import React from 'react';
import { Modal, Button } from "antd";
import {
  EditOutlined,
  UserAddOutlined,
  PlusOutlined,
  UserDeleteOutlined,
  SnippetsOutlined,
  BarsOutlined,
  UserOutlined,
} from "@ant-design/icons";

const ActionDialog = ({
  open,
  onClose,
  editHandler,
  assignPMHandler,
  onBoardHandler,
  unassignPMHandler,
  pmToUnassign,
  project,
  status,
  remarkHandler,
  statusChangeLogHandler,
  futureOnboardingsHandler,
}) => {
  const role = localStorage.getItem("designation");

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
      width={300}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          background: "white",
          borderRadius: "12px",
          fontSize: "20px",
        }}
      >
        <Button
          onClick={() => {
            onClose();
            editHandler();
          }}
          style={{
            marginBottom: "12px",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            border: "none",
            fontWeight: "500",
            fontSize: "1rem",
            boxShadow: "none",
          }}
          className=" hover:bg-gray-200 transition-colors duration-300"
          // Add hover effect using :hover pseudo-class
        >
          <EditOutlined style={{ marginRight: "8px" }} />
          Update Project
        </Button>
        {role !== "PM" && role !== "BA" && role !== "CSM" && (
          <>
            <Button
              onClick={() => {
                onClose();
                assignPMHandler();
              }}
              style={{
                marginBottom: "12px",
                color: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                border: "none",
                fontWeight: "500",
                fontSize: "1rem",
                boxShadow: "none",
              }}
              className=" hover:bg-gray-200 transition-colors duration-300"
              disabled={status === "Inactive"}
              title={
                status === "Inactive"
                  ? "Assign pm will be available for  active project only"
                  : null
              }
            >
              <UserAddOutlined style={{ marginRight: "8px" }} />
              Assign PM
            </Button>
            {pmToUnassign.length > 0 && (
              <Button
                onClick={() => {
                  onClose();
                  unassignPMHandler();
                }}
                style={{
                  marginBottom: "12px",
                  color: "black",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "1rem",
                  boxShadow: "none",
                }}
                className=" hover:bg-gray-200 transition-colors duration-300"
                //       disabled={status === "Inactive"}
                // title={status === "Inactive" ? "Assign pm will be available for  active project only" : null}
              >
                <UserDeleteOutlined style={{ marginRight: "8px" }} />
                Unassign PM
              </Button>
            )}
          </>
        )}
        <Button
          onClick={() => {
            onClose();
            onBoardHandler();
          }}
          style={{
            marginBottom: "12px",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            border: "none",
            fontWeight: "500",
            fontSize: "1rem",
            boxShadow: "none",
          }}
          className=" hover:bg-gray-200 transition-colors duration-300"
          disabled={status === "Inactive"}
          title={
            status === "Inactive"
              ? "Onboarding will be available for  active project only"
              : null
          }
        >
          <PlusOutlined style={{ marginRight: "8px" }} />
          Onboard Developer
        </Button>
        <Button
          onClick={() => {
            onClose();
            remarkHandler();
          }}
          style={{
            marginBottom: "12px",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            border: "none",
            fontWeight: "500",
            fontSize: "1rem",
            boxShadow: "none",
          }}
          className=" hover:bg-gray-200 transition-colors duration-300"
        >
          <SnippetsOutlined style={{ marginRight: "8px" }} />
          Remarks
        </Button>
        <Button
          onClick={() => {
            onClose();
            statusChangeLogHandler();
          }}
          style={{
            marginBottom: "12px",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            border: "none",
            fontWeight: "500",
            fontSize: "1rem",
            boxShadow: "none",
          }}
          className=" hover:bg-gray-200 transition-colors duration-300"
        >
          <BarsOutlined style={{ marginRight: "8px" }} />
          Status Change Logs
        </Button>
        <Button
          onClick={() => {
            onClose();
            futureOnboardingsHandler();
          }}
          style={{
            marginBottom: "12px",
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            border: "none",
            fontWeight: "500",
            fontSize: "1rem",
            boxShadow: "none",
          }}
          className=" hover:bg-gray-200 transition-colors duration-300"
        >
          <UserOutlined style={{ marginRight: "8px" }} />
          Future Onboardings
        </Button>
      </div>
    </Modal>
  );
};

export default ActionDialog;
