import React, { useState } from "react";
import { toast } from "react-toastify";

function Todoinput(props) {
  const [inputText, setInputText] = useState("");
  const [inputTime, setInputTime] = useState("");
  const isButtonDisabled =
    inputText === "" ||
    inputTime === "" ||
    inputTime <= 0 ||
    !isValidTime(inputTime);

  const handleLeaveClick = () => {
    setInputText("On Leave");

    props.extract("On Leave");
  };

  function isValidTime(parsedTime) {
    const truncatedTime = parseFloat(parsedTime.toFixed(2));
    return parsedTime === truncatedTime;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <input
          type="text"
          className="input-box-todo"
          placeholder="Enter your task"
          value={inputText}
          required
          onChange={(e) => {
            setInputText(e.target.value);
          }}
        />
        <input
          type="number"
          style={{ border: "1px solid rgb(161, 158, 158)" }}
          className="w-[20%] pl-4 
             rounded-lg"
          placeholder="Hours"
          required
          value={inputTime}
          onChange={(e) => {
            const newTime = e.target.value;
            if (newTime) {
              const parsedTime = parseFloat(newTime);
              setInputTime(parsedTime);
              if (parsedTime < 0 || !isValidTime(parsedTime)) {
                toast.error(
                  "Logged hours must be non-negative and have a maximum of 2 decimal places",
                  { position: "top-left" }
                );
              }
            } else {
              setInputTime("");
            }
          }}
        />

        <button
          className="add-btn"
          onClick={() => {
            if (!isButtonDisabled) {
              props.addList(inputText, inputTime);
              setInputText("");
              setInputTime("");
            }

            props.setIsFilled(true);
          }}
          disabled={isButtonDisabled}
        >
          +
        </button>
      </div>
      <div>
        <button
          onClick={handleLeaveClick}
          style={{
            backgroundColor: "#4CAF50",
            color: "#fff",
            border: "none",
            padding: "6px 10px",
            cursor: "pointer",
            borderRadius: "4px",
            margin: "10px 0px",
            fontStyle: "sans-serif",
            fontWeight: "bold",
          }}
        >
          On Leave
        </button>
      </div>
    </div>
  );
}
export default Todoinput;
