import React from "react";
import { Button, Modal } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "../../../Assets/CSS/IC/IC.css";

const ActionDialog = ({ open, onClose, editHandler, deleteHandler }) => {
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
      width={300}
    >
      <div class="flex flex-col items-start justify-center gap-4">
        <Button
          className="styleActionButtons"
          onClick={(e) => {
            editHandler(e);
            onClose();
          }}
        >
          <EditOutlined />
          Edit
        </Button>
        <Button
          className="styleActionButtons"
          onClick={() => {
            deleteHandler();
            onClose();
          }}
        >
          <DeleteOutlined />
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default ActionDialog;
