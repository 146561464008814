import { Box, IconButton } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Profile from "./Profile";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

const Breadcrumb = ({ handleDrawerOpen, open }) => {
  return (
    <>
      <Box
        component="div"
        className="flex-col bg-white mb-4 pl-[24px] pr-[24px] shadow-md sticky top-0 z-50"
      >
        <div className="flex justify-between">
          <div>
            {!open && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  // color: "white",
                  color: "black",
                  padding: "10px 10px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
          </div>
          <div>
            <Profile />
          </div>
        </div>
        <div className="flex justify-start">
          <BreadcrumbsNav />
        </div>
      </Box>
    </>
  );
};


const BreadcrumbsNav = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  
  function capitalizeFirstLetter(data) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }
  const role = localStorage.getItem("designation");
  return (
    <div>
      {role !== "DEVELOPER" ? (
        <Breadcrumbs aria-label="breadcrumb">
          {pathnames.map((name, index) => {
            // Decode the segment to remove percent-encoded spaces
            const decodedName = decodeURIComponent(name);

            const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
            const isLast = index === pathnames.length - 1;

            return isLast ? (
              <Typography color="text.primary" key={decodedName}>
                {capitalizeFirstLetter(decodedName)}
              </Typography>
            ) : (
              <Link color="inherit" to={routeTo} key={decodedName}>
                {capitalizeFirstLetter(decodedName)}
              </Link>
            );
          })}
        </Breadcrumbs>
      ) : (
        <p className="ml-7 text-white">Timesheet</p>
      )}
    </div>
  );
};

export default Breadcrumb;
