import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../apiclient";

const initialState = {
  isLoading: false,
  error: "",
  developers: [],
  newdevelopers: [],
  newdevelopersCount: 0,
  developersProject: [],
  projects: [],
  poc: [],
  projectManagers: [],
  reportingManagers: [],
  techStacks: [],
  designations: [],
  teamDesignations: [],
  totalDevelopers: [],
  groups: [],
  developerType: "",
  dataPerPage: 10,
  totalCount: 0,
  currentPage: 0,
  utilisation: [],
  rating: [],
  searchFilters: {},
  Status: [],
  POC: [],
  fullName: [],
  projectName: [],
  projectStatus: [],
  futureOnboardingDetails: [],
  departedDevelopersDetails: [],
  inactiveDevelopersDetails: [],
};
export const getDevelopers = createAsyncThunk(
  "lists/developers",
  async (data, thunkAPI) => {
    const { dataPerPage, currentPage } = thunkAPI.getState().lists;
    try {
      let res = await api.get(
        `api/v2/users/search?limit=${dataPerPage}&page=${currentPage}`,
        {},
      );
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getonoffdev = createAsyncThunk(
  "lists/getonoffdev",
  async (data, thunkAPI) => {
    const {} = thunkAPI.getState().lists;
    try {
      let res = await api.get(`api/v2/users/getOnboardingOffboardingData`, {});
      //main api for dashboard
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getonofstatus = createAsyncThunk(
  "lists/getonofstatus",
  async (data, thunkAPI) => {
    const {} = thunkAPI.getState().lists;
    try {
      let res = await api.get(`api/v2/users/getOnboardingOffboardingData`, {});
      //main api for dashboard
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getonoffullname = createAsyncThunk(
  "lists/getonoffullname",
  async (data, thunkAPI) => {
    const {} = thunkAPI.getState().lists;
    try {
      let res = await api.get(`api/v2/users/getOnboardingOffboardingData`, {});
      //main api for dashboard
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getonoffproject = createAsyncThunk(
  "lists/getonoffproject",
  async (data, thunkAPI) => {
    const {} = thunkAPI.getState().lists;
    try {
      let res = await api.get(`api/v2/users/getOnboardingOffboardingData`, {});
      //main api for dashboard
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getallprojects = createAsyncThunk(
  "lists/getallprojects",
  async (data, thunkAPI) => {
    const {} = thunkAPI.getState().lists;
    try {
      let res = await api.get(`/api/v2/projects/viewallProjects`, {});
      //main api for dashboard
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getonofpoc = createAsyncThunk(
  "lists/getonofpoc",
  async (data, { rejectWithValue }) => {
    try {
      let res = await api.get(`/api/v2/users/getPOCDetails`);
      return res.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  },
);

export const getDevelopersProject = createAsyncThunk(
  "lists/getDevelopersProject",
  async (totalDevelopers) => {
    try {
      let res = await api.get(
        `api/v2/users/list?limit=${totalDevelopers.availableCount}&page=0 `,
        {},
      );
      //main api for dashboard
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getnewDevelopers = createAsyncThunk(
  "lists/newdevelopers",
  async () => {
    try {
      let res = await api.get(`api/v2/users/getDashboardData`, {});
      return res.data;
    } catch (error) {
      return error;
    }
  },
);

export const getProjects = createAsyncThunk("lists/projects", async () => {
  const designation = "AVP";
  const user_id = "10sfffsa01";
  const projects_url = `/api/v2/projects/viewall`;
  try {
    let res = await api.get(projects_url);
    return res.data;
  } catch (error) {
    return error.response;
  }
});

export const getAllProjectManagers = createAsyncThunk(
  "lists/projectManagers",
  async ({ projectManagerId, role }) => {
    const projectManager_url = `api/v2/users/search?role=${role}`;
    try {
      const res = await api.get(projectManager_url, {
        designation: projectManagerId,
      });
      return res?.data;
    } catch (error) {
      console.log(error);
      // return error?.response
    }
  },
);
export const getAllReportingManagers = createAsyncThunk(
  "lists/reportingManagers",
  async ({ projectManagerId, role }) => {
    const projectManager_url = `api/v2/users/search?role=${role}`;
    try {
      const res = await api.get(projectManager_url, {
        designation: projectManagerId,
      });
      return res?.data;
    } catch (error) {
      console.log(error);
      // return error?.response
    }
  },
);

export const getTechStacks = createAsyncThunk(
  "lists/getTechStacks",
  async () => {
    const techStack_url = "api/v2/techstack/getAll";
    try {
      let res = await api.get(techStack_url);
      return res?.data;
    } catch (error) {
      return error.response;
    }
  },
);

// Poc
export const getPoc = createAsyncThunk("lists/getPoc", async () => {
  const poc_url = `api/v2/users/search?role=PM`;
  try {
    let res = await api.get(poc_url);
    return res?.data;
  } catch (error) {
    return error.response;
  }
});

export const getDesignations = createAsyncThunk(
  "lists/getDesignations",
  async () => {
    const designation_url = "api/v2/designation/getAllDesignation";
    try {
      let res = await api.get(designation_url);
      return res.data;
    } catch (error) {
      console.log(error.response);
      // return error.response;
    }
  },
);

export const getTotalDevelopers = createAsyncThunk(
  "lists/getTotalDevelopers",
  async () => {
    try {
      let res = await api.get("/api/v2/users/getDashboardData");
      return res.data;
    } catch (error) {
      return error.res;
    }
  },
);
export const getGroups = createAsyncThunk("lists/getGroups", async () => {
  try {
    const group_url = `api/v2/group/viewall?isDelete=${false}`;
    let res = await api.get(group_url);
    return res.data;
  } catch (error) {
    // console.log(error.response.data);
    // return error.response;
  }
});

export const utilisationHistory = createAsyncThunk(
  "lists/utilisationHistory",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      let res = await api.get(
        `api/v2/users/utilisationHistory?startDate=${data.startDate}&endDate=${
          data.endDate
        }&keyword=${data.name}&group=${
          searchFilters?.selectedGroup ? searchFilters?.selectedGroup : ""
        }&billingType=${
          searchFilters.selectedBillability
            ? searchFilters.selectedBillability
            : ""
        }`,
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getdeveloperOnboardDetails = createAsyncThunk(
  "lists/getdeveloperOnboardDetails",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      let res = await api.get(
        `api/v2/users/getOnboardingOffboardingData?fullName=${
          searchFilters.selectedDeveloper ? searchFilters.selectedDeveloper : ""
        }&projects=${
          searchFilters.selectedProject ? searchFilters.selectedProject : ""
        }&POC=${
          searchFilters.selectedPm ? searchFilters.selectedPm : ""
        }&status=${
          searchFilters.selectedStatus ? searchFilters.selectedStatus : ""
        }&name=${data?.name ? data.name : ""}&typeOfProject=${
          searchFilters.selectedProjectType
            ? searchFilters.selectedProjectType
            : ""
        }&billingType=${
          searchFilters.selectedBillability
            ? searchFilters.selectedBillability
            : ""
        }`,
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getDepartedDevelopersDetails = createAsyncThunk(
  "lists/getDepartedDevelopersDetails",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      let res = await api.get(
        `api/v2/users/getDepartedDevelopers?name=${
          data?.name ? data.name : ""
        }`,
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getInactiveDevelopersDetails = createAsyncThunk(
  "lists/getInactiveDevelopersDetails",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      let response = await api.get(
        `api/v2/users/get-active-inactive-developers?name=${data.name}&group=${
          searchFilters.selectedGroup ? searchFilters.selectedGroup : ""
        }&techStack=${
          searchFilters.selectedTechStack ? searchFilters.selectedTechStack : ""
        }`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const downloadInactiveDevelopersDetails = createAsyncThunk(
  "lists/downloadInactiveDevelopersDetails",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;

    try {
      let response = await api.get(
        `api/v2/users/download-active-inactive-developers?group=${
          searchFilters.selectedGroup ? searchFilters.selectedGroup : ""
        }&techStack=${
          searchFilters.selectedTechStack ? searchFilters.selectedTechStack : ""
        }`,
      );
      window.open(response?.data?.data, "_blank");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const downloadDepartedDevelopersDetails = createAsyncThunk(
  "lists/downloadDepartedDevelopersDetails",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(
        `api/v2/users/exportDepartedDevelopersHistorytoCSV`,
      );
      window.open(response?.data?.data, "_blank");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const downloadOnboardHistoryExcel = createAsyncThunk(
  "lists/downloadOnboardHistoryExcel",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      const response = await api.get(
        `api/v2/users/exportToCSV?fullName=${
          searchFilters.selectedDeveloper ? searchFilters.selectedDeveloper : ""
        }&projects=${
          searchFilters.selectedProject ? searchFilters.selectedProject : ""
        }&POC=${
          searchFilters.selectedPm ? searchFilters.selectedPm : ""
        }&status=${
          searchFilters.selectedStatus ? searchFilters.selectedStatus : ""
        }&name=${data?.name ? data.name : ""}&typeOfProject=${
          searchFilters.selectedProjectType
            ? searchFilters.selectedProjectType
            : ""
        }&billingType=${
          searchFilters.selectedBillability
            ? searchFilters.selectedBillability
            : ""
        }`,
      );
      window.open(response?.data?.data, "_blank");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getProjectStatusHistory = createAsyncThunk(
  "lists/getProjectStatusHistory",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      let res = await api.get(
        `api/v2/projectStatus/getProjectStatusHistory?startDate=${
          data.startDate
        }&endDate=${data.endDate}&keyword=${data.name}&group=${
          searchFilters?.selectedGroup ? searchFilters?.selectedGroup : ""
        }&typeOfProject=${
          searchFilters?.selectedProjectType
            ? searchFilters?.selectedProjectType
            : ""
        }&pm=${searchFilters?.selectedPm ? searchFilters?.selectedPm : ""}`,
      );
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const utilisationDownload = createAsyncThunk(
  "lists/utilisationDownload",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      const response = await api.get(
        `api/v2/users/exportutilisationHistorytoCSV?startDate=${
          data.startDate
        }&endDate=${data.endDate}&keyword=${data.name}&group=${
          searchFilters?.selectedGroup ? searchFilters?.selectedGroup : ""
        }&billingType=${
          searchFilters.selectedBillability
            ? searchFilters.selectedBillability
            : ""
        }`,
      );
      window.open(response?.data?.data, "_blank");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const projectStatusDownload = createAsyncThunk(
  "lists/projectStatusDownload",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      const response = await api.get(
        `api/v2/projectStatus/downloadProjectStatusHistory?startDate=${
          data.formattedStartISO
        }&endDate=${data.formattedEndDateISO}&keyword=${data.name}&group=${
          searchFilters?.selectedGroup ? searchFilters?.selectedGroup : ""
        }&typeOfProject=${
          searchFilters?.selectedProjectType
            ? searchFilters?.selectedProjectType
            : ""
        }&pm=${searchFilters?.selectedPm ? searchFilters?.selectedPm : ""}`,
      );
      window.open(response?.data?.data, "_blank");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const ratingDownload = createAsyncThunk(
  "lists/ratingDownload",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      const response = await api.get(
        `api/v2/users/exportratingHistorytoCSV?startDate=${
          data.startDate
        }&endDate=${data.endDate}&keyword=${data.name}&group=${
          searchFilters.selectedGroup ? searchFilters.selectedGroup : ""
        }`,
      );
      window.open(response?.data?.data, "_blank");
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const ratingHistory = createAsyncThunk(
  "lists/rating",
  async (data, thunkAPI) => {
    const { searchFilters } = thunkAPI.getState().lists;
    try {
      let response = await api.get(
        `api/v2/users/ratingHistory?startDate=${data.startDate}&endDate=${
          data.endDate
        }&keyword=${data.name}&group=${
          searchFilters.selectedGroup ? searchFilters.selectedGroup : ""
        }`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

const ListsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setDataPerPage(state, action) {
      state.dataPerPage = action.payload;
    },
    setFilterParameters(state, action) {
      state.searchFilters = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(ratingHistory.fulfilled, (state, action) => {
      state.rating = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(ratingHistory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ratingHistory.rejected, (state, action) => {
      state.rating = [];
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(utilisationHistory.fulfilled, (state, action) => {
      state.utilisation = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(utilisationHistory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(utilisationHistory.rejected, (state, action) => {
      state.utilisation = [];
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(getProjectStatusHistory.fulfilled, (state, action) => {
      state.projectStatus = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getProjectStatusHistory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getProjectStatusHistory.rejected, (state, action) => {
      state.projectStatus = [];
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(getdeveloperOnboardDetails.fulfilled, (state, action) => {
      state.futureOnboardingDetails = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getdeveloperOnboardDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getdeveloperOnboardDetails.rejected, (state, action) => {
      state.futureOnboardingDetails = [];
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getDepartedDevelopersDetails.fulfilled, (state, action) => {
      state.departedDevelopersDetails = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getDepartedDevelopersDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDepartedDevelopersDetails.rejected, (state, action) => {
      state.departedDevelopersDetails = [];
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getInactiveDevelopersDetails.fulfilled, (state, action) => {
      state.inactiveDevelopersDetails = action.payload.data;
      state.isLoading = false;
    });
    builder.addCase(getInactiveDevelopersDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInactiveDevelopersDetails.rejected, (state, action) => {
      state.inactiveDevelopersDetails = [];
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(utilisationDownload.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(utilisationDownload.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(utilisationDownload.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(ratingDownload.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(ratingDownload.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ratingDownload.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(projectStatusDownload.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(projectStatusDownload.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(projectStatusDownload.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadOnboardHistoryExcel.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(downloadOnboardHistoryExcel.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(downloadOnboardHistoryExcel.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getDevelopers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.developers = action?.payload?.data;
      state.totalCount = action?.payload?.count;
      state.error = "";
    });
    builder.addCase(getDevelopers.pending, (state, action) => {
      state.developers = [];
      state.isLoading = true;
    });
    builder.addCase(getDevelopers.rejected, (state, action) => {
      state.isLoading = false;
      state.developers = [];
      state.error = action.error.message;
    });

    builder.addCase(getDevelopersProject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.developersProject = action?.payload?.data;
      state.totalCount = action?.payload?.count;
      state.error = "";
    });
    builder.addCase(getDevelopersProject.pending, (state, action) => {
      state.developersProject = [];
      state.isLoading = true;
    });
    builder.addCase(getDevelopersProject.rejected, (state, action) => {
      state.isLoading = false;
      state.developersProject = [];
      state.error = action.error.message;
    });

    builder.addCase(getnewDevelopers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.newdevelopers = action?.payload?.data;
      state.newdevelopersCount = action?.payload?.count;
      state.error = "";
    });
    builder.addCase(getnewDevelopers.pending, (state, action) => {
      state.newdevelopers = [];
      state.isLoading = true;
    });
    builder.addCase(getnewDevelopers.rejected, (state, action) => {
      state.isLoading = false;
      state.newdevelopers = [];
      state.error = action.error.message;
    });

    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projects = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getProjects.pending, (state, action) => {
      state.projects = [];
      state.isLoading = true;
    });
    builder.addCase(getProjects.rejected, (state, action) => {
      state.isLoading = false;
      state.projects = [];
      state.error = action.error.message;
    });

    builder.addCase(getAllProjectManagers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectManagers = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getAllProjectManagers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllProjectManagers.rejected, (state, action) => {
      state.isLoading = false;
      state.projectManagers = [];
      state.error = action.error.message;
    });
    builder.addCase(getAllReportingManagers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reportingManagers = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getAllReportingManagers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllReportingManagers.rejected, (state, action) => {
      state.isLoading = false;
      state.reportingManagers = [];
      state.error = action.error.message;
    });
    builder.addCase(getTechStacks.fulfilled, (state, action) => {
      state.isLoading = false;
      state.techStacks = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getTechStacks.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTechStacks.rejected, (state, action) => {
      state.isLoading = false;
      state.techStacks = [];
      state.error = action.error.message;
    });

    // poc
    builder.addCase(getPoc.fulfilled, (state, action) => {
      state.isLoading = false;
      state.poc = action.payload.data;
      state.error = "";
    });
    builder.addCase(getPoc.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPoc.rejected, (state, action) => {
      state.isLoading = false;
      state.poc = [];
      state.error = action.error.message;
    });

    builder.addCase(getDesignations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.designations = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getDesignations.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDesignations.rejected, (state, action) => {
      state.isLoading = false;
      state.designations = [];
      state.error = action.error.message;
    });
    builder.addCase(getTotalDevelopers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.totalDevelopers = action?.payload.data;
      state.error = "";
    });
    builder.addCase(getTotalDevelopers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getTotalDevelopers.rejected, (state, action) => {
      state.isLoading = false;
      state.totalDevelopers = [];
      state.error = action.error.message;
    });

    builder.addCase(getonoffdev.fulfilled, (state, action) => {
      state.isLoading = false;
      state.POC = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getonoffdev.pending, (state, action) => {
      state.POC = [];
      state.isLoading = true;
    });
    builder.addCase(getonoffdev.rejected, (state, action) => {
      state.isLoading = false;
      state.POC = [];
      state.error = action.error.message;
    });

    builder.addCase(getonofstatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Status = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getonofstatus.pending, (state, action) => {
      state.Status = [];
      state.isLoading = true;
    });
    builder.addCase(getonofstatus.rejected, (state, action) => {
      state.isLoading = false;
      state.Status = [];
      state.error = action.error.message;
    });

    builder.addCase(getonoffullname.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fullName = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getonoffullname.pending, (state, action) => {
      state.fullName = [];
      state.isLoading = true;
    });
    builder.addCase(getonoffullname.rejected, (state, action) => {
      state.isLoading = false;
      state.fullName = [];
      state.error = action.error.message;
    });

    builder.addCase(getonoffproject.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectName = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getonoffproject.pending, (state, action) => {
      state.projectName = [];
      state.isLoading = true;
    });
    builder.addCase(getonoffproject.rejected, (state, action) => {
      state.isLoading = false;
      state.projectName = [];
      state.error = action.error.message;
    });

    builder.addCase(getonofpoc.fulfilled, (state, action) => {
      state.isLoading = false;
      state.POC = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getonofpoc.pending, (state, action) => {
      state.POC = [];
      state.isLoading = true;
    });
    builder.addCase(getonofpoc.rejected, (state, action) => {
      state.isLoading = false;
      state.POC = [];
      state.error = action.error.message;
    });

    builder.addCase(getallprojects.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectName = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getallprojects.pending, (state, action) => {
      state.projectName = [];
      state.isLoading = true;
    });
    builder.addCase(getallprojects.rejected, (state, action) => {
      state.isLoading = false;
      state.projectName = [];
      state.error = action.error.message;
    });

    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groups = action?.payload?.resp;
      state.error = "";
    });
    builder.addCase(getGroups.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.groups = [];
      state.error = action.error.message;
    });
  },
});

export const { setCurrentPage, setDataPerPage, setFilterParameters } =
  ListsSlice.actions;
export default ListsSlice.reducer;
