import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    background: {
      default: "#F1F1F1",
    },
    fontFamily: "'Kanit', sans-serif",
  },
  button: {
    "&:hover": {
      backgroundColor: "black !important",
    },
  },
  text: {
    secondary: {
      main: "#808080",
    },
  },
  shape: {
    borderRadius: 5,
  },
});

export default theme;
