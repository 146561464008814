import React, { useEffect, useState } from "react";
import Summary from "./common/summary";
import {
  get_all_client,
  get_filtered_client,
} from "../../Services/clientRequirementServices";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Box,
  Typography,
  Tooltip,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import "../../Assets/CSS/Developer/Developer.css";
import { useForm } from "react-hook-form";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import FilterSchema from "../Auth/Register/FilterSchema";
import { makeStyles } from "@mui/styles";
import { getClientRequirements } from "../../Redux/Slices/ClientRequirement/ClientRequirementSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "../../utils/LoadingSpinner";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import { toast } from "react-toastify";
import CreateClient from "./common/CreateClient";
import EditClient from "./common/EditClient";
import {
  deleteClientRequirement,
  editClientRequirement,
} from "../../apis/ClientApi";
import ClientFilter from "./Clientfilter";
import api from "../../apiclient";
import SearchIcon from "@mui/icons-material/Search";
import { FilterOutlined } from "@ant-design/icons";
import { setFilterParameters } from "../../Redux/Slices/ClientRequirement/ClientRequirementSlice";
import ActionDialog from "./common/ActionDialog";

const ClientRequirement = () => {
  const [dataPerPage, setDataPerPage] = useState(10);
  const [openCreateClient, setOpenCreateClient] = useState(false);
  const [openSummary, setOpenSummary] = useState(false);
  const [openEditClient, setOpenEditClient] = useState(false);
  const [client, setClient] = useState({});
  const [clients, setClients] = useState([]);
  const [userID, setUserID] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [clientName, setClientName] = useState("");

  const useStyles = makeStyles((theme) => ({
    actionColumn: {
      backgroundColor: "white",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },

    actionHeader: {
      backgroundColor: "#FAFAFA",
      color: "black",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.8)",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [openClientFilter, setOpenClientFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openActionModal, setOpenActionModal] = useState(false);

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FilterSchema),
  });
  const { searchFilters } = useSelector((state) => state?.clientRequirement);

  useEffect(() => {
    fetchAllClient().then(() => setIsLoading(false));
    dispatch(setFilterParameters({}));
  }, []);

  const getAllClient = async () => {
    if (Object.keys(searchFilters).length > 0) {
      const techStackParam = searchFilters.techStack.join(",");
      const statusParam = searchFilters.status.join(",");
      const POCParam = searchFilters.POC.join(",");

      const data1 = {
        techStack: techStackParam,
        status: statusParam,
        POC: POCParam,
        isDeleted: false,
      };

      const filteredClient = await get_filtered_client(data1);

      if (filteredClient?.resp?.status === 200) {
        setClients(filteredClient?.resp?.filterClient);
      } else if (!!filteredClient?.error) {
        setClients([]);
      }
    } else {
      try {
        const resp = await get_all_client();
        if (resp?.resp?.status == 200) {
          setClients(resp?.resp?.clients);
          setIsLoading(false);
        }
      } catch (error) {
        toast(error.message);
      }
    }
  };

  const fetchAllClient = async () => {
    const ans = await api.get(`api/v2/clientRequirement/all`);
    setClients(ans?.data?.resp?.clients);
    setAllClient(ans?.data?.resp?.clients);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (clients?.length !== 0) {
      let rows = clients?.map((user) => {
        return {
          id: user?._id,
          clientName: user?.clientName,
          count: user?.count,
          status: user?.status,
          techStack:
            user?.techStack?.length > 0
              ? user?.techStack[0]?.name
              : user?.techStack
              ? user?.techStack.name
              : "N/A",
          seniorityLevel: user?.seniorityLevel,
          remarks: user?.remarks,
          poc: user?.POC?.fullName,
          salesPoc: user?.salesPoc?.fullName,
          poc_obj: user?.POC,
          salesPoc_obj: user?.salesPoc,
          requirementOpenDate: user?.requirementOpenDate,
          deadline: user?.deadline,

          daysLeft: user?.daysLeft,
          hireDate: user?.hireDate,
          hiringDuration: user?.hiringDuration,
          actionDelete: user?._id,
          techStack_id: [
            user?.techStack?.length > 0
              ? user?.techStack[0]
              : user?.techStack
              ? user?.techStack
              : "N/A",
          ],
        };
      });
      setRows(rows);
    } else {
      setRows([]);
    }
  }, [clients]);

  const handleCloseCreateTeamDialog = () => {
    getAllClient();
    setOpenCreateClient(false);
  };

  const handleCloseSummaryTeamDialog = () => {
    setOpenSummary(false);
  };

  const handleOpenSummaryDialog = () => {
    setOpenSummary(true);
  };

  const handleCloseEditTeamDialog = () => {
    getAllClient();
    setOpenEditClient(false);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const handleEditRowChange = (params) => {
    // Handle changes when a cell is edited
    const { id, field, value } = params;

    const selectedRow = allClient.filter((data, index) => {
      return id == data?._id;
    });

    selectedRow[0].remarks = value;
    selectedRow[0].techStack = selectedRow[0]?.techStack?._id;
    selectedRow[0].POC = selectedRow[0]?.POC?._id;

    editClientRequirement(selectedRow[0])
      .then((res) => {
        toast("Remarks Updated Successfully");

        fetchAllClient();

        setTimeout(() => {
          dispatch(getClientRequirements());
        });
      })

      .catch((error) => toast(error?.response?.data?.message));
  };

  const handleRemoveMember = async () => {
    try {
      const response = await deleteClientRequirement(userID);
      if (response) {
        toast("Client deleted successfully");
        getAllClient();
        handleCloseRemoveDialog();
        setIsSuccess(!isSuccess);
      }
    } catch (e) {
      toast(e.msg);
    }
  };

  const handleOpenFilterModal = () => {
    setOpenClientFilter(true);
  };

  const handleCloseFilterModal = () => {
    setOpenClientFilter(false);
  };

  const deleteHandler = () => {
    setOpenRemoveDialog(true);
    setConfirmMessage("Are you sure you want to remove ?");
    setIsSuccess(false);
  };

  const editHandler = () => {
    setOpenEditClient(true);
  };

  const columns = [
    {
      field: "clientName",
      headerName: "Client Name",
      headerAlign: "left",
      width: 160,
      renderCell: (params) => {
        const clientName =
          params?.row?.clientName === "" ? "N/A" : params?.row?.clientName;
        return (
          <Tooltip title={clientName} placement="right" arrow>
            <span
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "context-menu",
              }}
            >
              {clientName}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 125,
      renderCell: (params) => {
        const status = params.row.status;

        const statusStyles = {
          OPEN: { color: "#178C45", backgroundColor: "rgba(23, 140, 69, 0.1)" },
          HOT: { color: "#F8812A", backgroundColor: "rgba(248, 129, 42, 0.1)" },
          CLOSE: { color: "#5C5C5C", backgroundColor: "rgba(92, 92, 92, 0.1)" },
          COLD: {
            color: "#38ABDD",
            backgroundColor: "rgba(56, 171, 221, 0.1)",
          },
          LOST: {
            color: "#E954D4",
            backgroundColor: "rgba(233, 84, 212, 0.1)",
          },
        };

        const commonStyle = {
          padding: "4px 12px 4px 12px",
          backgroundColor: statusStyles[status].backgroundColor,
          borderRadius: "13px",
          font: "Lato",
          fontWeight: "500",
          fontSize: "16px",
          width: "98px",
          display: "flex",
          justifyContent: "center",
          color: statusStyles[status].color,
        };

        return <div style={commonStyle}>{status}</div>;
      },
    },
    {
      field: "count",
      headerName: "Count",
      align: "center",
      headerAlign: "center",
      width: 125,
    },
    {
      field: "techStack",
      headerName: "Tech stack",
      width: 125,
    },
    {
      field: "seniorityLevel",
      headerName: "seniority Level",
      width: 125,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      width: 125,
      editable: true,
    },
    {
      field: "poc",
      headerName: "POC",
      width: 125,
    },
    {
      field: "salesPoc",
      headerName: "SALES POC",
      width: 125,
    },
    {
      field: "actions",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      sortable: false,
      minWidth: 200,
      flex: 0.5,
      headerClassName: classes.actionHeader,
      cellClassName: classes.actionColumn,

      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Perform Action" placement="top">
              <IconButton
                size="small"
                sx={{
                  marginLeft: "auto",
                }}
                onClick={() => {
                  setUserID(params?.row?.id);
                  setClient(params);
                  setOpenActionModal(true);
                }}
              >
                <GridMoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "requirementOpenDate",
      headerName: "Requirement Open Date",
      width: 125,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "deadline",
      headerName: "Deadline",
      width: 125,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "daysLeft",
      headerName: "Days Left",
      width: 125,
    },
    {
      field: "hireDate",
      headerName: "Hire Date",
      width: 125,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "hiringDuration",
      headerName: "Hiring Duration",
      width: 125,
    },
  ];

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Box
      sx={{
        height: "85vh",
        backgroundColor: "white",
        padding: "10px 32px 24px 32px",
        borderRadius: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
          marginBottom: "10px",
        }}
      >
        <Box>
          <Typography
            variant="h5"
            sx={{
              font: "Montserrat",
              fontSize: "32px",
              fontWeight: "500",
            }}
          >
            Client Requirement
          </Typography>
        </Box>
        <Box style={{ display: "flex", gap: "0.4rem" }}>
          <Button
            variant="contained"
            sx={{
              marginRight: "2px",
              backgroundColor: "#4E79FA", // Set the button background color to blue
              color: "#FFFFFF", // Set the button text color to white
              "&:hover": {
                backgroundColor: "#4E79FA", // Maintain blue background on hover
              },
              height: "56px",
            }}
            onClick={handleOpenSummaryDialog}
          >
            Summary
          </Button>
          <Button
            variant="contained"
            sx={{
              marginRight: "2px",
              backgroundColor: "#4E79FA",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#4E79FA",
              },
              height: "56px",
            }}
            onClick={() => {
              handleCloseCreateTeamDialog(); // Assuming this function handles closing the dialog
              setOpenCreateClient(true);
            }}
          >
            <AddCircleOutlineOutlined sx={{ marginRight: "2px" }} />
            Add Client Requirement
          </Button>
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          gap: "1rem",
          marginTop: "1rem",
          marginBottom: "0.5rem",
          width: "50%",
        }}
      >
        <TextField
          size="small"
          placeholder="Search Client Name"
          autoComplete={"off"}
          sx={{
            borderRadius: "8px",
            height: "100%",
            width: "100%",
            "& div": {
              height: "100%",
              backgroundColor: "white",
            },
          }}
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          endIcon={<FilterOutlined />}
          sx={{
            borderRadius: "8px",
            padding: "16px, 24px, 16px, 24px",
            width: "109px",
          }}
          onClick={handleOpenFilterModal}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "8px",
            padding: "16px, 24px, 16px, 24px",
            width: "109px",
          }}
          onClick={() => {
            dispatch(setFilterParameters({}));
            fetchAllClient();
          }}
        >
          Reset
        </Button>
      </Box>

      <div style={{ width: "100%", height: "80%" }}>
        <DataGrid
          rows={rows}
          rowHeight={40}
          columns={columns}
          pageSize={dataPerPage}
          onPageSizeChange={(value) => setDataPerPage(value)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          disableColumnMenu
          disableColumnFilter
          sx={{
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              border: "none",
            },
            backgroundColor: "white",
            "& .MuiDataGrid-columnHeaders": {
              // fontSize: "1rem",
              background: "#FAFAFA",
              color: "black",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "white",
            },
            "& 	.MuiDataGrid-menuIconButton": {
              color: "white",
            },
            "& 	.MuiDataGrid-filterIcon": {
              color: "white",
            },
            "& .MuiDataGrid-window": {
              // Customize the entire grid window (both horizontal and vertical scrollbars)
              // For example:
              scrollbarWidth: "thin", // For Firefox
              "&::-webkit-scrollbar": {
                width: "10px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-track": {
                background: "#f1f1f1", // Background color of the scrollbar track
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888", // Color of the scrollbar thumb
                borderRadius: "5px", // Border radius of the scrollbar thumb
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555", // Color of the scrollbar thumb on hover
              },
            },
            "& .MuiDataGrid-virtualScroller": {
              // Customize the vertical scrollbar container
              // For example:
              background: "white", // Background color of the vertical scrollbar container
              width: "10px", // Width of the vertical scrollbar container
            },
            "& .MuiDataGrid-horizontalScroller": {
              // Customize the horizontal scrollbar container
              // For example:
              background: "#f1f1f1", // Background color of the horizontal scrollbar container
              height: "10px", // Height of the horizontal scrollbar container
            },
            "& .MuiDataGrid-scrollbar": {
              // Styles for both vertical and horizontal scrollbar thumbs
              // Customize the common styles for the scrollbar thumb
              // For example:
              background: "#888", // Color of the scrollbar thumb
              borderRadius: "5px", // Border radius of the scrollbar thumb
            },
            "& .MuiDataGrid-scrollbar-thumb": {
              // Styles for scrollbar thumb itself
              // You can further customize the styles for the scrollbar thumb
              // For example:
              background: "#888", // Color of the scrollbar thumb
              borderRadius: "5px", // Border radius of the scrollbar thumb
              "&:hover": {
                background: "red", // Color of the scrollbar thumb on hover
              },
            },
          }}
          filterModel={{
            items: [
              {
                columnField: "clientName",
                operatorValue: "contains",
                value: `${clientName}`,
              },
            ],
          }}
          onCellEditCommit={(params) => handleEditRowChange(params)}
        />
      </div>
      {openCreateClient && (
        <CreateClient
          open={openCreateClient}
          onClose={handleCloseCreateTeamDialog}
          user={client.row}
        />
      )}
      {openSummary && (
        <Summary open={openSummary} onClose={handleCloseSummaryTeamDialog} />
      )}
      {openEditClient && (
        <EditClient
          open={openEditClient}
          onClose={handleCloseEditTeamDialog}
          user={client.row}
        />
      )}

      {openRemoveDialog && (
        <ConfirmationDialog
          open={openRemoveDialog}
          message={confirmMessage}
          onClose={handleCloseRemoveDialog}
          onConfirm={handleRemoveMember}
          isSuccess={isSuccess}
        />
      )}

      {openClientFilter && (
        <ClientFilter
          open={openClientFilter}
          onClose={handleCloseFilterModal}
          setClients={setClients}
        />
      )}
      {openActionModal && (
        <ActionDialog
          open={openActionModal}
          onClose={() => setOpenActionModal(false)}
          deleteHandler={deleteHandler}
          editHandler={editHandler}
        />
      )}
    </Box>
  );
};
export default ClientRequirement;
