import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../../apiclient";

export const create_ic = createAsyncThunk(
  "ic/create_ic",
  async (data, { rejectWithValue }) => {
    try {
      let res = await api.post("/api/v1/createIc", data);
      toast("IC created Successfully");
     
      return res.data;
    
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.message);
        return rejectWithValue(error.response.data);
      }
      return error.response;
    }
  }
);

const icSlice = createSlice({
  name: "ic",
  initialState: {
    isLoading: false,
    msg: "",
    code: "",
    status: "",
    ic: [],
    keyword: "",
  },
  reducers: {
    setKeyword: (state, action ) => {
      state.keyword = action.payload;
    },
    resetStatus: (state) => {
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(create_ic.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_ic.fulfilled, (state, action) => {
        state.isLoading = false;
        state.msg = action.payload.msg;
        state.code = action.payload.code;
        state.ic.push(action.payload);
      })
      .addCase(create_ic.rejected, (state, action) => {
        state.isLoading = false;
        state.msg = action.payload.message;
        // state.code = action.payload.code;
        state.status = action.payload.status;
      });
  },
});

export const { setKeyword , resetStatus } = icSlice.actions;
export default icSlice.reducer;



