import { Button, Modal, Input } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import {
  getProjectRemark,
  addProjectRemark,
} from "../../../Redux/Slices/Project/ProjectsSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const RemarkSectionModal = ({ id, open, onClose, projectRemarks }) => {
  let { displayRemarksLoading } = useSelector((store) => store.project);
  const [displayRemarks, setDisplayRemarks] = useState([...projectRemarks]);
  const [remark, setRemark] = useState("");
  const selectedDate = dayjs().format("YYYY-MM-DD");
  const { TextArea } = Input;
  const dispatch = useDispatch();

  const handleAddRemark = () => {
    if (remark.trim() !== "") {
      const newRemark = { remark: remark.trim(), date: selectedDate };
      dispatch(
        addProjectRemark({
          projectId: id,
          remarks: [...projectRemarks, newRemark],
        })
      ).then(() => {
        toast("Remark added successfully");
        dispatch(getProjectRemark({ projectId: id })).then((response) => {
          setDisplayRemarks(response.payload.data.remarks);
        });
        setRemark("");
      });
    }
  };

  const handleSubmit = () => {
    dispatch(
      addProjectRemark({
        projectId: id,
        remarks: displayRemarks,
      })
    ).then(() => toast("Remark changes saved"));
    onClose();
  };

  const handleEditRemark = (id, value) => {
    // Find the index of the remark in projectRemarks based on its _id
    const remarkIndex = displayRemarks.findIndex((remark) => remark._id === id);
    if (remarkIndex !== -1) {
      // Create a new array with the edited remark at the specified index
      const updatedRemarks = [...displayRemarks];
      updatedRemarks[remarkIndex] = {
        ...updatedRemarks[remarkIndex],
        remark: value,
      };
      setDisplayRemarks(updatedRemarks);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={
        displayRemarks.length > 0
          ? [
              <Button key="back" onClick={onClose}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handleSubmit}>
                Save Edit Changes
              </Button>,
            ]
          : null
      }
      centered
      destroyOnClose
      width={700}
      title="Remark Section"
    >
      <div className="flex gap-[1rem] rounded-lg overflow-y-auto mt-8 align-middle justify-center shadow-md bg-opacity-30 p-4">
        <TextArea
          rows={2}
          value={remark}
          onChange={(e) => setRemark(e.target.value)}
          placeholder="Add remark"
        />
        <button
          onClick={() => {
            handleAddRemark();
          }}
          className="text-[20px] bg-[black] font-semibold p-2 radius rounded-[8px] text-white border-transparent	"
        >
          +
        </button>
      </div>
      <div className="mt-[2rem]">
        <h3>Recent Remarks</h3>
        {displayRemarksLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="flex-column gap-[1rem] rounded-lg overflow-y-auto mt-4 max-h-80 p-4">
            {displayRemarks?.map((displayRemark) => (
              <div key={displayRemark._id} className="mb-[1rem]">
                <div className="text-sm font-semibold text-gray-500 mb-1">
                  Date: {dayjs(displayRemark.date).format("DD-MMM-YYYY")}
                </div>
                <TextArea
                  rows={2}
                  value={displayRemark.remark}
                  onChange={(e) =>
                    handleEditRemark(displayRemark._id, e.target.value)
                  }
                  placeholder="Add remark"
                  className="w-full"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RemarkSectionModal;
