import React, { useEffect, useState } from "react";
import api from "../../../apiclient";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, TextField, Tooltip } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const DeveloperReports = () => {
  const [developerData, setDeveloperData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState("2023-03-01");
  const [toDate, setToDate] = useState("2023-12-01");
  useEffect(() => {
    const getReportsDeveloper = async () => {
      const res = await api.get(
        `/api/v2/ic/doGetDeveloperIcData?startDate=${fromDate}&endDate=${toDate}`
      );
      setDeveloperData(res?.data?.data);
      setIsLoading(false);
      return res?.data;
    };
    getReportsDeveloper();
  }, [fromDate, toDate]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 150,
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Accepted",
      headerName: "Accepted",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Rejected",
      headerName: "Rejected",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Rescheduled",
      headerName: "Rescheduled",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Cancelled",
      headerName: "Cancelled",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Pending",
      headerName: "Pending",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "grandCount",
      headerName: "Grand Count",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
  ];

  const rows = developerData?.map((user) => {
    function getPendingCount(data) {
      let pendingCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Pending") {
          pendingCount += data[i].count;
        }
      }
      return pendingCount;
    }
    function getAcceptedCount(data) {
      let acceptedCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Accepted") {
          acceptedCount += data[i].count;
        }
      }
      return acceptedCount;
    }
    function getRejectedCount(data) {
      let rejectedCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Rejected") {
          rejectedCount += data[i].count;
        }
      }
      return rejectedCount;
    }
    function getRescheduledCount(data) {
      let rescheduledCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Rescheduled") {
          rescheduledCount += data[i].count;
        }
      }
      return rescheduledCount;
    }
    function getCancelledCount(data) {
      let canceledCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Cancelled") {
          canceledCount += data[i].count;
        }
      }
      return canceledCount;
    }

    return {
      // name : user,
      id: user?._id,
      name: user?.fullName,
      grandCount: user?.grandCount,
      Pending: getPendingCount(user?.feedbackCounts),
      Accepted: getAcceptedCount(user?.feedbackCounts),
      Rejected: getRejectedCount(user?.feedbackCounts),
      Rescheduled: getRescheduledCount(user?.feedbackCounts),
      Cancelled: getCancelledCount(user?.feedbackCounts),
    };
  });

  return (
    <div>
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div style={{ height: "70vh", width: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "4.5rem",
                display: "flex",
                gap: "15px",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "9.5rem",
                  // margin:"5rem",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      slotProps={{ textField: { size: "small" } }}
                      inputFormat="MM-DD-YYYY"
                      label="From"
                      onChange={(newValue) => {
                        setFromDate(moment(newValue).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            size="small"
                            required
                            fullWidth
                            {...params}
                          />
                        </>
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              </div>

              <div
                style={{
                  width: "9.5rem",
                  // margin:"5rem",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      slotProps={{ textField: { size: "small" } }}
                      inputFormat="MM-DD-YYYY"
                      label="TO"
                      onChange={(newValue) => {
                        setToDate(moment(newValue).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <>
                          <TextField
                            size="small"
                            required
                            fullWidth
                            {...params}
                          />
                        </>
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
            </div>

            <DataGrid
              rows={rows}
              rowHeight={40}
              columns={columns}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableColumnMenu
              disableColumnFilter
              sx={{
                backgroundColor: "white",
                "& .MuiDataGrid-columnHeaders": {
                  background: "black",
                  color: "white",
                },
                "& .MuiDataGrid-sortIcon": {
                  color: "white",
                },
                "& 	.MuiDataGrid-menuIconButton": {
                  color: "white",
                },
                "& 	.MuiDataGrid-filterIcon": {
                  color: "white",
                },
              }}
              componentsProps={{
                loadingOverlay: {
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                },
                noRowsOverlay: {
                  style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  },
                },
              }}
            />
          </div>
        )}
      </>
    </div>
  );
};

export default DeveloperReports;
