import React from "react";
import Dashboardicon from "../../../Assets/Images/Dashboardicon.png";
import Project from "../../../Assets/Images/Project.png";
import Developer from "../../../Assets/Images/Developer.png";
import Group from "../../../Assets/Images/Group.png";
import Acess from "../../../Assets/Images/Acess.png";
import IC from "../../../Assets/Images/IC.png";
import Timesheet from "../../../Assets/Images/Timesheet.png";
import Client from "../../../Assets/Images/Client.png";
import GitHubIcon from "@mui/icons-material/GitHub";

export const teamLeadListItems = [
  {
    id: "0",
    icon: <img src={Dashboardicon} alt="Dashboard" />,
    label: "Dashboard",
    route: "/dashboard",
  },
  {
    id: "1",
    icon: <img src={Project} alt="Project" />,
    label: "Projects",
    route: "/dashboard/projects",
  },
  {
    id: "2",
    icon: <img src={Developer} alt="Developer" />,
    label: "Developer",
    route: "/dashboard/developer",
  },

  {
    id: "4",
    icon: <img src={Group} alt="Group" />,
    label: "Groups",
    route: "/dashboard/groups",
  },
  {
    id: "5",
    icon: <img src={Acess} alt="Acess" />,
    label: "Access Management",
    route: "/dashboard/teams",
  },
  {
    id: "6",
    icon: <img src={IC} alt="IC" />,
    label: "IC Management",
    route: "/dashboard/Icmanagement",
  },
  {
    id: "7",
    icon: <img src={Timesheet} alt="Timesheet" />,
    label: "Time Sheet",
    route: "/dashboard/timesheet",
  },
  {
    id: "8",
    icon: <img src={Client} alt="Client" />,
    label: "Client Requirement",
    route: "/dashboard/client",
  },
  {
    id: "9",
    icon: <GitHubIcon alt="github" />,
    label: "Github",
    route: "/dashboard/githubDetails",
  },
];

export const projectManagerListItems = [
  {
    id: "0",
    icon: <img src={Dashboardicon} alt="Dashboard" />,
    label: "Dashboard",
    route: "/dashboard",
  },
  {
    id: "1",
    icon: <img src={Project} alt="Project" />,
    label: "Projects",
    route: "/dashboard/projects",
  },
  {
    id: "2",
    icon: <img src={Developer} alt="Developer" />,
    label: "Developer",
    route: "/dashboard/developer",
  },
  {
    id: "3",
    icon: <img src={Group} alt="Group" />,
    label: "Groups",
    route: "/dashboard/groups",
  },
  {
    id: "4",
    icon: <img src={IC} alt="IC" />,
    label: "IC Management",
    route: "/dashboard/Icmanagement",
  },
  {
    id: "5",
    icon: <img src={Timesheet} alt="Timesheet" />,
    label: "Time Sheet",
    route: "/dashboard/timesheet",
  },
  {
    id: "6",
    icon: <img src={Client} alt="Client" />,
    label: "Client Requirement",
    route: "/dashboard/client",
  },
  {
    id: "7",
    icon: <GitHubIcon alt="github" />,
    label: "Github",
    route: "/dashboard/githubDetails",
  },
];

export const developerListItems = [
  {
    id: "0",
    icon: <img src={Timesheet} alt="Timesheet" />,
    label: "Time Sheet",
    route: "/dashboard/timesheet",
  },
];

export const salesListItems = [
  {
    id: "0",
    icon: <img src={Dashboardicon} alt="Dashboard" />,
    label: "Dashboard",
    route: "/dashboard",
  },
  {
    id: "1",
    icon: <img src={Developer} alt="Developer" />,
    label: "Developer",
    route: "/dashboard/developer",
  },
  {
    id: "2",
    icon: <img src={IC} alt="IC" />,
    label: "IC Management",
    route: "/dashboard/Icmanagement",
  },
  {
    id: "3",
    icon: <img src={Client} alt="Client" />,
    label: "Client Requirement",
    route: "/dashboard/client",
  },
];

export const hrListItems = [
  {
    id: "0",
    icon: <img src={Dashboardicon} alt="Dashboard" />,
    label: "Dashboard",
    route: "/dashboard",
  },
  {
    id: "1",
    icon: <img src={Developer} alt="Developer" />,
    label: "Developer",
    route: "/dashboard/developer",
  },
  {
    id: "2",
    icon: <img src={IC} alt="IC" />,
    label: "IC Management",
    route: "/dashboard/Icmanagement",
  },
  {
    id: "3",
    icon: <img src={Client} alt="Client" />,
    label: "Client Requirement",
    route: "/dashboard/client",
  },
];
