import React, { useEffect, useState } from "react";
import { Modal, Tooltip } from "antd";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { Select } from "antd";
import { Form } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import SearchIcon from "@mui/icons-material/Search";
import {
  get_users,
  setFilterParameters,
} from "../../Redux/Slices/getUsersSlice";
import {
  getAllProjectManagers,
  getProjects,
  getTechStacks,
  getDesignations,
  getGroups,
  getAllReportingManagers,
} from "../../Redux/Slices/Lists/ListsSlice";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import RegisterDeveloperModal from "./RegisterDevModal";
import { search_user } from "../../Redux/Slices/getUsersSlice";

const DeveloperFilter = ({ setDeveloperName, developerName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("designation");
  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      designation: [],
      isAvailable: [],
      primaryTechStack: [],
      secondaryTechStack: [],
      projects: [],
      seniority: [],
      location: [],
      reportingPm: [],
      group: [],
      Status: [],
      developerType: [],
      techstackCondition: "and",
    },
  });

  const [isFresher, setIsFresher] = useState("true");
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const [count, setcount] = useState(0);
  const [count1, setcount1] = useState(false);

  const [includeNA, setIncludeNA] = useState(true);

  const { searchFilters } = useSelector((store) => store?.getUsers);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    setcount1(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setcount(0);
  };

  const handleCancel = () => {
    setIsModalVisible(false);

    setcount(0);
    reset();

    // Clear any filter-specific state variables here
    setAvailability([]);
    setBillabilityRange([0, 200]);
  };
  const [availability, setAvailability] = useState([]);

  const availabilityOptions = [
    {
      value: "Available",
      label: "Available",
    },
    {
      value: "mayBeAvailable",
      label: "May be available",
    },
    {
      value: "Enagaged",
      label: "Enagaged",
    },
    {
      value: "notAvailable",
      label: "Not Available",
    },
    {
      value: "yetToBeHired",
      label: "Yet To Be Hired",
    },
    {
      value: "offerLetterReleased",
      label: "Offer Letter Released",
    },
    {
      value: "offerLetterAccepted",
      label: "Offer Letter Accepted",
    },
    {
      value: "underTraining",
      label: "Under Training",
    },
    {
      value: "backedOut",
      label: "Backed Out",
    },
    {
      value: "Consultant",
      label: "Consultant",
    },
  ];

  const [billabilityRange, setBillabilityRange] = useState([0, 200]);
  const handleBillabilityChange = (event, newValue) => {
    setBillabilityRange(newValue);
  };
  const {
    projectManagers,
    projects,
    techStacks,
    designations,
    groups,
    reportingManagers,
  } = useSelector((store) => store?.lists);

  useEffect(() => {
    dispatch(getTechStacks());
    dispatch(getDesignations());
    dispatch(getProjects());
    dispatch(getGroups());
    dispatch(getAllProjectManagers({ role: "PM" }));
    dispatch(getAllReportingManagers({ role: "RM" }));
  }, []);

  const handleChangeDesignation = (event) => {
    setIsFresher(event);
    setValue("designation", event);
  };

  const handleChangeProjects = (event) => {
    setIsFresher(event);
    setValue("projects", event);
  };
  const handleChangeSeniority = (event) => {
    setIsFresher(event);
    setValue("seniority", event);
  };
  const handleChangeLocation = (event) => {
    setIsFresher(event);
    setValue("location", event);
  };
  const handleChangeProjectsManager = (event) => {
    setIsFresher(event);
    setValue("reportingPm", event);
  };

  const handleChangeReportingManager = (event) => {
    setIsFresher(event);
    setValue("reportingManager", event);
  };

  const handleChangeGroup = (event) => {
    if (event.includes("N/A")) {
      dispatch(
        search_user({
          group: "N/A",
        }),
      );
    } else {
      setIncludeNA(true);

      setIsFresher(event);
      setValue("group", event);
    }
  };

  const handleChangeDeveloperType = (event) => {
    setIsFresher(event);
    setValue("developerType", event);
  };

  const closeRegisterModalHandler = () => {
    setOpenRegisterModal(false);
  };

  const handleSelectChange = (value) => {
    if (value === "History") {
      navigate("/dashboard/developer/OnOffBoardHistory");
    } else if (value === "Utilisation") {
      navigate("/dashboard/developer/utilisation");
    } else if (value === "Rating") {
      navigate("/dashboard/developer/rating");
    } else if (value === "ProjectStatus") {
      navigate("/dashboard/developer/projectStatus");
    } else if (value === "DepartedDevelopers") {
      navigate("/dashboard/developer/departedDevelopers");
    } else if (value === "InactiveDevelopers") {
      navigate("/dashboard/developer/InactiveDevelopers");
    }
  };

  const handleChangeCheck = (value) => {
    setValue("techstackCondition", value);
  };
  const resetHandler = (key) => {
    const data = {};

    reset();
    setAvailability([]);

    setBillabilityRange([0, 200]);

    const url = "api/v2/users/search";
    setDeveloperName("");
    dispatch(get_users());
    dispatch(setFilterParameters(data));
    setcount1(true);
  };

  const FormHandler = (data) => {
    let data1 = { ...data };

    const minBillability = billabilityRange[0];
    const maxBillability = billabilityRange[1];

    data1.min_billability = minBillability;
    data1.max_billability = maxBillability;
    const dataKeys = Object.keys(data);

    dataKeys.map((key) => {
      if (Array.isArray(data1[`${key}`])) {
        if (data1[`${key}`]?.length === 0) {
          delete data1[`${key}`];
        } else {
          data1[`${key}`] = data1[`${key}`]?.join(",");
        }
      } else {
        data1[`${key}`] = data1[`${key}`]?.trim();
        if (data1[`${key}`] === "") {
          delete data1[`${key}`];
        }
      }
    });
    setcount(count + 1);
    // if(data.techStack)
    dispatch(setFilterParameters(data1));
  };

  useEffect(() => {
    if (count === 1) {
      setIsModalVisible(false);
      setcount(0);
    }
    if (count1) {
      setcount(0);
    }
  }, [count, count1]);

  let data = [];

  projects?.forEach((element) => {
    let dropDown = {
      label: element?.projects?.projectName,
      value: element?.projects?._id,
    };

    data.push(dropDown);
  });

  const [selectedReport, setSelectedReport] = useState("");

  return (
    <Grid
      container
      sx={{
        overflow: "hidden",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          borderRadius: "16px",
        }}
        onSubmit={handleSubmit(FormHandler)}
      >
        {openRegisterModal && (
          <RegisterDeveloperModal
            open={openRegisterModal}
            onClose={closeRegisterModalHandler}
          />
        )}

        <Grid
          container
          sx={{
            width: "100%",
            marginTop: "1rem",
            marginLeft: "0px",
            alignItems: "center",
            borderRadius: "16px",
            paddingBottom: "16px",
            paddingRight: "16px",
          }}
          spacing={2}
        >
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "0px ",
              marginLeft: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="font-semibold text-3xl ml-4">Developer</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            ></div>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "0px !important",
              }}
              gap={1}
            >
              {role !== "PM" &&
                role !== "SALES" &&
                role !== "BA" &&
                role !== "CSM" && (
                  <Button
                    onClick={() => {
                      setOpenRegisterModal(true);
                    }}
                    variant="contained"
                    sx={{
                      background: "#4E79FA",
                      color: "white",
                      height: "56px",
                    }}
                  >
                    + Add Developer
                  </Button>
                )}
            </Grid>

            <Grid
              container
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "-5px",
                marginLeft: "0px",
              }}
            >
              <Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "40px",

                      placeItems: "center",
                    }}
                  >
                    <TextField
                      id="outlined-required"
                      name="keyword"
                      type={"text"}
                      sx={{
                        bottom: "-6px",
                        marginLeft: "15px",
                        marginTop: "1px",
                        height: "100%",

                        width: "25rem",
                        borderRadius: "8px",
                        "& div": {
                          height: "100%",
                          backgroundColor: "white",
                        },
                      }}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search developer name "
                      value={developerName}
                      onChange={(e) => setDeveloperName(e.target.value)}
                      autoComplete={"off"}
                    />
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "0.5rem",
                      marginLeft: "1rem",
                    }}
                  >
                    <Button
                      className="h-10 flex items-center justify-center  w-24 cursor-pointer py-16 px-20"
                      onClick={showModal}
                      endIcon={<FilterOutlined className="mr-1" />}
                      variant="outlined"
                      sx={{
                        borderRadius: "8px",
                        marginRight: "10px",

                        backgroundColor:
                          Object.keys(searchFilters).length > 0
                            ? "#4E79FA"
                            : "white",
                        color:
                          Object.keys(searchFilters).length > 0
                            ? "white"
                            : "black",
                      }}
                    >
                      Filter
                    </Button>

                    <Button
                      className="h-10 rounded-lg w-24 py-16 px-24"
                      variant="outlined"
                      color="primary"
                      sx={{
                        borderRadius: "8px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        reset();
                        resetHandler(true);
                        setcount(0);
                      }}
                    >
                      Reset
                    </Button>

                    {role !== "SALES" && (
                      <div>
                        <Select
                          value={selectedReport}
                          onChange={(value) => {
                            setSelectedReport(value);
                            handleSelectChange(value);
                          }}
                          size="large"
                          style={{ width: "10rem" }}
                        >
                          <Select.Option value="">Reports</Select.Option>
                          {role !== "HR" && (
                            <Select.Option value="History">
                              <Tooltip title="On/Off-boarding History">
                                <p>On/Off-boarding History</p>
                              </Tooltip>
                            </Select.Option>
                          )}
                          <Select.Option value="Utilisation">
                            <p>%Utilisation</p>
                          </Select.Option>
                          <Select.Option value="Rating">
                            <p>Rating</p>
                          </Select.Option>
                          <Select.Option value="DepartedDevelopers">
                            <p>Departed Developers</p>
                          </Select.Option>
                          {role !== "HR" && (
                            <>
                              <Select.Option value="ProjectStatus">
                                <p>Project Status</p>
                              </Select.Option>
                              <Select.Option value="InactiveDevelopers">
                                <p>Active/Inactive Developers</p>
                              </Select.Option>
                            </>
                          )}
                        </Select>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <Modal
                      title="Filter Developers"
                      open={isModalVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                      style={{
                        minWidth: 730,
                        top: 25,

                        maxHeight: "70vh",
                      }}
                    >
                      {/* form */}
                      <div className=" ml-3 b mt-4 ">
                        <Form layout="vertical">
                          <div className="flex justify-between items-center">
                            <Form.Item
                              label="Tech Stack"
                              name="techStack-select"
                              id="techStack-select"
                            >
                              <Controller
                                name="primaryTechStack"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    id="techStack-select"
                                    mode="multiple"
                                    allowClear
                                    style={{ width: 320, fontSize: "10px" }}
                                    value={field.value}
                                    onChange={field.onChange}
                                    // placeholder="Tech Stack"
                                    defaultValue={
                                      searchFilters?.primaryTechStack
                                    }
                                    className={
                                      errors.primaryTechStack ? "error" : ""
                                    }
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .startsWith(input.toLowerCase())
                                    }
                                  >
                                    <Select.Option key="NULL" value="">
                                      Default
                                    </Select.Option>
                                    {techStacks?.map((techStack) => (
                                      <Select.Option
                                        key={techStack.name}
                                        value={techStack._id}
                                        style={{
                                          fontSize: "12px",
                                          padding: "3px",
                                        }}
                                      >
                                        {techStack.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )}
                              />
                            </Form.Item>

                            <Form.Item
                              label="TechStack Condition"
                              name="techstackCondition"
                              validateStatus={
                                errors.techstackCondition ? "error" : ""
                              }
                              help={errors.techstackCondition}
                            >
                              <Controller
                                name="techstackCondition"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    style={{ width: 320, fontSize: "10px" }}
                                    value={getValues("techstackCondition")}
                                    // defaultValue={
                                    //   searchFilters?.techstackCondition
                                    // }
                                    onChange={handleChangeCheck}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .startsWith(input.toLowerCase())
                                    }
                                  >
                                    <Select.Option value="and">
                                      AND
                                    </Select.Option>
                                    <Select.Option value="or">OR</Select.Option>
                                  </Select>
                                )}
                              />
                            </Form.Item>
                          </div>

                          <div className="flex justify-between items-center">
                            <Form.Item
                              label="Seniority"
                              name="seniority"
                              validateStatus={errors.seniority ? "error" : ""}
                              help={errors.seniority}
                            >
                              <Controller
                                name="techStack"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    mode="multiple"
                                    style={{ width: 320, fontSize: "10px" }}
                                    value={getValues("seniority")}
                                    defaultValue={searchFilters?.seniority}
                                    onChange={handleChangeSeniority}
                                  >
                                    <Select.Option value="">
                                      Default
                                    </Select.Option>
                                    <Select.Option value="Intern">
                                      Intern
                                    </Select.Option>
                                    <Select.Option value="Junior">
                                      Junior
                                    </Select.Option>
                                    <Select.Option value="Mid Level">
                                      Mid Level
                                    </Select.Option>
                                    <Select.Option value="Senior">
                                      Senior
                                    </Select.Option>
                                  </Select>
                                )}
                              />
                            </Form.Item>

                            <Form.Item label="Designation" name="Designation">
                              <Controller
                                name="techStack"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    mode="multiple"
                                    style={{ width: 320, fontSize: "10px" }}
                                    value={getValues("designation")}
                                    defaultValue={searchFilters?.techStack}
                                    onChange={handleChangeDesignation}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .startsWith(input.toLowerCase())
                                    }
                                  >
                                    <Select.Option value="">
                                      Default
                                    </Select.Option>
                                    {designations.map((designation) => (
                                      <Select.Option
                                        key={designation._id}
                                        value={designation._id}
                                      >
                                        {designation.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )}
                              />
                            </Form.Item>
                          </div>

                          <div className="flex justify-between items-center">
                            <Form.Item label="Availability" name="isAvailable">
                              <Controller
                                name="techStack"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Select
                                      mode="multiple"
                                      style={{ width: 320, fontSize: "10px" }}
                                      // placeholder="Select"
                                      value={availability}
                                      onChange={(selectedValues) => {
                                        setAvailability(selectedValues);
                                        setValue("isAvailable", selectedValues);
                                      }}
                                    >
                                      {availabilityOptions.map((option) => (
                                        <Select.Option
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </>
                                )}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Location"
                              name="location"
                              validateStatus={errors.location ? "error" : ""}
                              help={errors.location}
                            >
                              <Controller
                                name="techStack"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    mode="multiple"
                                    style={{ width: 320, fontSize: "10px" }}
                                    value={getValues("location")}
                                    defaultValue={searchFilters?.location}
                                    onChange={handleChangeLocation}
                                  >
                                    <Select.Option value="Gurgaon">
                                      Gurgaon
                                    </Select.Option>
                                    <Select.Option value="Bangalore">
                                      Bangalore
                                    </Select.Option>
                                  </Select>
                                )}
                              />
                            </Form.Item>
                          </div>

                          {/* developer type */}
                          <div className="flex justify-between items-center">
                            <Form.Item
                              label="DeveloperType"
                              name="developerType"
                              validateStatus={
                                errors.developerType ? "error" : ""
                              }
                              help={errors.developerType}
                            >
                              <Controller
                                name="developerType"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    style={{ width: 320, fontSize: "10px" }}
                                    // value={developerType}
                                    value={getValues("developerType")}
                                    defaultValue={searchFilters?.developerType}
                                    onChange={handleChangeDeveloperType}
                                  >
                                    <Select.Option value="YetToHire">
                                      YetToHire
                                    </Select.Option>
                                    <Select.Option value="InHouse">
                                      InHouse
                                    </Select.Option>
                                  </Select>
                                )}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Group"
                              name="group"
                              validateStatus={errors.group ? "error" : ""}
                              help={errors.group}
                            >
                              <Controller
                                name="group"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    mode="multiple"
                                    style={{ width: 320, fontSize: "10px" }}
                                    value={getValues("group")}
                                    defaultValue={searchFilters?.group}
                                    onChange={handleChangeGroup}
                                  >
                                    <Select.Option value="">
                                      Default
                                    </Select.Option>
                                    <Select.Option value="NA">NA</Select.Option>
                                    {groups?.map((group) => (
                                      <Select.Option
                                        key={group._id}
                                        value={group._id}
                                      >
                                        {group.group[0]?.groupName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )}
                              />
                            </Form.Item>
                          </div>

                          <div className="flex justify-between items-center">
                            <Form.Item
                              label="Projects"
                              name="projects"
                              validateStatus={errors.projects ? "error" : ""}
                              help={errors.projects}
                            >
                              <Controller
                                name="techStack"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    mode="multiple"
                                    style={{ width: 320 }}
                                    value={getValues("projects")}
                                    // onChange={handleChangeProjects}
                                    defaultValue={searchFilters?.projects}
                                    onChange={handleChangeProjects}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .startsWith(input.toLowerCase())
                                    }
                                    showSearch
                                    // placeholder="Search for Projects"
                                  >
                                    <Select.Option value="">
                                      Default
                                    </Select.Option>
                                    <Select.Option value="NA">NA</Select.Option>

                                    {projects?.map((item) => (
                                      <Select.Option
                                        key={item.projects._id}
                                        value={item.projects._id}
                                      >
                                        {item.projects.projectName}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                )}
                              />
                            </Form.Item>

                            {role !== "PM" &&
                              role !== "BA" &&
                              role !== "CSM" && (
                                <Form.Item
                                  label="Project Manager"
                                  name="reportingPm"
                                  validateStatus={
                                    errors.reportingPm ? "error" : ""
                                  }
                                  help={errors.reportingPm}
                                >
                                  <Controller
                                    name="techStack"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        mode="multiple"
                                        style={{ width: 320, fontSize: "10px" }}
                                        value={getValues("reportingPm")}
                                        defaultValue={
                                          searchFilters?.reportingPm
                                        }
                                        onChange={handleChangeProjectsManager}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        showSearch
                                        // placeholder="Search for Project Managers"
                                      >
                                        <Select.Option value="">
                                          Default
                                        </Select.Option>
                                        {projectManagers?.map((pm) => (
                                          <Select.Option
                                            key={pm._id}
                                            value={pm._id}
                                          >
                                            {pm.fullName}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  />
                                </Form.Item>
                              )}
                          </div>
                          <div className="flex justify-between items-center">
                            {role !== "PM" &&
                              role !== "BA" &&
                              role !== "CSM" && (
                                <Form.Item
                                  label="Reporting Manager"
                                  name="reportingManager"
                                  validateStatus={
                                    errors.reportingManager ? "error" : ""
                                  }
                                  help={errors.reportingManager}
                                >
                                  <Controller
                                    name="reportingManager"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        mode="multiple"
                                        style={{ width: 320, fontSize: "10px" }}
                                        value={getValues("reportingManager")}
                                        defaultValue={
                                          searchFilters?.reportingManager
                                        }
                                        onChange={handleChangeReportingManager}
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        showSearch={false}
                                        // placeholder="Search for Project Managers"
                                      >
                                        <Select.Option value="NA">
                                          NA
                                        </Select.Option>
                                        {/* {projectManagers?.map((pm) => ( */}
                                        {reportingManagers?.map((pm) => (
                                          <Select.Option
                                            key={pm._id}
                                            value={pm._id}
                                          >
                                            {pm.fullName} ({pm.role})
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  />
                                </Form.Item>
                              )}
                          </div>
                          {/* range picker */}
                          <div className="flex justify-between items-center">
                            <Typography
                              id="billability-range-slider"
                              gutterBottom
                            >
                              Billability
                            </Typography>
                            <Slider
                              style={{ width: "80%", margin: "0 auto" }}
                              value={billabilityRange}
                              onChange={handleBillabilityChange}
                              valueLabelDisplay="auto"
                              valueLabelFormat={(value) => `${value}%`}
                              aria-labelledby="billability-range-slider"
                              min={0}
                              max={200}
                            />
                          </div>
                        </Form>
                      </div>
                      <div className="flex justify-between items-center">
                        <div>
                          <Button
                            sx={{
                              color: "#4E79FA",

                              backgroundColor: "white",
                              width: "320px",
                              height: "46px",
                              borderRadius: "8px",

                              padding: "0px 40px",
                              // gap: "8px",
                              transition: "background-color 0.3s, color 0.3s",
                              "&:hover": {
                                backgroundColor: "#4E79FA",
                                color: "white",
                              },
                            }}
                            variant="contained"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div>
                          <Button
                            onClick={handleSubmit(FormHandler)}
                            sx={{
                              color: "#4E79FA",
                              backgroundColor: "white",
                              width: "320px",
                              height: "46px",
                              borderRadius: "8px",
                              padding: "0px 40px",
                              marginRight: "10px",

                              transition: "background-color 0.3s, color 0.3s",
                              "&:hover": {
                                backgroundColor: "#4E79FA",
                                color: "white",
                              },
                            }}
                            type="submit"
                            variant="contained"
                          >
                            Apply Filter
                          </Button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* ------ */}
      </Grid>
    </Grid>
  );
};

export default DeveloperFilter;
