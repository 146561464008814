import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../apiclient";
import { toast } from "react-toastify";


const initialState = {
  isLoading: false,
  groups: [],
  error: "",
  utilization: [],
  getProjectStatusData: [],
  statusLoading: false,
};

export const postUtilization = createAsyncThunk(
  "postUtilization/bw_rtg/add",
  async (data, { rejectWithValue }) => {
    try {
      let response = await api.post("api/v2/bw_rtg/add", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGroups = createAsyncThunk(
  "groups/getGroups",
  async (status) => {
    try {
      const isDeleted = status ? status : false;
      const group_url = `api/v2/group/viewall?isDelete=${isDeleted}`;
      let res = await api.get(group_url);
      return res.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const addGroup = createAsyncThunk(
  "groups/addGroup",
  async (data, { rejectWithValue }) => {
    try {
      const group_url = "api/v2/group/add-group";
      let res = await api.post(group_url, data);
      return res.data;
    } catch (error) {
      toast(error.response.data.resp.message);
      return rejectWithValue(error.response);
      // return error.response;
    }
  }
);

export const getGroupDevelopers = createAsyncThunk(
  "groups/getGroupDevelopers",
  async (data, { rejectWithValue }) => {
    try {
      let response = await api.get(
        `api/v2/group/getDevelopers?groupId=${data.id}&date=${data.selectedDate}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addDevelopers = createAsyncThunk(
  "groups/addDevelopers",
  async (data, { rejectWithValue }) => {
    try {
      const payload = { ...data, Action: "Assigned" };
      const group_url = "api/v2/group/onboard";
      let res = await api.post(group_url, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const removeDeveloper = createAsyncThunk(
  "groups/removeDeveloper",
  async (data) => {
    try {
      const payload = {
        Action: "Unassigned",
        userId: [data.developerId],
        groupId: data.groupName,
      };
      const remove_url = "api/v2/group/onboard";
      let res = await api.post(remove_url, payload);
      return res.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const getProjectStatus = createAsyncThunk(
  "groups/getProjectUtilisation",
  async (data, { rejectWithValue }) => {
    try {
      let response = await api.get(
        `api/v2/projectStatus/getProjectStatus?&date=${data.formattedDate}&groupId=${data.groupId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postProjectStatus = createAsyncThunk(
  "groups/postProjectStatus",
  async (data, { rejectWithValue }) => {
    try {
      let response = await api.post(`api/v2/projectStatus/add`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGroupDevelopers.fulfilled, (state, action) => {
      state.error = "";
    });
    builder.addCase(getGroupDevelopers.pending, (state, action) => {
      state.error = "";
    });
    builder.addCase(getGroupDevelopers.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(getProjectStatus.fulfilled, (state, action) => {
      state.getProjectStatusData = action.payload.data;
      state.statusLoading = false;
    });
    builder.addCase(getProjectStatus.pending, (state, action) => {
      state.getProjectStatusData = [];
      state.statusLoading = true;
    });
    builder.addCase(getProjectStatus.rejected, (state, action) => {
      state.getProjectStatusData = [];
      state.statusLoading = false;
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groups = action?.payload?.resp;
      state.error = "";
    });
    builder.addCase(getGroups.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getGroups.rejected, (state, action) => {
      state.isLoading = false;
      state.groups = [];
      state.error = action.error.message;
    });
    builder.addCase(addGroup.fulfilled, (state, action) => {
      // console.log(action.payload.data);
    });
    builder.addCase(addGroup.rejected, (state, action) => {
      state.error = action.payload.data;
    });
    builder.addCase(addDevelopers.fulfilled, (state, action) => {
      // console.log(action.payload);
    });
    builder.addCase(postUtilization.fulfilled, (state, action) => {
      state.utilization = action.payload;
      state.isLoading = false;
    });
    builder.addCase(postUtilization.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(postUtilization.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
    builder.addCase(removeDeveloper.fulfilled, (state, action) => {
      console.log(action?.payload);
    });
  },
});

export const GroupsReducer = groupsSlice.reducer;
