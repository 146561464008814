import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { editProject, getProject } from "../../../apis/ProjectApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getGroups,
  getTechStacks,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { toast } from "react-toastify";
import { getProjects } from "../../../Redux/Slices/Project/ProjectsSlice";
import CreateProjectSchema from "./CreateProjectSchema";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  container: {
    gap: "10px",
  },
  containerItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
  },
  inputItem: {
    width: "100%",
  },
  paper: { minWidth: "500px" },
  customScrollbar: {
    scrollbarWidth: "thin", // For Firefox
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
}));

const EditProject = ({ open, onClose, id, projectData }) => {
  const [project, setProject] = useState();
  const classes = useStyles();
  const [eventValue, setEventValue] = useState("");
  const [eventList, setEventList] = useState([]);
  const [eventName, setEventName] = useState("");
  const [estimatedEnddate, setEstimatedEnddate] = useState("");
  const completionDate = null;
  const isCompleted = false;
  const [isStatusChange, setIsStatusChange] = useState(false);

  const addEvent = () => {
    if (eventValue.trim() === "" || estimatedEnddate.trim() === "") {
      if (eventValue.trim() === "") {
        toast.error("Event description missing");
      }
      return; // Don't add if eventValue or estimatedEnddate is empty
    }
    const newEvent = {
      estimatedEnddate,
      eventValue,
      completionDate,
      isCompleted,
      eventName,
    };
    setEventList([...eventList, newEvent]);
    setEventValue(""); // Clear the input field after adding a todo
    setEstimatedEnddate("");
    setEventName("");
  };
  const deleteEventHandler = (index) => {
    const newEvents = eventList.filter((event, i) => i !== index);
    setEventList(newEvents);
  };

  const markAsCompleteHandler = (index) => {
    const allPreviousEventsCompleted = eventList
      .slice(0, index)
      .every((event) => event.isCompleted);

    if (!allPreviousEventsCompleted) {
      toast.error("Complete previous events first.");
      return;
    }

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const newEvents = eventList.map((event, i) => {
      if (i === index) {
        return {
          ...event,
          isCompleted: true,
          completionDate: formattedDate, // Store the formatted date without timestamp
        };
      } else {
        return event;
      }
    });
    setEventList(newEvents);
  };

  const onChangeDeadlineHandler = (e, index) => {
    const newEvents = eventList.map((event, i) => {
      if (i === index) {
        return { ...event, completionDate: e };
      } else {
        return event;
      }
    });
    setEventList(newEvents);
  };

  const onChangeEventHandler = (e, index) => {
    const newEvents = eventList.map((event, i) => {
      if (i === index) {
        return { ...event, eventValue: e };
      } else {
        return event;
      }
    });
    setEventList(newEvents);
  };

  useEffect(() => {
    getProject(id)
      .then((res) => {
        setProject(res.data);
        const formattedEvent = res?.data?.events?.map((event) => ({
          ...event,
          estimatedEnddate: dayjs(event?.estimatedEnddate).format("YYYY-MM-DD"),
        }));
        setEventList(formattedEvent);
      })
      .catch((error) => console.log(error?.response?.data?.message));
  }, [id]);

  const projectType = [
    "Fixed Scope Budget",
    "Fixed Scope Premium",
    "Developers On Demand",
    "Dedicated Team Model",
    "Shared Tech Team",
    "Monthly Maintenance",
  ];
  const status = ["Active", "Inactive", "Onhold"];

  const handleSubmit = (values) => {
    const techStackIds = values?.techStack.map((stack) => stack?._id);
    const groupId = values?.group?.groupId;

    let obj = {
      id: id,
      updateData: {
        projectName: values?.projectName,
        clientName: values?.clientName,
        startDate: dayjs(values?.startDate).format("MM-DD-YYYY"),
        estimatedEndDate: dayjs(values?.estimatedEndDate).format("MM-DD-YYYY"),
        techStack: techStackIds,
        typeOfProject: values?.typeOfProject,
        demoUrls: values?.demoUrls,
        group: groupId,
        clientPointOfContact: values?.clientPointOfContact,
        status: values?.status,
        description: values?.description,
        events: eventList,
        statusReason: values?.statusReason,
        modifiedStatusDate: dayjs(values?.modifiedStatusDate).format(
          "MM-DD-YYYY"
        ),
      },
    };

    if (obj.updateData.status === "Inactive") {
      obj.updateData.developers = project?.developers
        ? project?.developers
        : [];
    }

    editProject(obj)
      .then((res) => {
        toast("Project successfully updated.");
        setTimeout(() => {
          formik.handleReset();
          onClose();
          dispatch(getProjects("Active"));
        }, 1000);
      })
      .catch((error) => toast(error?.response?.data?.message));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: project?.projectName ? project?.projectName : "",
      clientName: project?.clientName ? project?.clientName : "",
      startDate: project?.startDate,
      estimatedEndDate: project?.estimatedEndDate
        ? project?.estimatedEndDate
        : "",
      techStack: project?.techStack ? project?.techStack : [],
      group: project?.group?.groupName
        ? project?.group
        : { groupName: "", groupId: "" },
      typeOfProject: project?.typeOfProject ? project?.typeOfProject : "",
      demoUrls: project?.demoUrls ? project?.demoUrls : "",
      clientPointOfContact: project?.clientPointOfContact
        ? project?.clientPointOfContact
        : "",
      status: project?.status ? project?.status : "",
      description: project?.description ? project?.description : "",
      events: project?.events ? project?.events : eventList,
      statusReason: project?.statusReason ? project?.statusReason : "",
      modifiedStatusDate: project?.modifiedStatusDate
        ? project?.modifiedStatusDate
        : "",
    },
    validationSchema: CreateProjectSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const dispatch = useDispatch();

  const techStack = useSelector((store) => store?.lists?.techStacks);
  const { groups } = useSelector((store) => store?.lists);

  const groupList = groups.map((group) => {
    return {
      groupName: group.group[0].groupName,
      groupId: group.group[0]._id,
    };
  });

  const isFutureDate = (dateString) => {
    const selectedDate = new Date(dateString);
    const today = new Date();
    return selectedDate > today;
  };

  useEffect(() => {
    dispatch(getTechStacks());
    dispatch(getGroups());
  }, []);

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Dialog
            className={classes.paper}
            maxWidth={"sm"}
            open={open}
            close={onClose}
            PaperProps={{
              sx: {
                minHeight: "80vh",
                borderRadius: "16px",
              },
            }}
            sx={{
              "& .MuiDialogActions-root": {
                padding: "0px !important",
              },
            }}
          >
            <DialogTitle>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "30px",
                  // borderBottom: "1px solid #F0F0F0",
                }}
              >
                Update Project
                <Button
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                  }}
                  onClick={onClose}
                >
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                </Button>
              </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.customScrollbar}>
              <Grid
                container
                sx={{
                  "& 	.MuiAutocomplete-popper": {
                    paddingBottom: "1rem",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#D32F2F",
                  },
                }}
                mt={1}
                spacing={2}
              >
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Project Name
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    required
                    value={formik.values.projectName}
                    name="projectName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // label="Project Name"
                    helperText={
                      formik.touched.projectName
                        ? formik.errors.projectName
                        : ""
                    }
                    autoComplete="off"
                    placeholder="Enter Project Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Client Name
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    required
                    value={formik.values.clientName}
                    name="clientName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // label="Client Name"
                    helperText={
                      formik.touched.clientName ? formik.errors.clientName : ""
                    }
                    autoComplete="off"
                    placeholder="Enter Client Name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Project Start Date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    {" "}
                    <DesktopDatePicker
                      inputFormat="MM-DD-YYYY"
                      // label="Start date"
                      sx={{
                        width: "100%",
                      }}
                      value={
                        formik?.values?.startDate &&
                        new Date(
                          moment(formik?.values?.startDate).format("MM-DD-YYYY")
                        )
                      }
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                      name="startDate"
                      onChange={(newValue) =>
                        formik.setFieldValue("startDate", newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                          onBlur={(e) => {
                            formik.setFieldTouched("startDate", true);
                          }}
                          helperText={
                            formik.touched.startDate
                              ? formik.errors.startDate
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Project End Date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>{" "}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      // label="Estimated End date "
                      dateFormat="MM-DD-YYYY"
                      sx={{
                        width: "100%",
                      }}
                      // defaultValue={formik?.values?.estimatedEndDate}
                      value={
                        formik?.values?.estimatedEndDate &&
                        new Date(
                          moment(formik?.values?.estimatedEndDate).format(
                            "MM-DD-YYYY"
                          )
                        )
                      }
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                      onBlur={formik.handleBlur}
                      onChange={(newValue) => {
                        formik.setFieldValue("estimatedEndDate", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          helperText={
                            formik.touched.estimatedEndDate
                              ? formik.errors.estimatedEndDate
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Tech Stack
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <Autocomplete
                    className={classes.inputItem}
                    freeSolo
                    options={techStack?.filter(
                      (option) =>
                        !formik?.values?.techStack.some(
                          (item) => item === option?._id
                        )
                    )}
                    disableCloseOnSelect={true}
                    multiple
                    getOptionLabel={(option) => option?.name}
                    value={formik?.values?.techStack}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) =>
                      formik.setFieldValue("techStack", value)
                    }
                    filterSelectedOptions
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        name="techStack"
                        // label="Tech Stack"
                        value={formik.values.techStack}
                        helperText={
                          formik.touched.techStack
                            ? formik.errors.techStack
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Project Type
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <Autocomplete
                    options={projectType || []}
                    className={classes.inputItem}
                    name="typeOfProject"
                    getOptionLabel={(option) =>
                      option?.isDeleted ? "" : option
                    }
                    value={formik.values.typeOfProject}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) =>
                      formik.setFieldValue("typeOfProject", value)
                    }
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik.values.typeOfProject}
                        name="typeOfProject"
                        // label="Project Type"
                        helperText={
                          formik.touched.typeOfProject
                            ? formik.errors.typeOfProject
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Status
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <Autocomplete
                    options={status || []}
                    className={classes.inputItem}
                    name="status"
                    getOptionLabel={(option) =>
                      option?.isDeleted ? "" : option
                    }
                    value={formik.values.status}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) => {
                      formik.setFieldValue("status", value);
                      setIsStatusChange(true);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik.values.status}
                        name="status"
                        helperText={
                          formik.touched.status ? formik.errors.status : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Status Change Reason
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <TextArea
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.statusReason}
                    name="statusReason"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="statusReason"
                    required
                    helperText={
                      formik.touched.statusReason
                        ? formik.errors.statusReason
                        : ""
                    }
                    autoComplete="off"
                    disabled={!isStatusChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Status Change Date
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        lineHeight: "16px",
                        marginLeft: "2px",
                      }}
                    >
                      *
                    </Typography>
                  </Box>{" "}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      dateFormat="MM-DD-YYYY"
                      sx={{
                        width: "100%",
                      }}
                      value={
                        formik?.values?.modifiedStatusDate &&
                        new Date(
                          moment(formik?.values?.modifiedStatusDate).format(
                            "MM-DD-YYYY"
                          )
                        )
                      }
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                      disabled={!isStatusChange}
                      onBlur={formik.handleBlur}
                      onChange={(newValue) => {
                        formik.setFieldValue("modifiedStatusDate", newValue);
                      }}
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          {...params}
                          helperText={
                            formik.touched.estimatedEndDate
                              ? formik.errors.estimatedEndDate
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Client Contact Number
                    </Typography>
                  </Box>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    required
                    value={formik.values.clientPointOfContact}
                    name="clientPointOfContact"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    // label="Client Contact Details"
                    helperText={
                      formik.touched.clientPointOfContact
                        ? formik.errors.clientPointOfContact
                        : ""
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "25px",
                    }}
                  >
                    Demo URL
                  </Typography>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.demoUrls}
                    name="demoUrls"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Demo urls"
                    helperText={
                      formik.touched.demoUrls ? formik.errors.demoUrls : ""
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Group
                    </Typography>
                  </Box>
                  <Autocomplete
                    options={groupList || []}
                    className={classes.inputItem}
                    name="group"
                    getOptionLabel={(option) => option?.groupName}
                    onChange={(event, value) =>
                      formik.setFieldValue("group", value)
                    }
                    filterSelectedOptions
                    onBlur={formik.handleBlur}
                    value={formik?.values?.group}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={formik?.values?.group}
                        name="group"
                        helperText={
                          formik.touched?.group ? formik.errors?.group : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "15px",
                        lineHeight: "25px",
                      }}
                    >
                      Add Description
                    </Typography>
                  </Box>
                  <TextArea
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.description}
                    name="description"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    label="Description(500 words)"
                    helperText={
                      formik.touched.description
                        ? formik.errors.description
                        : ""
                    }
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "25px",
                    marginTop: "1rem",
                  }}
                >
                  Update Events
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "0px 5px 10px rgba(11, 10, 10, 0.3)",
                    padding: "16px 24px 16px 24px",
                    gap: "0.3rem",
                    marginBotton: "1rem",
                    borderRadius: "16px",
                  }}
                >
                  <textarea
                    type="text"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    placeholder="Event Name"
                    style={{
                      border: "2px solid #F0F0F0",
                      borderRadius: "4px",
                      padding: "6px",
                      // marginBottom: "1rem",
                      width: "50%",
                      height: "2.5rem",
                    }}
                    maxLength="25"
                    disabled={eventList.length === 5}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      fontSize: "14px",
                    }}
                  >
                    <p>Estimated End Date</p>
                    <input
                      type="date"
                      value={estimatedEnddate}
                      onChange={(e) => setEstimatedEnddate(e.target.value)}
                      placeholder="Add a deadline date"
                      style={{
                        border: "2px solid #F0F0F0",
                        borderRadius: "4px",
                        padding: "6px",
                      }}
                      min={dayjs(formik?.values?.startDate).format(
                        "YYYY-MM-DD"
                      )}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "0.2rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <textarea
                      type="text"
                      value={eventValue}
                      onChange={(e) => setEventValue(e.target.value)}
                      placeholder="Event description"
                      style={{
                        border: "2px solid #F0F0F0",
                        borderRadius: "4px",
                        padding: "6px",
                        width: "90%",
                        height: "2.5rem",
                      }}
                      disabled={eventList.length === 5}
                    />
                    <button
                      onClick={addEvent}
                      className="add-btn"
                      // disabled={eventValue.trim() === ""}
                    >
                      +
                    </button>
                  </div>
                </div>
                <ul>
                  {eventList?.map((event, index) => (
                    <li
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        borderRadius: "16px",
                        padding: "16px 24px 16px 24px",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <textarea
                          type="text"
                          value={event.eventName}
                          style={{
                            border: "2px solid #F0F0F0",
                            borderRadius: "4px",
                            padding: "6px",
                            // marginBottom: "1rem",
                            width: "30%",
                            height: "2.5rem",
                          }}
                          disabled={true}
                        />
                        <Box style={{ display: "flex" }}>
                          <Button
                            onClick={() => deleteEventHandler(index)}
                            variant="outlined"
                            style={{
                              height: "40px",
                              color: "red",
                              borderColor: "red",
                              fontWeight: "bold",
                              marginLeft: "10px",
                              cursor: "pointer",
                              boxShadow: "0px 5px 10px rgba(11, 10, 10, 0.3)",
                            }}
                          >
                            Remove Event
                          </Button>
                          <Button
                            onClick={() => markAsCompleteHandler(index)}
                            variant="outlined"
                            style={{
                              height: "40px",
                              color: event.isCompleted ? "white" : "#4E79FA",
                              backgroundColor: event.isCompleted
                                ? "green"
                                : "white",
                              borderColor: event.isCompleted
                                ? "green"
                                : "4E79FA",
                              fontWeight: "bold",
                              marginLeft: "10px",
                              cursor: event.isCompleted
                                ? "not-allowed"
                                : "pointer",
                              boxShadow: "0px 5px 10px rgba(11, 10, 10, 0.3)",
                            }}
                            disabled={event.isCompleted}
                          >
                            {event.isCompleted ? (
                              <p>Completed</p>
                            ) : (
                              <p>Mark As Complete</p>
                            )}
                          </Button>
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "1rem",
                          fontSize: "14px",
                        }}
                      >
                        <p>Estimated End Date</p>
                        <input
                          value={event.estimatedEnddate}
                          onChange={(e) =>
                            onChangeDeadlineHandler(e.target.value, index)
                          }
                          style={{
                            border: "2px solid #F0F0F0",
                            borderRadius: "4px",
                            padding: "6px",
                            width: "30%",
                          }}
                          disabled={true}
                        />
                      </Box>
                      <Box>
                        <textarea
                          value={event.eventValue}
                          onChange={(e) =>
                            onChangeEventHandler(e.target.value, index)
                          }
                          style={{
                            border: "2px solid #F0F0F0",
                            borderRadius: "4px",
                            padding: "6px",
                            width: "100%",
                            height: "2.5rem",
                          }}
                        ></textarea>
                      </Box>
                    </li>
                  ))}
                </ul>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box className="mr-2 mb-4 mt-16">
                <Button
                  onClick={onClose}
                  // sx={{ color: "blue", width: "90%" }}
                  size="medium"
                  variant="contained"
                  sx={{
                    color: "#4E79FA",
                    backgroundColor: "white",
                    width: "260px",
                    height: "46px",
                    borderRadius: "8px",
                    padding: "0px 36px",
                    // gap: "8px",
                    transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      backgroundColor: "#4E79FA",
                      color: "white",
                    },
                  }}
                >
                  Go Back
                </Button>
              </Box>
              <Box className="mr-10 mb-4 mt-16">
                <Button
                  onClick={formik.handleSubmit}
                  variant="contained"
                  sx={{
                    color: "#4E79FA",
                    backgroundColor: "white",
                    width: "260px",
                    height: "46px",
                    borderRadius: "8px",
                    padding: "0px 36px",
                    transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                    backgroundColor: "#4E79FA",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#4E79FA",
                    },
                  }}
                >
                  Save Changes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </>
  );
};

export default EditProject;
