import api from "../apiclient";


export const addIC = async (data) => {
  const res = await api.post("/api/v2/ic/createIc", data);
  return res?.data;
};

export const GetIC = async (id) => {
  const res = await api.get(`api/v2/ic/viewIcById?icId=${id}`);
  return res?.data;
};

export const UpdateIC = async (id, payload) => {
  const res = await api.post(`api/v2/ic/doEditIc?icId=${id}`, payload);

  return res?.data;
};

// export const EditIC = async (data) => {

//     const res = await api.get("/api/v2/ic/updateIc", data);
//     console.log("addIC",res?.data)
//     return res?.data;
//   };

export const getAllIC = async (payload) => {
  const res = await api.get(`/api/v2/ic/getAllIc?&developerType=${
    payload?.developerType ? payload?.developerType : ""
  }&developer_id=${payload?.developer ? payload?.developer : ""}&techStackId=${
    payload?.techStack ? payload?.techStack : ""
  }&clientId=${payload?.clientName ? payload?.clientName : ""}&scheduledBy=${
    payload?.scheduledBy ? payload?.scheduledBy : ""
  }&feedBack=${payload?.feedBack ? payload?.feedBack : ""}&name=${
    payload?.Searchclientname ? payload?.Searchclientname : ""
  }
   `);

  return res?.data;
};

export const getAllProjectManagers = async () => {
  const res = await api.get("/api/v2/users/search", {
    params: {
      role: "PM",
    },
  });
  return res?.data;
};

export const getDeveloperNames = async () => {
  const res = await api.get("api/v2/users/listIcUsers");
  return res?.data;
};

export const getClientName = async () => {
  const res = await api.get("api/v2/users/listClient");
  return res?.data;
};

export const removeIC = async (id) => {
  return await api.delete(`api/v2/ic/deleteIc?icId=${id}`);
};
  




  // <<< Reports>>>>>>>>>>>




  
