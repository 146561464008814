import { Modal, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatusChangeLogs } from "../../../Redux/Slices/Project/ProjectsSlice";
import dayjs from "dayjs";

const StatusChangeLogModal = ({ open, onClose, id }) => {
  const dispatch = useDispatch();
  const { statusChangeLogs, isLoading1 } = useSelector(
    (store) => store.project
  );

  useEffect(() => {
    dispatch(getStatusChangeLogs({ projectId: id }));
  }, []);

  const rows =
    statusChangeLogs?.length > 0 &&
    statusChangeLogs?.map((statusChangeLog) => {
      return {
        status: statusChangeLog.status,
        statusReason: statusChangeLog.statusReason,
        createdAt: statusChangeLog.createdAt,
      };
    });

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      width: 50,
      align: "center",
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
      fixed: "left",
    },
    {
      title: "Updated date",
      dataIndex: "createdAt",
      width: 60,

      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status Change Reason",
      dataIndex: "statusReason",
      width: 200,
      align: "center",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={false}
      centered
      destroyOnClose
      width={700}
      title="Status Change Logs"
    >
      <Table
        dataSource={rows}
        columns={columns}
        loading={isLoading1}
        rowKey="key"
        scroll={{ y: 400 }}
        pagination={false}
      />
    </Modal>
  );
};

export default StatusChangeLogModal;
