import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { search_client } from "../../../Services/clientRequirementServices";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React from "react";
import { useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { getTechStacks } from "../../../Redux/Slices/Lists/ListsSlice";
import { addClientRequirement } from "../../../apis/ClientApi";
import {
  getClientRequirements,
  getSalesPoc,
} from "../../../Redux/Slices/ClientRequirement/ClientRequirementSlice";
import { toast } from "react-toastify";

import moment from "moment";
import { getAllManagers } from "../../../Redux/Slices/IC/ICSlice";

const CreateClient = ({ open, onClose }) => {
  const [resp, setResp] = useState();
  const [searchByClientName, setSearchByClientName] = useState([]);
  const { projectManagers } = useSelector((store) => store?.ICS);
  const { salesPoc } = useSelector((store) => store?.clientRequirement);

  const useStyles = makeStyles({
    container: {
      gap: "10px",
    },

    inputItem: {
      width: "100%",
    },
    paper: { minWidth: "500px" },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  let ts = {
    _id: "",

    name: "",

    createdAt: "",

    updatedAt: "",

    __v: 0,
  };
  const handleSubmit = (values) => {
    if (
      !values.clientName ||
      !values.status ||
      !values.requirementOpenDate ||
      !values.deadline
    ) {
      toast.error("Please enter all the required values.");
      return; // Do not proceed with submission
    }

    const techStackIds = values?.techStack?._id;
    const pocIds = values?.poc?._id;
    const salesPoc = values?.salesPoc?._id;
    let obj = {
      clientName: values?.clientName,
      status: values?.status,
      startDate: values?.startDate,
      techStack: techStackIds,
      count: values?.count,
      seniorityLevel: values?.seniorityLevel,
      remarks: values?.remarks,
      POC: pocIds,
      salesPoc: salesPoc,
      deadline: moment(values?.deadline).add(1, "d"),
      requirementOpenDate: moment(values?.requirementOpenDate).add(1, "d"),
      daysLeft: values?.daysLeft,
      hireDate: values?.hireDate,
      hiringDuration: values?.hiringDuration,
    };
    addClientRequirement(obj)
      .then((res) => {
        formik.handleReset();
        onClose();
        dispatch(getClientRequirements());
        toast.success("Client Added Successfully");
      })
      .catch((error) => toast.error(error?.response?.data?.message));
  };

  const formik = useFormik({
    initialValues: {
      clientName: "",
      status: "",
      techStack: ts,
      count: "",
      seniorityLevel: "",
      remarks: "",
      POC: "",
      deadline: "",
      requirementOpenDate: "",
      daysLeft: "",
      hireDate: "",
      hiringDuration: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const fetchDataClientName = async () => {
    const payload = {
      clientName: formik?.values?.clientName,
    };
    if (!payload.clientName) return;
    try {
      const response = await search_client(payload);
      const data = response;
      setResp(data.resp.clients);
      const clientNames = resp.map((item) => item.clientName);
      onanimationstart(clientNames);
    } catch (error) {
      // console.log("No data found");
    }
  };
  useEffect(() => {
    if (formik.values.deadline) {
      const deadline = moment(formik.values.deadline);
      const currentDate = moment();
      const daysLeft = deadline.diff(currentDate, "days");
      formik.setFieldValue("daysLeft", daysLeft);
    }
  }, [formik.values.deadline]);

  useEffect(() => {
    dispatch(getTechStacks());
    dispatch(getAllManagers());
    dispatch(getSalesPoc());
  }, []);

  useEffect(() => {
    const searchByClientNameInterval = setInterval(fetchDataClientName(), 100);
    clearInterval(searchByClientNameInterval);
  }, [formik?.values?.clientName]);
  const techStack = useSelector((store) => store?.lists?.techStacks);
  const status = ["OPEN", "HOT", "CLOSE", "COLD", "LOST"];
  const seniorityLevel = ["Intern", "Junior", "Mid Level", "Senior"];
  const handleClose = () => {
    formik.handleReset();
    onClose();
  };

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Dialog
            className={classes.paper}
            maxWidth={"md"}
            open={open}
            close={onClose}
            sx={{
              "& .MuiDialogActions-root": {
                padding: "0px !important",
              },
            }}
          >
            <DialogTitle>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  font: "Montserrat",
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "30px",
                  // borderBottom: "1px solid #F0F0F0",
                }}
              >
                Create Client Requirement
                <Button
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                </Button>
              </Grid>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid
                container
                sx={{
                  "& 	.MuiAutocomplete-popper": {
                    paddingBottom: "1rem",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#D32F2F",
                  },
                }}
                mt={1}
                spacing={2}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    options={resp ? resp : []}
                    disablePortal={true}
                    className={classes.inputItem}
                    freeSolo={true}
                    name="clientName"
                    getOptionLabel={(option) => {
                      if (option?.clientName) return option?.clientName;
                    }}
                    sx={{
                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        visibility: "hidden",
                      },
                    }}
                    onBlur={(event, value) => {
                      formik.setFieldValue("clientName", event?.target?.value);
                    }}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik?.values?.clientName}
                        options={searchByClientName ?? ""}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            "clientName",
                            event?.target?.value
                          );
                        }}
                        autoComplete="off"
                        name="clientName"
                        label="Client Name"
                        helperText={
                          formik.touched.clientName
                            ? formik.errors.clientName
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={status}
                    disablePortal={true}
                    className={classes.inputItem}
                    name="status"
                    getOptionLabel={(option) =>
                      option?.isDeleted ? "" : option
                    }
                    sx={{
                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        visibility: "hidden",
                      },
                    }}
                    value={formik.values.status}
                    onChange={(event, value) =>
                      formik.setFieldValue("status", value)
                    }
                    onBlur={formik.handleBlur}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik.values.status}
                        name="status"
                        label="Status"
                        helperText={
                          formik.touched.status ? formik.errors.status : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={classes.inputItem}
                    options={techStack || []}
                    disablePortal={true}
                    getOptionLabel={(option) => option?.name}
                    filterSelectedOptions
                    onChange={(event, value) =>
                      formik.setFieldValue("techStack", value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik?.values?.techStack}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        name="techStack"
                        label="Tech Stack"
                        helperText={
                          formik.touched.techStack
                            ? formik.errors.techStack
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    required
                    value={formik.values.count}
                    name="count"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Count"
                    helperText={formik.touched.count ? formik.errors.count : ""}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={seniorityLevel}
                    disablePortal={true}
                    className={classes.inputItem}
                    name="seniorityLevel"
                    getOptionLabel={(option) =>
                      option?.isDeleted ? "" : option
                    }
                    sx={{
                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        visibility: "hidden",
                      },
                    }}
                    value={formik.values.seniorityLevel}
                    onChange={(event, value) =>
                      formik.setFieldValue("seniorityLevel", value)
                    }
                    onBlur={formik.handleBlur}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik.values.seniorityLevel}
                        name="seniorityLevel"
                        label="Seniority Level"
                        helperText={
                          formik.touched.seniorityLevel
                            ? formik.errors.seniorityLevel
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    // required
                    value={formik.values.remarks}
                    name="remarks"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Remarks"
                    helperText={
                      formik.touched.remarks ? formik.errors.remarks : ""
                    }
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={classes.inputItem}
                    options={projectManagers || []}
                    name="poc"
                    disablePortal={true}
                    getOptionLabel={(option) =>
                      `${option.fullName} (${option.role})`
                    }
                    filterSelectedOptions
                    onChange={(event, value) =>
                      formik.setFieldValue("poc", value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik?.values?.poc?.fullName}
                    size="small"
                    renderInput={(params) => (
                      <>
                        <TextField
                          required
                          {...params}
                          name="poc"
                          label="POC"
                          value={formik?.values?.poc?.fullName}
                          helperText={
                            formik.touched.poc ? formik.errors.poc : ""
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={classes.inputItem}
                    options={salesPoc || []}
                    name="salesPoc"
                    disablePortal={true}
                    getOptionLabel={(option) =>
                      `${option.fullName} (${option.role})`
                    }
                    filterSelectedOptions
                    onChange={(event, value) =>
                      formik.setFieldValue("salesPoc", value)
                    }
                    onBlur={formik.handleBlur}
                    value={formik?.values?.salesPoc?.fullName}
                    size="small"
                    renderInput={(params) => (
                      <>
                        <TextField
                          required
                          {...params}
                          name="salesPoc"
                          label="SALES POC"
                          value={formik?.values?.salesPoc?.fullName}
                          helperText={
                            formik.touched.salesPoc
                              ? formik.errors.salesPoc
                              : ""
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      // required
                      sx={{ width: "100%" }}
                      inputFormat="MM/dd/yyyy"
                      slotProps={{ textField: { size: "small" } }}
                      label="Requirement Open Date *"
                      value={formik.values.requirementOpenDate || null}
                      onChange={(newValue) => {
                        formik.setFieldValue("requirementOpenDate", newValue);
                      }}
                      invalidDateMessage="Computer says no"
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                          onBlur={(e) => {
                            formik.setFieldTouched("startDate", true);
                          }}
                          helperText={
                            formik.touched.startDate
                              ? formik.errors.startDate
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      inputFormat="MM/dd/yyyy"
                      slotProps={{ textField: { size: "small" } }}
                      label="Deadline *"
                      required
                      value={formik.values.deadline || null}
                      onChange={(newValue) => {
                        formik.setFieldValue("deadline", newValue);
                      }}
                      minDate={formik.values.requirementOpenDate} // Set the minimum date to Requirement Open Date
                      invalidDateMessage="Invalid Date"
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          required
                          {...params}
                          onBlur={(e) => {
                            formik.setFieldTouched("deadline", true);
                          }}
                          helperText={
                            formik.touched.deadline
                              ? formik.errors.deadline
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.daysLeft}
                    name="daysLeft"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Days Left"
                    helperText={
                      formik.touched.daysLeft ? formik.errors.daysLeft : ""
                    }
                    autoComplete="off"
                    disabled // Add this line to make the field disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.hireDate}
                    name="hireDate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Hire Date"
                    helperText={
                      formik.touched.hireDate ? formik.errors.hireDate : ""
                    }
                    autoComplete="off"
                    // required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.hiringDuration}
                    name="hiringDuration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Hiring Duration"
                    helperText={
                      formik.touched.hiringDuration
                        ? formik.errors.hiringDuration
                        : ""
                    }
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                margin: "8px 20px",
                display: "flex",
                justifyContent: "center",
                gap: "2rem",
              }}
            >
              <Button
                onClick={onClose}
                // sx={{ color: "blue", width: "90%" }}
                size="medium"
                variant="contained"
                sx={{
                  color: "#4E79FA",
                  backgroundColor: "white",
                  width: "260px",
                  height: "46px",
                  borderRadius: "8px",
                  padding: "0px 36px",
                  transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                  "&:hover": {
                    backgroundColor: "#4E79FA",
                    color: "white",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={formik.handleSubmit}
                sx={{
                  width: "260px",
                  height: "46px",
                  borderRadius: "8px",
                  padding: "0px 36px",
                  transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                  backgroundColor: "#4E79FA",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#4E79FA",
                  },
                }}
                variant="contained"
              >
                Add client requirement
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </>
  );
};
export default CreateClient;
