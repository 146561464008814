import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { getTaskSheetbyuser, getTimeSheet } from "../../apis/TimesheetApi";
import { Button } from "antd";
import SliderModal from "./SliderModal";
import Todoinput from "./Todoinput";
import TodoList from "./TodoList";
import { Form } from "antd";
import { BASE_URL } from "../../constant";
import CustomDatePicker from "./CustomDatePicker ";
import api from "../../apiclient";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown, Menu, Modal, DatePicker } from "antd"; // Import DatePicker from antd
import {
  getOffboardedDevelopers,
  getOnboardedDevelopers,
} from "../../Redux/Slices/Project/ProjectsSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";

function TimesheetPM() {
  const location = useLocation();
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("en-US", { month: "long" })
  );
  const [developerid, setDeveloperid] = useState();
  const [taskSheet, setTaskSheet] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [listTodo, setListTodo] = useState([]);
  const [editTasksData, setEditTasksData] = useState();
  const [timeTaken, setTimeTaken] = useState("");
  const [projectIdFromUrl, setProjectIdFromUrl] = useState("");
  const [dynamicDate, setDynamicDate] = useState("2023-07-30");
  const [addTaskByPm, setAddTaskByPm] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("projectId");
  const { offboardedDevelopers, onboardedDevelopers } = useSelector(
    (store) => store.project
  );

  const monthStartDate = dayjs(new Date())
    .startOf("month")
    .format("YYYY-MM-DD");

  const monthCurrentDate = dayjs().format("YYYY-MM-DD");

  function getWeekStartAndNextSunday(selectedDate) {
    const selectedDay = new Date(selectedDate);

    const selectedDayOfWeek = selectedDay.getDay();

    const daysToMonday = selectedDayOfWeek === 0 ? 6 : selectedDayOfWeek - 1;

    const weekStartDate = new Date(
      selectedDay.getTime() - daysToMonday * 24 * 60 * 60 * 1000
    );

    const nextSunday = new Date(
      weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
    );

    const options = { year: "numeric", month: "numeric", day: "numeric" };

    const formattedWeekStartDate = weekStartDate.toLocaleDateString(
      "sv-SE",

      options
    );

    const formattedNextSunday = nextSunday.toLocaleDateString("sv-SE", options);

    return {
      weekStartDate: formattedWeekStartDate,

      nextSunday: formattedNextSunday,
    };
  }

  useEffect(() => {
    dispatch(getOnboardedDevelopers({ projectId }));
    dispatch(getOffboardedDevelopers({ projectId }));
  }, []);

  useEffect(() => {
    if (changeState) handleDateChange(selectedDay);
  }, [changeState]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const projectId = searchParams.get("projectId");

    setProjectIdFromUrl(projectId);
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const projectName = searchParams.get("projectName");

    const projectId = searchParams.get("projectId");

    setSelectedProjectName(projectName);

    setSelectedProjectId(projectId);
  }, [location.search]);

  if (onboardedDevelopers.length > 0 && !developerid) {
    setDeveloperid(onboardedDevelopers[0].userId);
  }

  useEffect(() => {
    if (onboardedDevelopers.length > 0) {
      handleDateChange(selectedDate);
    }
  }, [onboardedDevelopers]);

  const handleDateChange = async (date) => {
    const { weekStartDate: startDate, nextSunday: endDate } =
      getWeekStartAndNextSunday(date);

    const searchParams = new URLSearchParams(location.search);
    const projectId = searchParams.get("projectId");
    if (selectedProjectId) {
      let taskSheet = await getTaskSheetbyuser(
        startDate,

        endDate,

        selectedProjectId,

        developerid
      );

      setEditTasksData([]);

      setTaskSheet(taskSheet);
    } else {
      let taskSheet = await getTaskSheetbyuser(
        startDate,

        endDate,

        selectedProjectId ? selectedProjectId : projectId,

        developerid
      );

      setTaskSheet(taskSheet);
    }

    setDynamicDate(
      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    );

    setListTodo([]);

    setSelectedDay(date);

    setSelectedDate(startDate);

    setSelectedMonth(date.toLocaleString("en-US", { month: "long" }));

    setChangeState(false);
  };

  const handleSelectChange = async (event) => {
    setDeveloperid(event.target.value);
    try {
      const { weekStartDate: startDate, nextSunday: endDate } =
        getWeekStartAndNextSunday(selectedDate);

      const searchParams = new URLSearchParams(location.search);
      const projectId = searchParams.get("projectId");

      let taskSheetData = await getTaskSheetbyuser(
        startDate,
        endDate,
        projectId,
        developerid
      );

      setTaskSheet(taskSheetData);
      setChangeState(true);
    } catch (error) {
      // console.error("Error updating taskSheet:", error);
    }
  };

  // my code
  const [isFilled, setIsFilled] = useState(false);

  const editTaskBtn = (task, date) => {
    setIsFilled(task?.task.length ? true : false);

    if (task === undefined) {
      setAddTaskByPm(true);
    }

    let newDate = new Date(date);

    const taskDataEdit = {
      tasks: task?.task,

      projectData: task?.projectId,

      userId: task?.userId,

      id: task?._id,

      date: newDate.toISOString(),
    };

    setEditTasksData(taskDataEdit);

    setOpenSidebar(true);

    setListTodo(task?.task);
  };

  let addList = (inputText, inputTime) => {
    let taskObject = {
      name: inputText,

      time: inputTime,
    };

    if (listTodo?.length > 0) setListTodo([...listTodo, taskObject]);
    else setListTodo([taskObject]);
  };

  const disabledDate = (current) => {
    return current && current.isAfter(new Date(), "day");
  };

  const onTimeFieldChange = (e) => {
    setTimeTaken(e.target.value);
  };

  const deleteListItem = (key) => {
    setListTodo((prev) => prev.filter((item) => item.name !== key));
  };

  const exportToPdf = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `${BASE_URL}/api/v2/timesheet/exportindividual?projectId=${projectIdFromUrl}&date=${dynamicDate}&userId=${developerid}`
      );

      window.open(res?.data?.data, "_blank");
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSidebarClose = async () => {
    setOpenSidebar(false);
  };

  const renderPreviousDays = () => {
    const weekDays = [];

    const startOfWeek = new Date(selectedDate);

    const selectedDayOfWeek = startOfWeek.getDay();

    startOfWeek.setDate(startOfWeek.getDate() - selectedDayOfWeek + 1);

    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);

      currentDate.setDate(startOfWeek.getDate() + i);

      const day = currentDate.toLocaleString("en-US", { weekday: "long" });

      const dayNumber = currentDate.getDate();

      const month = currentDate.toLocaleString("en-US", { month: "long" });

      const tasksForDay = taskSheet.data.find(
        (task) =>
          new Date(task.date).toLocaleDateString() ===
          currentDate.toLocaleDateString()
      );

      // Check if the day is Saturday or Sunday
      const isWeekend =
        currentDate.getDay() === 0 || currentDate.getDay() === 6;

      weekDays.push(
        <div key={currentDate.toString()}>
          <div
            style={{ borderBottom: "1px solid #ccc" }}
            className="border-b flex pt-2 pb-2 pl-2"
          >
            <div className="w-[10%]">
              <h3>{day}</h3>

              <span className="text-gray-500 text-lg">
                ({dayNumber} {month})
              </span>
            </div>
            <div className="w-[90%] flex justify-between">
              <div key={tasksForDay?.id} className="flex w-[90%]">
                <div>
                  {tasksForDay && tasksForDay.task.length > 0 ? (
                    tasksForDay.task.map((item) => {
                      return (
                        <div className="pl-16" key={item.name}>
                          <p className="text-black">
                            {item.name}{" "}
                            {item?.name == "On Leave" ? (
                              ""
                            ) : (
                              <span className="text-gray-400">
                                - {item.time}{" "}
                                <span className="text-gray-400">
                                  {item.time > 1 ? "hrs" : "hr"}
                                </span>
                              </span>
                            )}
                          </p>
                        </div>
                      );
                    })
                  ) : isWeekend ? (
                    <div className="pl-16">
                      <p className="">
                        <span className="text-green-500 uppercase">
                          Week-Off{" "}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <div>
                      <div className="pl-16  ">
                        <p className="">
                          <span className="text-red-500 uppercase">
                            missing entry
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Button
                className="bg-black text-white mt-4 mr-3"
                style={{
                  fontWeight: "500",
                }}
                onClick={() => editTaskBtn(tasksForDay, currentDate)}
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return weekDays;
  };

  const [openDateRangeModal, setOpenDateRangeModal] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const downloadDateRangeTimesheet = async () => {
    if (dateRange.startDate && dateRange.endDate) {
      try {
        setIsLoading(true);

        // Format the dates to 'YYYY-MM-DD' format
        const formattedStartDate = dateRange.startDate
          .toISOString()
          .split("T")[0];
        const formattedEndDate = dateRange.endDate.toISOString().split("T")[0];

        const response = await api.get(
          `${BASE_URL}/api/v2/timesheet/exportindividual?projectId=${projectIdFromUrl}&startingDate=${formattedStartDate}&endingDate=${formattedEndDate}&userId=${developerid}`
        );

        if (response.data.data) {
          window.open(response.data.data, "_blank");
        } else {
          // Handle the case where there's no data
        }
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsLoading(false);
        setOpenDateRangeModal(false);
      }
    } else {
      alert("Please select a start and end date.");
    }
  };
  const disabledDates = (currentDate) => {
    return (
      dateRange.startDate && currentDate < dateRange.startDate.startOf("day")
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key="fullMonth" onClick={exportToPdf}>
        Download Full Month Timesheet
      </Menu.Item>
      <Menu.Item key="dateRange" onClick={() => setOpenDateRangeModal(true)}>
        Download by Date Range
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div className="w-[100%] bg-black h-20  flex  justify-start rounded-md">
        <div className="pt-5 ml-4">
          <Link to="/dashboard/timesheet">
            <ArrowLeftOutlined className="text-white  text-2xl" />
          </Link>
        </div>
        <div className=" ml-7 pt-6">
          <h1 className="text-white text-xl ">TimeSheet Entry</h1>
        </div>
      </div>
      <div className="bg-gray-100 w-full">
        <div className="flex justify-between items-center py-3 px-4 my-3 border-b bg-white">
          <div>
            <h1 className="text-xl text-slate-600">
              {selectedProjectName.toUpperCase()}
            </h1>

            <p className="text-slate-400">See and Edit the missing entry</p>
          </div>
          {/* Onboard Developer Dropdown */}
          <div>
            <select
              className="w-full h-10 px-3 border border-black rounded-md bg-black text-white focus:outline-none focus:border-white"
              onChange={(e) => handleSelectChange(e)}
              value={developerid}
            >
              {onboardedDevelopers?.map((onboardedDeveloper) => (
                <option
                  key={onboardedDeveloper._id}
                  value={onboardedDeveloper.userId}
                >
                  {onboardedDeveloper.developerName.toUpperCase()}
                </option>
              ))}
              {offboardedDevelopers?.map((offboardedDeveloper) => (
                <option
                  key={offboardedDeveloper._id}
                  value={offboardedDeveloper.userId}
                  className="bg-red-800"
                >
                  {offboardedDeveloper.developerName.toUpperCase()}
                </option>
              ))}
            </select>
          </div>
          <div>
            {!isLoading ? (
              <Dropdown overlay={menu} trigger={["click"]}>
                <button className="ml-4 bg-black text-white h-8 pl-2 pr-2 rounded-md">
                  Download Timesheet
                </button>
              </Dropdown>
            ) : (
              <Button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "600",
                }}
              >
                LOADING...!!!
              </Button>
            )}

            <Modal
              title="Select Date Range"
              open={openDateRangeModal}
              onOk={downloadDateRangeTimesheet}
              onCancel={() => setOpenDateRangeModal(false)}
              okButtonProps={{
                className: "bg-blue-500 text-white",
              }}
              cancelButtonProps={{ className: "bg-blue-500 text-white" }}
            >
              <div>
                <DatePicker
                  selected={dateRange.startDate}
                  onChange={(date) =>
                    setDateRange({ ...dateRange, startDate: date })
                  }
                  placeholderText="Start Date"
                  placeholder="Select start date"
                />
                <DatePicker
                  selected={dateRange.endDate}
                  onChange={(date) =>
                    setDateRange({ ...dateRange, endDate: date })
                  }
                  placeholderText="End Date"
                  disabledDate={disabledDates}
                  className="ml-2"
                  placeholder="Select end date"
                />
              </div>
            </Modal>
          </div>
        </div>

        <div className="flex justify-between bg-gray-100">
          <div
            className="h-[70%] w-full bg-white mb-4"
            style={{ borderRadius: "0.5rem" }}
          >
            <div
              className=" h-[13%] w-[100%] flex justify-between bg-red-200 "
              style={{
                backgroundColor: "#5555",

                borderRadius: "0.5rem 0.5rem 0 0",
              }}
            >
              <div className="flex justify-start">
                <div className="pt-1">
                  <Link to="/dashboard/timesheet">
                    <ArrowLeftOutlined className="text-black pt-4 pl-4 text-2xl" />
                  </Link>
                </div>

                <div className="ml-4 pt-5 pb-6 text-sm font-normal">
                  <h1>{selectedMonth}</h1>
                </div>
              </div>

              <div>
                <CustomDatePicker
                  selectedDay={selectedDay}
                  handleDateChange={handleDateChange}
                  userId={developerid}
                  projectId={projectId}
                  startDate={monthStartDate}
                  endDate={monthCurrentDate}
                  changeState={changeState}
                />

                {/* Other content of your component */}
              </div>
            </div>

            {taskSheet ? (
              renderPreviousDays()
            ) : (
              <p className="text-center">No Data</p>
            )}
          </div>
        </div>
      </div>

      {openSidebar && (
        <SliderModal
          // timesheetId={timesheetId}
          isFilled={isFilled}
          setIsFilled={setIsFilled}
          openSidebar={openSidebar}
          editTasksData={editTasksData}
          selectedProjectName={selectedProjectName}
          handleSidebarClose={handleSidebarClose}
          disabledDate={disabledDate}
          addList={addList}
          listTodo={listTodo}
          saveTimeForTask="ferjge"
          onTimeFieldChange={onTimeFieldChange}
          form={form}
          Todoinput={Todoinput}
          TodoList={TodoList}
          deleteListItem={deleteListItem}
          open={open}
          setOpen={setOpen}
          addTaskByPm={addTaskByPm}
          setAddTaskByPm={setAddTaskByPm}
          developerId={developerid}
          setChangeState={setChangeState}
          isTimesheetPm={true}
          editMode={true}
          taskSheet={taskSheet}
          setTaskSheet={setTaskSheet}
        />
      )}
    </div>
  );
}

export default TimesheetPM;
