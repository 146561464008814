import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";

import { Typography } from "@mui/material";

import { VisibilityOff, Visibility } from "@mui/icons-material";

import CloseIcon from "@mui/icons-material/Close";

import { makeStyles } from "@mui/styles";

import MenuItem from "@mui/material/MenuItem";

import "../../Assets/CSS/RegDev/RegDev.css";

import RegDevSchema from "./RegDevSchema";
import RegDevSchemaIC from "./DevSchemaIC";

import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { useDispatch, useSelector } from "react-redux";

import {
  getTechStacks,
  getDesignations,
  getAllProjectManagers,
} from "../../Redux/Slices/Lists/ListsSlice";

import {
  AddIcUser,
  reg_dev_vld,
  resetStatus,
} from "../../Redux/Slices/Auth/RegDevSlice";

import { get_users } from "../../Redux/Slices/getUsersSlice";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { useState, useEffect } from "react";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { getCitiesName } from "../../Redux/Slices/Dashboard/DashboardSlice";

const RegisterDeveloper = ({ open, onClose }) => {
  const data = localStorage.getItem("openRegisterModal");
  let schemaValidation =
    data == "true" || data == "openRegisterModal"
      ? RegDevSchemaIC
      : RegDevSchema;
  const [showPassword, setShowPassword] = useState(false);

  const isfromCreateIc = localStorage.getItem("openRegisterModal");
  const [isClientSide, setIsClientSide] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const useStyles = makeStyles({
    container: {
      gap: "10px",
    },

    inputItem: {
      width: "100%",
    },

    paper: { minWidth: "500px" },
  });

  const classes = useStyles();

  const handleClose = () => {
    localStorage.removeItem("openRegisterModal");

    reset();

    onClose();
  };

  const { status, msg, isLoading } = useSelector((store) => store.regDev);

  const { techStacks, designations, projectManagers } = useSelector(
    (store) => store.lists
  );

  const { citiesList } = useSelector((store) => store.dashboard);

  const [isFresher, setIsFresher] = useState("true");

  const [email, setEmail] = useState("");

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });

  useEffect(() => {
    dispatch(getTechStacks());
    dispatch(getDesignations());
    dispatch(getAllProjectManagers({ role: "RM" }));
    dispatch(getCitiesName());
  }, []);

  useEffect(() => {
    if (status === 201) {
      setTimeout(() => {
        dispatch(get_users("api/v2/users/search?isDeleted=false"));

        dispatch(resetStatus());

        onClose();
      }, 1000);
    }

    if (status === false) {
      toast.error(msg);
    }
  }, [status]);

  const onSubmit = (data) => {
    if (
      data.workingExperienceInMonths < 0 ||
      data.workingExperienceInYears < 0
    ) {
      toast.error("Work experience cannot be negative");
      return;
    }
    data.workingExperienceInMonths =
      data.workingExperienceInYears * 12 + data.workingExperienceInMonths;
    delete data.workingExperienceInYears;
    if (isfromCreateIc === "openRegisterModal") {
      dispatch(AddIcUser(data));
    } else {
      dispatch(reg_dev_vld(data));
    }

    if (status === 200) {
      localStorage.removeItem("openRegisterModal");
      setTimeout(() => {
        dispatch(get_users("api/v2/users/search?isDeleted=false"));
        dispatch(resetStatus());
        handleClose();
      }, 1000);
    }
  };

  const handleChange = (event) => {
    setIsFresher(event.target.value);
  };

  return (
    <>
      <Box>
        <form>
          <Dialog
            className={classes.paper}
            maxWidth={"md"}
            open={open}
            close={onClose}
            sx={{
              "& .MuiDialogActions-root": {
                padding: "0px !important",
              },
            }}
          >
            <DialogTitle>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",

                  alignItems: "center",

                  fontFamily: "Montserrat",

                  fontSize: "24px",

                  lineHeight: "30px",

                  borderBottom: "1px solid #F0F0F0",
                }}
              >
                Add Developer
                <Button
                  sx={{
                    color: "black",

                    fontSize: "1rem",
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                </Button>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <Grid
                container
                component="form"
                noValidate="on"
                autoComplete="off"
                spacing={2}
                sx={{
                  marginTop: "4px",

                  "& .MuiTextField-root": {
                    width: "100%",
                  },

                  "& .MuiFormHelperText-root": {
                    color: "#D32F2F",
                  },
                }}
              >
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Full Name <span style={{ color: "red" }}> *</span>
                  </Typography>

                  <TextField
                    required
                    id="outlined-required"
                    name="Enter Developer fullName"
                    type={"text"}
                    placeholder="FullName"
                    size="small"
                    {...register("fullName")}
                    helperText={errors.fullName?.message}
                  />
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      Date of joining<span style={{ color: "red" }}> *</span>
                    </Typography>

                    <Controller
                      name="joiningDate"
                      control={control}
                      defaultValue={null}
                      render={({ field, ...props }) => (
                        <DesktopDatePicker
                          inputFormat="MM/dd/yyyy"
                          onChange={(date) => field.onChange(date)}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              required
                              className="bg-black"
                              fullWidth
                              {...params}
                              size="small"
                              sx={{ fontSize: "small" }}
                              // sx={{ paddingTop: "50px" }}

                              helperText={errors.joiningDate?.message}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.joiningDate && (
                      <div
                        style={{
                          color: "#E06B6B",
                          fontSize: "12px",
                          lineHeight: "16px",
                        }}
                      >
                        Date of Joining is Required
                        {/* {errors.joiningDate?.message} */}
                      </div>
                    )}
                  </Grid>
                </LocalizationProvider>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Email
                    <span style={{ color: "red" }}>
                      {" "}
                      {isfromCreateIc === "openRegisterModal" ? "" : "*"}
                    </span>
                  </Typography>

                  <TextField
                    id="outlined-required"
                    // label="Email"

                    size="small"
                    name="email"
                    placeholder="Enter Email"
                    autoComplete="new-password"
                    type={"email"}
                    style={{ width: "100%" }}
                    onChange={(e) => setEmail(e.target.value)}
                    {...register("email")}
                    helperText={errors.email?.message}
                  />
                </Grid>

                {isfromCreateIc != "openRegisterModal" && (
                  <>
                    {" "}
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        Password
                        <span style={{ color: "red" }}>
                          {" "}
                          {isfromCreateIc === "openRegisterModal" ? "" : "*"}
                        </span>
                      </Typography>

                      <TextField
                        required={
                          isfromCreateIc === "openRegisterModal" ? false : true
                        }
                        id="outlined-required"
                        // label="Password"

                        size="small"
                        // type={"password"}

                        name="password"
                        autoComplete="new-password"
                        placeholder="Enter Password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...register("password")}
                        helperText={errors.password?.message}
                        sx={{ width: "100% " }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Phone Number<span style={{ color: "red" }}> *</span>
                  </Typography>

                  <TextField
                    required
                    id="outlined-required"
                    size="small"
                    type={"tel"}
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    {...register("phoneNumber")}
                    inputProps={{ maxLength: 10 }}
                    helperText={errors.phoneNumber?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reporting Manager
                  </Typography>
                  <TextField
                    id="outlined-required"
                    size="small"
                    type={"text"}
                    placeholder="Select Reporting Manager"
                    select // Make the field a select field
                    name="reportingManager"
                    {...register("reportingManager")}
                    helperText={errors.reportingManager?.message}
                    onChange={(e) =>
                      setValue("reportingManager", e.target.value)
                    }
                  >
                    {projectManagers.map((manager) => (
                      <MenuItem key={manager._id} value={manager._id}>
                        {manager.fullName} ({manager.role})
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Emergency Contact Number
                  </Typography>

                  <TextField
                    id="outlined-required"
                    size="small"
                    type={"tel"}
                    placeholder="Enter Phone Number"
                    inputProps={{ maxLength: 10 }}
                    name="emergencyContactNumber"
                    {...register("emergencyContactNumber")}
                    helperText={errors.emergencyContactNumber?.message}
                    sx={{ width: "100% " }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Employee ID
                    <span style={{ color: "red" }}>
                      {" "}
                      {isfromCreateIc === "openRegisterModal" ? "" : "*"}
                    </span>
                  </Typography>
                  <TextField
                    required={
                      isfromCreateIc === "openRegisterModal" ? false : true
                    }
                    id="outlined-required"
                    name="empId"
                    type={"text"}
                    size="small"
                    placeholder="Employee ID"
                    {...register("empId")}
                    // error={errors.empId ? true : false}

                    helperText={errors.empId?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Base Location
                    <span style={{ color: "red" }}>
                      {" "}
                      {isfromCreateIc === "openRegisterModal" ? "" : "*"}
                    </span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    required={
                      isfromCreateIc === "openRegisterModal" ? false : true
                    }
                    type={"text"}
                    placeholder="Select Location"
                    onChange={(e) => setValue("location", e.target.value)}
                    name="location"
                    size="small"
                    error={errors.isAvailable ? true : false}
                    helperText={errors.location?.message}
                  >
                    <MenuItem key="Bangalore" value="Bangalore">
                      Bangalore
                    </MenuItem>

                    <MenuItem key="Gurgaon" value="Gurgaon">
                      Gurgaon
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Work Location
                    <span style={{ color: "red" }}>
                      {" "}
                      {isfromCreateIc === "openRegisterModal" ? "" : "*"}
                    </span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    required={
                      isfromCreateIc === "openRegisterModal" ? false : true
                    }
                    type={"text"}
                    placeholder="Select Work Location"
                    onChange={(e) => {
                      setValue("workLocation", e.target.value);
                      const selectedWorkLocation = e.target.value;
                      if (selectedWorkLocation === "CS") {
                        setIsClientSide(true);
                      } else {
                        setIsClientSide(false);
                        setValue("workLocationCity", "NA");
                      }
                    }}
                    name="workLocation"
                    size="small"
                    error={errors.workLocation ? true : false}
                    helperText={errors.workLocation?.message}
                  >
                    <MenuItem key="WFH" value="WFH">
                      Work From Home
                    </MenuItem>

                    <MenuItem key="Hybrid" value="Hybrid">
                      Hybrid
                    </MenuItem>
                    <MenuItem key="CS" value="CS">
                      Client Side
                    </MenuItem>
                    <MenuItem key="In-Office" value="In-Office">
                      In Office
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Client Side City
                    <span style={{ color: "red" }}>
                      {" "}
                      {isfromCreateIc === "openRegisterModal"
                        ? ""
                        : isClientSide
                        ? "*"
                        : ""}
                    </span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    required={
                      isfromCreateIc === "openRegisterModal" ? false : true
                    }
                    type={"text"}
                    placeholder="Select Work Location City"
                    onChange={(e) =>
                      setValue("workLocationCity", e.target.value)
                    }
                    name="workLocationCity"
                    size="small"
                    error={errors.workLocationCity ? true : false}
                    helperText={errors.workLocationCity?.message}
                    disabled={!isClientSide}
                    className={!isClientSide ? "bg-gray-200" : "bg-white"}
                  >
                    {citiesList?.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Exp(Years) prior to joining antino{" "}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>

                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Enter Value"
                    size="small"
                    type={"number"}
                    name="workingExperienceInYears"
                    {...register("workingExperienceInYears")}
                    helperText={errors.workingExperienceInYears?.message}
                    inputProps={{ min: 0 }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Exp(Months) prior to joining antino{" "}
                    <span style={{ color: "red" }}> *</span>
                  </Typography>

                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Enter Value"
                    size="small"
                    type={"number"}
                    name="workingExperienceInMonths"
                    {...register("workingExperienceInMonths")}
                    helperText={errors.workingExperienceInMonths?.message}
                    inputProps={{ min: 0 }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Seniority <span style={{ color: "red" }}> *</span>
                  </Typography>

                  <TextField
                    id="outlined-required"
                    select
                    type={"text"}
                    // defaultValue={userData.seniority?._id}

                    required
                    onChange={(e) => setValue("seniority", e.target.value)}
                    name="seniority"
                    size="small"
                    // {...register("seniority")}
                    helperText={errors.seniority?.message}
                    error={errors.isAvailable ? true : false}
                  >
                    <MenuItem key="Junior" value="Junior">
                      Junior
                    </MenuItem>

                    <MenuItem key="MidLevel" value="Mid Level">
                      Mid Level
                    </MenuItem>

                    <MenuItem key="Intern" value="Intern">
                      Intern
                    </MenuItem>

                    <MenuItem key="Senior" value="Senior">
                      Senior
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Designation <span style={{ color: "red" }}> *</span>
                  </Typography>

                  <TextField
                    required
                    id="outlined-required"
                    select
                    size="small"
                    placeholder="Select Designation"
                    onChange={handleChange}
                    type={"text"}
                    name="designation"
                    {...register("designation")}
                    helperText={errors.designation?.message}
                  >
                    {designations.map((designation) => (
                      <MenuItem key={designation.name} value={designation._id}>
                        {designation.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Tech Stack
                  </Typography>
                  <Autocomplete
                    multiple
                    id="primary-tech-stack"
                    options={techStacks || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      const selectedIds = newValue.map((option) => option._id);
                      setValue("primaryTechStack", selectedIds);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        placeholder="Select Tech Stack"
                        // error={errors.primaryTechStack ? true : false}
                        // helperText={errors.primaryTechStack?.message}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Profile Link
                  </Typography>

                  <TextField
                    id="outlined-required"
                    size="small"
                    type="text"
                    placeholder="Enter Profile Link"
                    name="profileLink"
                    {...register("profileLink")}
                    helperText={errors.profileLink?.message}
                  />
                </Grid>
              </Grid>

              <Typography variant="subtitle1" gutterBottom>
                Remarks
              </Typography>

              <textarea className="border border-slate-300 w-[100%] rounded-md"></textarea>
            </DialogContent>

            <DialogActions className=" h-20">
              <Box className="mr-32 mb-2 ">
                <Button
                  onClick={handleClose}
                  sx={{
                    color: "#4E79FA",
                    backgroundColor: "white",
                    width: "348.5px",
                    height: "46px",
                    borderRadius: "8px",
                    padding: "0px 36px",
                    transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect
                    "&:hover": {
                      backgroundColor: "#4E79FA",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Box>
              <Box className="mr-10 mb-2">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    color: "#4E79FA",

                    backgroundColor: "white",

                    width: "348.5px",

                    height: "46px",

                    borderRadius: "8px",

                    padding: "0px 36px",

                    // gap: "5px",

                    transition: "background-color 0.3s, color 0.3s", // Add transition for smooth effect

                    "&:hover": {
                      backgroundColor: "#4E79FA",

                      color: "white",
                    },
                  }}
                  variant="contained"
                  disabled={isLoading}
                >
                  Add Developer
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </>
  );
};

export default RegisterDeveloper;
