import moment from "moment";
import React, { useEffect } from "react";
import { InputAdornment, TextField, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FilterOutlined } from "@ant-design/icons";
import FilterDialogUtilisation from "./FilterDialogUtilisation";
import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { DatePicker, Table } from "antd";
import {
  setFilterParameters,
  getonofpoc,
  getallprojects,
  getonofstatus,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { get_users } from "../../../Redux/Slices/getUsersSlice";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import dayjs from "dayjs";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const Reports = ({
  handleDateChange,
  rows,
  columns,
  handleSearch,
  title,
  picker,
  handleDownload,
  searchPlaceholder,
  showProjectStatusFilters,
  showOnboardHistoryFilter,
  showDepartedDeveloperFilter,
  showInactiveDevelopersFilter,
  showUtilisationFilter,
  setName,
  name,
}) => {
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const { isLoading, groups, POC, searchFilters, Status, projectName } =
    useSelector((store) => store.lists);
  const { users } = useSelector((store) => store?.getUsers);
  const [filterParams, setFilterParams] = useState({ ...searchFilters });
  const dispatch = useDispatch();

  const disabledFutureDate = (current) => {
    // Get the current date
    const today = moment();

    // Disable dates greater than today
    return current && current > today;
  };

  useEffect(() => {
    dispatch(getGroups());
    dispatch(getonofpoc());
    dispatch(get_users("/api/v2/users/search?isDeleted=false"));
    dispatch(getallprojects());
  }, []);

  return (
    <div
      style={{
        height: "fitContent",
        backgroundColor: "white",
        padding: "10px 32px 24px 32px",
        borderRadius: "16px",
        maxHeight: "85vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "2rem",
        }}
      >
        <h1
          style={{
            font: "Montserrat",
            fontSize: "32px",
            fontWeight: "500",
          }}
        >
          {title}
        </h1>
        {!showOnboardHistoryFilter &&
          !showDepartedDeveloperFilter &&
          !showInactiveDevelopersFilter && (
            <DatePicker
              onChange={handleDateChange}
              disabledDate={disabledFutureDate}
              picker={picker}
              allowClear={false}
              defaultValue={dayjs(
                dayjs(new Date()).format("YYYY-MM-DD"),
                "YYYY-MM-DD"
              )}
            />
          )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 0px",
          marginRight: "2rem",
        }}
      >
        <div
          style={{
            display: showDepartedDeveloperFilter ? "none" : "flex",
            gap: "1rem",
          }}
        >
          <TextField
            size="small"
            placeholder={searchPlaceholder}
            onChange={(e) => {
              handleSearch(e.target.value);
              setName(e.target.value);
            }}
            value={name}
            autoComplete={"off"}
            sx={{
              width: "15rem",
              borderRadius: "8px",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Button
            variant="outlined"
            color="primary"
            endIcon={<FilterOutlined />}
            onClick={() => setOpenFilterDialog(true)}
            sx={{
              borderRadius: "8px",
              padding: "8px 10px",
              width: "109px",
            }}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              dispatch(setFilterParameters({}));
              setFilterParams({});
              handleSearch("");
              setName("");
            }}
            sx={{
              borderRadius: "8px",
              padding: "8px 10px",
              width: "109px",
            }}
          >
            Reset
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            sx={{
              borderRadius: "8px",
              padding: "8px 10px",
              backgroundColor: "#4E79FA",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#FFFFFF",
                color: "#4E79FA",
                border: "1px solid #4E79FA",
              },
            }}
            onClick={handleDownload}
            disabled={isLoading}
            endIcon={<FileDownloadIcon />}
          >
            Download
          </Button>
        </div>
      </div>
      <div style={{ width: "75vw" }}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table
            dataSource={rows}
            columns={columns}
            pagination={{ showQuickJumper: true, showSizeChanger: true }}
            rowKey="key"
            scroll={{ x: 1000, y: 350 }}
          />
        )}
      </div>
      <FilterDialogUtilisation
        open={openFilterDialog} // Pass the state to the dialog
        onClose={() => {
          setOpenFilterDialog(false);
        }}
        groups={groups}
        POC={POC}
        Status={Status}
        projectName={projectName}
        users={users}
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        showProjectStatusFilters={showProjectStatusFilters}
        showOnboardHistoryFilter={showOnboardHistoryFilter}
        showInactiveDevelopersFilter={showInactiveDevelopersFilter}
        showUtilisationFilter={showUtilisationFilter}
      />
    </div>
  );
};

export default Reports;
