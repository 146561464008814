import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete
} from "@mui/material";
import { Typography } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import "../../Assets/CSS/RegDev/RegDev.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { get_users } from "../../Redux/Slices/getUsersSlice";
import { resetStatus, update_user } from "../../Redux/Slices/Auth/RegDevSlice";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { getAllProjectManagers } from "../../Redux/Slices/Lists/ListsSlice";
import { getCitiesName } from "../../Redux/Slices/Dashboard/DashboardSlice";

const EditDevModal = ({ open, onClose, setOpenActionModal }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [availability, setAvailability] = useState("");
  const { status, msg, isLoading, userData } = useSelector(
    (store) => store.regDev
  );
  const [isClientSide, setIsClientSide] = useState(false);
  const { citiesList } = useSelector((store) => store.dashboard);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const useStyles = makeStyles({
    container: {
      gap: "10px",
    },
    inputItem: {
      width: "100%",
    },
    paper: { minWidth: "500px" },
  });

  const classes = useStyles();

  const [isFresher, setIsFresher] = useState("true");
  const [primaryTechStack, setPrimaryTechStack] = useState([]);

  const dispatch = useDispatch();

  const { techStacks, designations, projectManagers } = useSelector(
    (store) => store.lists
  );

  useEffect(() => {
    if (status === 200) {
      setTimeout(() => {
        dispatch(get_users("api/v2/users/search?isDeleted=false"));
        dispatch(resetStatus());
        onClose();
      }, 1000);
    }
    if (status === false) {
      toast.error(msg);
    }
    dispatch(getAllProjectManagers({ role: "RM" }));
  }, [status]);

  useEffect(() => {
    dispatch(getCitiesName());
    if (userData?.workLocation === "CS") {
      setIsClientSide(true);
    }
    setPrimaryTechStack(userData?.primaryTechStack);
  }, [userData]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { seniority: userData.seniority || "" },
  });

  const onSubmit = (data) => {
    dispatch(resetStatus());

    if (
      data.workingExperienceInMonths < 0 ||
      data.workingExperienceInYears < 0
    ) {
      toast.error("Work experience cannot be negative");
      return;
    }
    data.workingExperienceInMonths =
      data.workingExperienceInYears * 12 +
      Number(data.workingExperienceInMonths);
    delete data.workingExperienceInYears;

    data.workLocation = data.workLocation || userData.workLocation;
    data.primaryTechStack = primaryTechStack.map((techStack) => techStack._id);

    dispatch(update_user({ id: userData._id, data }));

    setOpenActionModal(false);
  };

  const handleTechStackChange = (event, value) => {
    setPrimaryTechStack(value);
  };
  const handleChange = (event) => {
    setIsFresher(event.target.value);
    const selectedAvailability = event.target.value;
    setAvailability(selectedAvailability);

    // Show the datepicker only if the selected value is "Available" or "May be available"
    setDatePickerVisible(
      selectedAvailability === "Available" ||
        selectedAvailability === "mayBeAvailable"
    );
  };

  return (
    <>
      <Box>
        <form>
          <Dialog
            className={classes.paper}
            maxWidth={"md"}
            open={open}
            close={onClose}
            sx={{
              "& .MuiDialogActions-root": {
                padding: "0px !important",
              },
            }}
          >
            <DialogTitle>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: "Montserrat",
                  fontSize: "24px",
                  lineHeight: "30px",
                  borderBottom: "1px solid #F0F0F0",
                }}
              >
                Edit Developer
                <Button
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                  }}
                  onClick={onClose}
                >
                  <CloseIcon sx={{ cursor: "pointer" }} />
                </Button>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                component="form"
                noValidate
                autoComplete="off"
                spacing={2}
                sx={{
                  marginTop: "4px",
                  "& .MuiTextField-root": {
                    width: "100%",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#D32F2F",
                  },
                }}
              >
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Full Name<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    name="fullName"
                    defaultValue={userData.fullName}
                    type={"text"}
                    size="small"
                    {...register("fullName")}
                    error={errors.fullName ? true : false}
                    helpertext={errors.fullName?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Date of joining<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    size="small"
                    defaultValue={moment(userData.joiningDate).format(
                      "YYYY-MM-DD"
                    )}
                    type={"date"}
                    name="joiningDate"
                    {...register("joiningDate")}
                    error={errors.joiningDate ? true : false}
                    helpertext={errors.joiningDate?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Email<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    required
                    size="small"
                    name="email"
                    defaultValue={userData.email}
                    type={"email"}
                    style={{ width: "100%" }}
                    {...register("email")}
                    error={errors.email ? true : false}
                    helpertext={errors.email?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Password<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    // label="Password"
                    size="small"
                    // type={"password"}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...register("password")}
                    helperText={errors.password?.message}
                    sx={{ width: "100% " }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Available
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    type={"text"}
                    defaultValue={userData.isAvailable}
                    onChange={handleChange}
                    name="isAvailable"
                    size="small"
                    {...register("isAvailable")}
                    error={errors.isAvailable ? true : false}
                  >
                     <MenuItem key="Available" value="Available">
                      Available
                    </MenuItem>
                    <MenuItem key="mayBeAvailable" value="mayBeAvailable">
                      May be Available
                    </MenuItem>
                    <MenuItem key="Enagaged" value="Enagaged">
                      Engaged
                    </MenuItem>
                  
                   
                    <MenuItem key="notAvailable" value="notAvailable">
                      Not Available
                    </MenuItem>
                    <MenuItem key="yetToBeHired" value="yetToBeHired">
                      Yet to be Hired
                    </MenuItem>
                    <MenuItem
                      key="offerLetterReleased"
                      value="offerLetterReleased"
                    >
                      Offer Letter Released
                    </MenuItem>
                    <MenuItem
                      key="offerLetterAccepted"
                      value="offerLetterAccepted"
                    >
                      Offer Letter Accepted
                    </MenuItem>
                    <MenuItem key="underTraining" value="underTraining">
                      Under Training
                    </MenuItem>

                    <MenuItem key="backedOut" value="backedOut">
                      Backed Out
                    </MenuItem>
                    <MenuItem key="Consultant" value="Consultant">
                      Consultant
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Availability Date
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    size="small"
                    // defaultValue={moment(userData.isAvailableFrom).format(
                    //   "YYYY-MM-DD"
                    // )}
                    type={"date"}
                    name="isAvailableFrom"
                    {...register("isAvailableFrom")}
                    error={errors.isAvailableFrom ? true : false}
                    helpertext={errors.isAvailableFrom?.message}
                    InputLabelProps={{ shrink: true }}
                    open={
                      availability === "Available" ||
                      availability === "mayBeAvailable"
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Contact Number<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    size="small"
                    defaultValue={userData.phoneNumber}
                    type={"tel"}
                    name="phoneNumber"
                    {...register("phoneNumber")}
                    error={errors.phoneNumber ? true : false}
                    helpertext={errors.phoneNumber?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reporting Manager
                  </Typography>
                  <TextField
                    id="outlined-required"
                    size="small"
                    type={"text"}
                    placeholder="Select Reporting Manager"
                    select // Make the field a select field
                    name="reportingManager"
                    defaultValue={
                      userData?.reportingManager?._id
                        ? userData?.reportingManager?._id
                        : ""
                    }
                    {...register("reportingManager")}
                    helpertext={errors.reportingManager?.message}
                    onChange={(e) =>
                      setValue("reportingManager", e.target.value)
                    }
                  >
                    {projectManagers.map((manager) => (
                      <MenuItem key={manager._id} value={manager._id}>
                        {manager.fullName} ({manager.role})
                      </MenuItem>
                    ))}
                    <MenuItem value="">NA</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Emergency Contact Number
                  </Typography>
                  <TextField
                    id="outlined-required"
                    size="small"
                    defaultValue={userData.emergencyContactNumber}
                    type={"tel"}
                    name="emergencyContactNumber"
                    {...register("emergencyContactNumber")}
                    error={errors.emergencyContactNumber ? true : false}
                    helperText={errors.emergencyContactNumber?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Employee ID<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    name="empId"
                    defaultValue={userData.empId}
                    type={"text"}
                    size="small"
                    {...register("empId")}
                    error={errors.empId ? true : false}
                    helpertext={errors.empId?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Base Location<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    required
                    type={"text"}
                    defaultValue={userData.location}
                    onChange={(e) => {
                      setValue("location", e.target.value);
                    }}
                    size="small"
                    error={errors.isAvailable ? true : false}
                    {...register("location")}
                  >
                    <MenuItem key="Bangalore" value="Bangalore">
                      Bangalore
                    </MenuItem>
                    <MenuItem key="Gurgaon" value="Gurgaon">
                      Gurgaon
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Work Location
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    required
                    type={"text"}
                    placeholder="Select Work Location"
                    defaultValue={userData?.workLocation ?? ""}
                    onChange={(e) => {
                      const selectedWorkLocation = e.target.value;
                      setValue("workLocation", e.target.value);
                      if (selectedWorkLocation === "CS") {
                        setIsClientSide(true);
                      } else {
                        setIsClientSide(false);
                        setValue("workLocationCity", "NA");
                      }
                    }}
                    name="workLocation"
                    size="small"
                    error={errors.workLocation ? true : false}
                    helpertext={errors.workLocation?.message}
                  >
                    <MenuItem key="WFH" value="WFH">
                      Work From Home
                    </MenuItem>

                    <MenuItem key="Hybrid" value="Hybrid">
                      Hybrid
                    </MenuItem>
                    <MenuItem key="CS" value="CS">
                      Client Side
                    </MenuItem>
                    <MenuItem key="In-Office" value="In-Office">
                      In Office
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Client Side City
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    required
                    type={"text"}
                    placeholder="Select Work Location City"
                    value={userData?.workLocationCity ?? ""}
                    onChange={(e) => {
                      setValue("workLocationCity", e.target.value);
                    }}
                    name="workLocationCity"
                    size="small"
                    error={errors.workLocationCity ? true : false}
                    helpertext={errors.workLocationCity?.message}
                    {...register("workLocationCity")}
                    disabled={!isClientSide}
                    className={!isClientSide ? "bg-gray-200" : "bg-white"}
                  >
                    {citiesList?.map((city) => (
                      <MenuItem key={city.name} value={city.name}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Exp(Years) prior to joining antino
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Years"
                    size="small"
                    type={"number"}
                    inputProps={{ min: 0 }}
                    defaultValue={Math.floor(
                      userData.workingExperienceInMonths / 12
                    )}
                    name="workingExperienceInYears"
                    {...register("workingExperienceInYears")}
                    error={errors.workingExperienceInYears ? true : false}
                    helperText={errors.workingExperienceInYears?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Exp(Month) prior to joining antino
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    id="outlined-required"
                    placeholder="Months"
                    size="small"
                    type={"number"}
                    inputProps={{ min: 0 }}
                    defaultValue={userData.workingExperienceInMonths % 12}
                    {...register("workingExperienceInMonths")}
                    error={errors.workingExperienceInMonths ? true : false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Senority<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    id="outlined-required"
                    select
                    name="seniority"
                    type={"text"}
                    defaultValue={userData.seniority}
                    onChange={(e) => setValue("seniority", e.target.value)}
                    size="small"
                    // {...register("seniority")}
                    error={errors.isAvailable ? true : false}
                  >
                    <MenuItem key="Junior" value="Junior">
                      Junior
                    </MenuItem>
                    <MenuItem key="MidLevel" value="Mid Level">
                      Mid Level
                    </MenuItem>
                    <MenuItem key="Intern" value="Intern">
                      Intern
                    </MenuItem>
                    <MenuItem key="Senior" value="Senior">
                      Senior
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Designation<span style={{ color: "red" }}> *</span>
                  </Typography>
                  <TextField
                    required
                    select
                    id="outlined-required"
                    onChange={handleChange}
                    size="small"
                    defaultValue={userData.designation?._id}
                    type={"text"}
                    name="designation"
                    {...register("designation")}
                    error={errors.designation ? true : false}
                    helperText={errors.designation?.message}
                  >
                    {designations.map((designation) => (
                      <MenuItem key={designation.name} value={designation._id}>
                        {designation.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Tech Stack
                  </Typography>
                  <Autocomplete
                    multiple
                    id="primary-tech-stack"
                    options={techStacks || []}
                    getOptionLabel={(option) => option.name}
                    value={primaryTechStack}
                    onChange={handleTechStackChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        name="primaryTechStack"
                        size="small"
                        placeholder="Select Tech Stack"
                        defaultValue={userData.primaryTechStack}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Billability %
                  </Typography>
                  <TextField
                    id="outlined-required"
                    size="small"
                    defaultValue={userData?.billability}
                    step={0.5}
                    type={"number"}
                    name="billability"
                    {...register("billability", {
                      validate: (value) => parseInt(value, 10) <= 200,
                    })}
                    error={errors?.billability ? true : false}
                    helperText={
                      errors?.billability ? "Value must be 200 or less" : ""
                    }
                    inputProps={{ min: 0, max: 200 }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Profile
                  </Typography>

                  <TextField
                    id="outlined-required"
                    label="Profile Link"
                    size="small"
                    type="text"
                    name="profileLink"
                    {...register("profileLink")}
                    helperText={errors.profileLink?.message}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Developer Type
                  </Typography>
                  <TextField
                    select
                    id="outlined-required"
                    size="small"
                    name="developerType"
                    {...register("developerType")}
                    helperText={errors.developerType?.message}
                    defaultValue={userData.developerType}
                  >
                    {userData.developerType === "InHouse" ? null : (
                      <MenuItem key="YetToHire" value="YetToHire">
                        YetToHire
                      </MenuItem>
                    )}
                    <MenuItem key="InHouse" value="InHouse">
                      InHouse
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Typography variant="subtitle1" gutterBottom>
                Remarks
              </Typography>
              <textarea
                className="border border-slate-300 w-[100%] rounded-md "
                defaultValue={userData.remarks === null ? "" : userData.remarks}
                name="remarks"
                {...register("remarks")}
                error={errors.remarks ? true : false}
                helperText={errors.remarks?.message}
              ></textarea>
            </DialogContent>

            <DialogActions className=" h-20">
              <Box className="mr-32 mb-2 ">
                <Button
                  onClick={onClose}
                  sx={{
                    color: "#4E79FA",

                    backgroundColor: "white",
                    width: "348.5px",
                    height: "46px",
                    borderRadius: "8px",

                    padding: "0px 36px",

                    transition: "background-color 0.3s, color 0.3s",
                    "&:hover": {
                      backgroundColor: "#4E79FA",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Box>

              <Box className="mr-10 mb-2">
                <Button
                  onClick={handleSubmit(onSubmit)}
                  sx={{
                    color: "#4E79FA",
                    backgroundColor: "white",
                    width: "348.5px",
                    height: "46px",
                    borderRadius: "8px",
                    padding: "0px 36px",
                    // gap: "5px",
                    transition: "background-color 0.3s, color 0.3s",
                    "&:hover": {
                      backgroundColor: "#4E79FA",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  disabled={isLoading}
                >
                  Save Changes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </>
  );
};

export default EditDevModal;
