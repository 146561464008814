import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Box, Paper, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  getDeveloperDetails,
  getOnBoardDetails,
} from "../../apis/DeveloperApi";
import Loader from "./Loader";
import FreeBreakfastIcon from "@mui/icons-material/FreeBreakfast";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import moment from "moment/moment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";

import "./style.css";

const DeveloperDetailsNewPage = () => {
  const location = useLocation();
  const data = location?.state;

  const [userDetail, setUserDetail] = useState([]);
  const [onBoardDetail, setOnBoardDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const [showIcHistory, setShowIcHistory] = useState(false);

  const [designationName, setdesignationName] = useState();
  useEffect(() => {
    const fethcDetails = async () => {
      try {
        const response = await getDeveloperDetails(data?.id);
        setUserDetail(response?.data[0]);

        setdesignationName(response?.data[0]?.designation[0]?.name);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fethcDetails();
  }, []);

  useEffect(() => {
    const FetchOnBoardingDetails = async () => {
      try {
        await getOnBoardDetails(data?.id).then((data) => {
          setOnBoardDetail(data?.data);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    FetchOnBoardingDetails();
  }, []);

  const csvData = [
    ["Developer Name", `${userDetail?.fullName}`],
    ["Email", `${userDetail?.email}`],
    ["Employee ID", `${userDetail?.empId}`],
    ["Peoject", `${userDetail?.projects?.[0]?.projectDetail?.projectName}`],
    ["Location", `${userDetail?.location}`],
  ];

  const getExperienceString = () => {
    const joinDate = moment(
      userDetail?.joiningDate,
      "YYYY-MM-DDTHH:mm:ssZ"
    ).format();
    const currentDate = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    const experienceAfterJoining = Math.floor(
      moment(currentDate).diff(moment(joinDate), "months", true)
    );
    const totalExperience =
      userDetail?.workingExperienceInMonths + experienceAfterJoining;
    const years = Math.floor(totalExperience / 12);
    const months = totalExperience - years * 12;
    const yearString =
      years === 0 ? "" : years === 1 ? years + " Year " : years + " Years ";
    const monthString =
      months === 0
        ? years === 0
          ? "0 Month"
          : ""
        : months === 1
        ? months + " Month "
        : months + " Months ";

    return yearString + monthString;
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Stack spacing={4} direction="row">
              <Button
                sx={{
                  bgcolor: show ? "black" : "lightgray",
                }}
                variant="contained"
                onClick={() => {
                  setShow(true);
                  setShowIcHistory(false);
                  setShowOnBoarding(false);
                }}
              >
                Developer Details
              </Button>
              <Button
                sx={{
                  bgcolor: showOnBoarding ? "black" : "lightgray",
                }}
                variant="contained"
                onClick={() => {
                  setShow(false);
                  setShowIcHistory(false);
                  setShowOnBoarding(true);
                }}
              >
                Onboarding History
              </Button>
            </Stack>
          </Box>

          {show && (
            <>
              <Box sx={{ height: 200 }}>
                <Paper elevation={3} sx={{ padding: 3 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span>
                      <h2>DEVELOPER DETAILS</h2>
                    </span>
                  </div>

                  <Typography
                    sx={{
                      fontSize: 23,
                    }}
                  >
                    <b>
                      {userDetail?.fullName === undefined
                        ? "NA"
                        : userDetail?.fullName.toUpperCase()}
                    </b>
                  </Typography>
                  <div
                    className="Developer_Container"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="firstContainer">
                      <Box sx={{ marginTop: 1 }}>
                        <Typography variant="subtitle1">
                          Position:{" "}
                          <span style={{ paddingLeft: "90px" }}>
                            {designationName === undefined
                              ? "NA"
                              : designationName}
                          </span>
                        </Typography>
                        <Typography variant="subtitle1">
                          TechStack:{" "}
                          <span style={{ paddingLeft: "74px" }}>
                            {userDetail?.techStack?.[0]?.name === undefined
                              ? "NA"
                              : userDetail?.techStack?.[0]?.name}
                          </span>
                        </Typography>

                        <Typography variant="subtitle1">
                          Role:{" "}
                          <span style={{ paddingLeft: "116px" }}>
                            {userDetail?.role}
                          </span>
                        </Typography>

                        <Typography variant="subtitle1">
                          Employee Id:{" "}
                          <span style={{ paddingLeft: "58px" }}>
                            {" "}
                            {userDetail?.empId === undefined
                              ? "NA"
                              : userDetail?.empId}
                          </span>
                        </Typography>
                      </Box>
                    </div>

                    <div className="secondContainer" style={{ width: "25vw" }}>
                      <div
                        className="contrainer"
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Box sx={{ marginTop: 1 }}>
                          <Typography variant="subtitle1">
                            Seniority:{" "}
                            <span style={{ paddingLeft: "50px" }}>
                              {" "}
                              {userDetail?.seniority}
                            </span>
                          </Typography>

                          <Typography variant="subtitle1">
                            Email:{" "}
                            <span style={{ paddingLeft: "72px" }}>
                              {userDetail?.email}
                            </span>
                          </Typography>

                          <Typography variant="subtitle1">
                            Experience:{" "}
                            <span style={{ paddingLeft: "30px" }}>
                              {getExperienceString()}
                            </span>
                          </Typography>

                          <Typography variant="subtitle1">
                            Phone:{" "}
                            <span style={{ paddingLeft: "64px" }}>
                              {userDetail?.phoneNumber}
                            </span>
                          </Typography>
                        </Box>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Box>

              <div
                className="mainCard"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.25rem",
                  zIndex: "3",
                  width: "80vw",
                  height: "20",
                }}
              >
                {userDetail?.projects?.map((info, i) => {
                  return (
                    <div
                      className="card card-1"
                      style={{
                        background: "#FFFFFF",
                        display: "inline-",
                        height: "320px",
                        width: "27vw",
                        position: "relative",
                        top: "65px",
                        borderRadius: "2rem",

                        transition: " all 0.3s cubic-bezier(.25,.8,.25,1)",
                      }}
                    >
                      <h2
                        className="Project-Heading"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "1rem",
                        }}
                      >
                        {info?.projectDetail?.projectName.toUpperCase()}
                      </h2>

                      <div
                        className="ProjectDetailsCard"
                        style={{
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                          paddingTop: "1rem",
                          display: "flex",
                          flexDirection: "column",
                          gap: "6px",
                        }}
                      >
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {" "}
                            Project Manager:{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {info?.PM[0]?.fullName === undefined
                              ? "NA"
                              : info?.PM[0]?.fullName}{" "}
                          </p>
                        </div>

                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {" "}
                            Type of Project :{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {" "}
                            {info?.projectDetail?.typeOfProject === undefined
                              ? "NA"
                              : info?.projectDetail?.typeOfProject}
                          </p>
                        </div>
                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            Tech Stack:{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {" "}
                            {info?.projectDetail?.techStack.map((TechInfo) => {
                              return TechInfo?.name === undefined
                                ? "NA"
                                : `${TechInfo?.name} `;
                            })}
                          </p>
                        </div>

                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            Start Date:{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {info?.projectDetail?.startDate === undefined
                              ? "NA"
                              : moment(info?.projectDetail?.startDate).format(
                                  "L"
                                )}
                          </p>
                        </div>

                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            End Date:{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {info?.projectDetail?.estimatedEndDate === undefined
                              ? "NA"
                              : moment(
                                  info?.projectDetail?.estimatedEndDate
                                ).format("L")}{" "}
                          </p>
                        </div>

                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            Billing Type:{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {info?.onboardProjectDetail[0]?.billingType}{" "}
                          </p>
                        </div>

                        <div
                          className="content"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            Engagement Type:{" "}
                          </p>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "1rem",
                              lineHeight: "1.75",
                              letterSpacing: " 0.00938em",
                            }}
                          >
                            {info?.onboardProjectDetail[0]?.engagementType}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {showOnBoarding && (
            <div>
              <div
                className="headerDiv"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "78vw",
                }}
              >
                <h3
                  style={{
                    display: "flex",
                    paddingLeft: "60px",
                    paddingTop: "20px",
                    fontSize: "26px",
                    fontWeight: "500",
                  }}
                >
                  {userDetail?.fullName}{" "}
                </h3>
                <div
                  className="downloadDetails"
                  style={{
                    position: "relative",
                    top: "6px",
                  }}
                >
                  <CSVLink data={csvData} filename={"Developer_Details.csv"}>
                    <FileDownloadIcon
                      disableInteractive
                      fontSize="large"
                      style={{
                        color: "black",
                      }}
                    />
                  </CSVLink>
                </div>
              </div>
              <div
                className="Container"
                style={{
                  width: "75vw",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="OnBoarding"
                  style={{ width: "35vw", paddingTop: "2rem" }}
                >
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    FullTime Timeline
                  </h3>

                  <div
                    className="FullTime-Timeline"
                    style={{ marginTop: "1.4rem" }}
                  >
                    {onBoardDetail?.FullTimeData?.map((info) => {
                      const date = new Date(info?.onboardDate);
                      const formatedDate1 = date?.toLocaleDateString("en-GB");

                      if (info?.offBoardDate === "current") {
                        var date2 = new Date();
                        var formatedDate2 = date2?.toLocaleDateString("en-GB");

                        const calculateDateDifference = () => {
                          const [day1, month1, year1] = formatedDate1
                            .split("/")
                            .map(Number);
                          const [day2, month2, year2] = formatedDate2
                            .split("/")
                            .map(Number);

                          const dateObject1 = new Date(year1, month1 - 1, day1);
                          const dateObject2 = new Date(year2, month2 - 1, day2);

                          const differenceInMilliseconds = Math.abs(
                            dateObject2 - dateObject1
                          );
                          const differenceInDays = Math.ceil(
                            differenceInMilliseconds / (1000 * 60 * 60 * 24)
                          );

                          return differenceInDays;
                        };

                        var difference = calculateDateDifference();

                        const datee = new Date();
                        const formatedTodayDate =
                          datee?.toLocaleDateString("en-GB");
                      } else if (info?.offBoardDate !== "current") {
                        var date2 = new Date(info?.offBoardDate);

                        var formatedDate2 = date2?.toLocaleDateString("en-GB");

                        const calculateDateDifference = () => {
                          const [day1, month1, year1] = formatedDate1
                            .split("/")
                            .map(Number);
                          const [day2, month2, year2] = formatedDate2
                            .split("/")
                            .map(Number);

                          const dateObject1 = new Date(year1, month1 - 1, day1);
                          const dateObject2 = new Date(year2, month2 - 1, day2);

                          const differenceInMilliseconds = Math.abs(
                            dateObject2 - dateObject1
                          );
                          const differenceInDays = Math.ceil(
                            differenceInMilliseconds / (1000 * 60 * 60 * 24)
                          );

                          return differenceInDays;
                        };

                        var difference = calculateDateDifference();

                        const datee = new Date();
                        const formatedTodayDate =
                          datee?.toLocaleDateString("en-GB");
                      }

                      return (
                        <Timeline position="alternate">
                          <TimelineItem>
                            <TimelineSeparator>
                              {/* <TimelineConnector /> */}
                              {info?.projectName.toUpperCase() ===
                              "ON BENCH" ? (
                                <TimelineDot color="error">
                                  {" "}
                                  <FreeBreakfastIcon
                                    style={{ color: "#fff" }}
                                  />{" "}
                                </TimelineDot>
                              ) : info?.projectName.toUpperCase() ===
                                "WAS ON BENCH" ? (
                                <TimelineDot color="warning">
                                  {" "}
                                  <FreeBreakfastIcon
                                    style={{ color: "#fff" }}
                                  />{" "}
                                </TimelineDot>
                              ) : (
                                <TimelineDot color="success">
                                  {" "}
                                  <LaptopMacIcon
                                    style={{ color: "#fff" }}
                                  />{" "}
                                </TimelineDot>
                              )}

                              <TimelineConnector />
                            </TimelineSeparator>

                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                              {info?.projectName.toUpperCase() == "ON BENCH" ? (
                                <p
                                  style={{
                                    color: "#f44336",
                                    fontSize: "20px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {info?.projectName.toUpperCase()}
                                </p>
                              ) : info?.projectName.toUpperCase() ===
                                "WAS ON BENCH" ? (
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#ff8c00",
                                  }}
                                >
                                  {info?.projectName.toUpperCase()}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#1baf1b",
                                  }}
                                >
                                  {info?.projectName.toUpperCase()}
                                </p>
                              )}

                              <Typography>
                                {info?.projectName.toUpperCase() ===
                                "ON BENCH" ? (
                                  <p style={{ color: "#fd1d29" }}>
                                    {" "}
                                    From : {date?.toLocaleDateString(
                                      "en-GB"
                                    )}{" "}
                                  </p>
                                ) : info?.projectName.toUpperCase() ===
                                  "WAS ON BENCH" ? (
                                  <p style={{ color: "#ff8c00 " }}>
                                    {" "}
                                    From : {date?.toLocaleDateString(
                                      "en-GB"
                                    )}{" "}
                                  </p>
                                ) : (
                                  <p style={{ color: "#1baf1b" }}>
                                    {" "}
                                    From : {date?.toLocaleDateString(
                                      "en-GB"
                                    )}{" "}
                                  </p>
                                )}
                              </Typography>
                              <Typography>
                                {info?.projectName.toUpperCase() ===
                                "ON BENCH" ? (
                                  info?.offBoardDate === "current" ? (
                                    <p style={{ color: "#fd1d29" }}>
                                      {" "}
                                      To : current
                                    </p>
                                  ) : info?.projectName.toUpperCase() ===
                                    "WAS ON BENCH" ? (
                                    <p style={{ color: "#ff8c00" }}>
                                      To: {date2?.toLocaleDateString("en-GB")}
                                    </p>
                                  ) : (
                                    <p style={{ color: "#fd1d29" }}>
                                      To: {date2?.toLocaleDateString("en-GB")}
                                    </p>
                                  )
                                ) : info?.offBoardDate === "current" ? (
                                  <p style={{ color: "#1baf1b" }}>
                                    {" "}
                                    To : current
                                  </p>
                                ) : info?.projectName.toUpperCase() ===
                                  "WAS ON BENCH" ? (
                                  <p style={{ color: "#ff8c00" }}>
                                    To: {date2?.toLocaleDateString("en-GB")}
                                  </p>
                                ) : (
                                  <p style={{ color: "#1baf1b" }}>
                                    To: {date2?.toLocaleDateString("en-GB")}
                                  </p>
                                )}
                              </Typography>
                              <Typography>
                                {info?.projectName.toUpperCase() ===
                                "ON BENCH" ? (
                                  info?.offBoardDate === "current" ? (
                                    <p style={{ color: "#fd1d29" }}>
                                      {" "}
                                      Duration: {difference} Days
                                    </p>
                                  ) : info?.projectName.toUpperCase() ===
                                    "WAS ON BENCH" ? (
                                    <p style={{ color: "#ff8c00" }}>
                                      {difference} Days
                                    </p>
                                  ) : (
                                    <p style={{ color: "#fd1d29" }}>
                                      Duration: {difference} Days
                                    </p>
                                  )
                                ) : info?.offBoardDate == "current" ? (
                                  <p style={{ color: "#1baf1b" }}>
                                    {" "}
                                    Duration: {difference} Days
                                  </p>
                                ) : info?.projectName.toUpperCase() ===
                                  "WAS ON BENCH" ? (
                                  <p style={{ color: "#ff8c00" }}>
                                    Duration: {difference} Days
                                  </p>
                                ) : (
                                  <p style={{ color: "#1baf1b" }}>
                                    Duration: {difference} Days
                                  </p>
                                )}
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                      );
                    })}
                  </div>
                </div>

                <div
                  className="OnBoarding"
                  style={{
                    width: "35vw",
                    paddingTop: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  <h3 style={{ display: "flex", justifyContent: "center" }}>
                    PartTime Timeline
                  </h3>

                  <div
                    className="PartTime-Timeline"
                    style={{ marginTop: "1.4rem" }}
                  >
                    {onBoardDetail?.PartTimeData?.map((info) => {
                      const date = new Date(info?.onboardDate);
                      const formatedDate1 = date?.toLocaleDateString("en-GB");

                      if (info?.offBoardDate === "current") {
                        var date2 = new Date();
                        var formatedDate2 = date2?.toLocaleDateString("en-GB");

                        const calculateDateDifference = () => {
                          const [day1, month1, year1] = formatedDate1
                            .split("/")
                            .map(Number);
                          const [day2, month2, year2] = formatedDate2
                            .split("/")
                            .map(Number);

                          const dateObject1 = new Date(year1, month1 - 1, day1);
                          const dateObject2 = new Date(year2, month2 - 1, day2);

                          const differenceInMilliseconds = Math.abs(
                            dateObject2 - dateObject1
                          );
                          const differenceInDays = Math.ceil(
                            differenceInMilliseconds / (1000 * 60 * 60 * 24)
                          );

                          return differenceInDays;
                        };

                        var difference = calculateDateDifference();

                        const datee = new Date();
                        const formatedTodayDate =
                          datee?.toLocaleDateString("en-GB");
                      } else if (info?.offBoardDate !== "current") {
                        var date2 = new Date(info?.offBoardDate);

                        var formatedDate2 = date2?.toLocaleDateString("en-GB");

                        const calculateDateDifference = () => {
                          const [day1, month1, year1] = formatedDate1
                            .split("/")
                            .map(Number);
                          const [day2, month2, year2] = formatedDate2
                            .split("/")
                            .map(Number);

                          const dateObject1 = new Date(year1, month1 - 1, day1);
                          const dateObject2 = new Date(year2, month2 - 1, day2);

                          const differenceInMilliseconds = Math.abs(
                            dateObject2 - dateObject1
                          );
                          const differenceInDays = Math.ceil(
                            differenceInMilliseconds / (1000 * 60 * 60 * 24)
                          );

                          return differenceInDays;
                        };

                        var difference = calculateDateDifference();

                        const datee = new Date();
                        const formatedTodayDate =
                          datee?.toLocaleDateString("en-GB");
                      }

                      return (
                        <Timeline>
                          <TimelineItem>
                            <TimelineSeparator>
                              {/* <TimelineConnector /> */}
                              <TimelineDot color="success">
                                <LaptopMacIcon style={{ color: "#fff" }} />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                              <Typography variant="h6" component="span">
                                <p style={{ color: "#1baf1b" }}>
                                  {info?.projectName.toUpperCase()}
                                </p>
                              </Typography>
                              <Typography>
                                <p style={{ color: "#1baf1b" }}>
                                  From : {date?.toLocaleDateString("en-GB")}{" "}
                                </p>
                              </Typography>
                              <Typography>
                                <p style={{ color: "#1baf1b" }}>
                                  {" "}
                                  To :
                                  {info?.offBoardDate === "current"
                                    ? "current"
                                    : date2?.toLocaleDateString("en-GB")}{" "}
                                </p>
                              </Typography>
                              <Typography>
                                <p style={{ color: "#1baf1b" }}>
                                  {" "}
                                  Duration: {difference} Days
                                </p>
                              </Typography>
                            </TimelineContent>
                          </TimelineItem>
                        </Timeline>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {showIcHistory && <h1>Ic Historyyyyyyy</h1>}
        </>
      )}
    </div>
  );
};

export default DeveloperDetailsNewPage;
