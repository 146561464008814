import {
  getdeveloperOnboardDetails,
  downloadOnboardHistoryExcel,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch, useSelector } from "react-redux";
import { Space, Tooltip } from "antd";
import { useEffect, useState } from "react";

import { getGroups } from "../../../Redux/Slices/Lists/ListsSlice";
import Reports from "./Reports";
import { debounce, throttle } from "lodash";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const OnboardHistoryReport = () => {
  const dispatch = useDispatch();
  const { groups, searchFilters, futureOnboardingDetails } = useSelector(
    (store) => store.lists
  );
  const [name, setName] = useState("");
  const showOnboardHistoryFilter = true;

  const handleSearch = debounce((name) => {
    dispatch(getdeveloperOnboardDetails({ name }));
  }, 800);

  const handleDownload = () => {
    dispatch(downloadOnboardHistoryExcel()).then((response) => {
      toast.success(response.payload.message);
    });
    toast.success("Downloading CSV file");
  };

  useEffect(() => {
    dispatch(getdeveloperOnboardDetails());
    if (groups) {
      dispatch(getGroups());
    }
  }, [searchFilters]);

  const color = (text) => {
    const common = {
      display: "block",
      padding: "5px",
      borderRadius: "14px",
      font: "Lato",
      fontWeight: "500",
      fontSize: "16px",
      textAlign: "center",
      width: "8rem",
    };

    if (text.includes("On")) {
      return { color: "white", backgroundColor: "#00E600", ...common };
    }
    if (text.includes("Off")) {
      return { color: "white", backgroundColor: "#FF3333", ...common };
    }
  };

  const rows =
    futureOnboardingDetails &&
    futureOnboardingDetails.map((user) => {
      let updatedDate = dayjs(user.date).format("DD-MMM-YYYY");
      return {
        status: user.Status,
        clientStatus: user.clientStatus,
        date: updatedDate,
        fullName: user.fullName,
        POC: user.POC,
        location: user.location,
        projectName: user.projectName,
        projectType: user.projectType,
        remark: user.remark,
        techStack: user.techStack,
        billingType: user.billingType,
      };
    });

  let columns = [
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      width: 180,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span style={color(text)}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Developer Name",
      dataIndex: "fullName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Billability",
      dataIndex: "billingType",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Project",
      dataIndex: "projectName",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Project Manager",
      dataIndex: "POC",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Project Type",
      dataIndex: "projectType",
      width: 200,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Tech Stack",
      dataIndex: "techStack",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Reports
      columns={columns}
      rows={rows}
      handleSearch={handleSearch}
      title="Onboarding/Offboarding History"
      handleDownload={handleDownload}
      searchPlaceholder="Search Developer Name"
      setName={setName}
      name={name}
      showOnboardHistoryFilter={showOnboardHistoryFilter}
    />
  );
};

export default OnboardHistoryReport;
