import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ labels, count }) => {

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = Array.from({ length: count.length }, generateRandomColor);

  const data = {
    labels: labels,

    datasets: [
      {
        label: "Number of Project",
        data: count,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "bottom", // Display legend at the bottom
      },
    },
    scales: {
      xAxes: [
        {
          position: "top",
          ticks: {
            padding: 100,
          },
        },
      ],
    },
  };

  return (
    <div className="w-full h-[60vh]">
      {labels.length > 0 && count.length > 0 && (
        <Doughnut data={data} options={options} />
      )}
    </div>
  );
};

export default DoughnutChart;
