import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../apiclient";

const initialState = {
  loading: false,
  clientRequirement: [],
  error: "",
  searchFilters: {},
};

export const getClientRequirements = createAsyncThunk(
  "client/getClientRequirements",
  async (thunkAPI) => {
    try {
      let res = await api.get(`api/v2/clientRequirement/all`);
      return res?.data?.resp;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSalesPoc = createAsyncThunk(
  "clientRequirement/getSalesPoc",
  async (thunkAPI) => {
    try {
      let res = await api.get(`api/v2/clientRequirement/getSalesPoc`);
      return res?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const clientRequirementSlice = createSlice({
  name: "clientRequirement",
  initialState,
  reducers: {
    setFilterParameters(state, action) {
      state.searchFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientRequirements.fulfilled, (state, action) => {
      state.loading = false;
      state.clientRequirement = action?.payload?.resp;
    });
    builder.addCase(getClientRequirements.pending, (state, action) => {
      state.loading = true;
      state.error = action.error?.message;
    });
    builder.addCase(getClientRequirements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
    });
    builder.addCase(getSalesPoc.fulfilled, (state, action) => {
      state.loading = false;
      state.salesPoc = action?.payload?.salesPocData;
    });
    builder.addCase(getSalesPoc.pending, (state, action) => {
      state.loading = true;
      state.error = action.error?.message;
    });
    builder.addCase(getSalesPoc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message;
      state.salesPoc = [];
    });
  },
});

export const { setFilterParameters } = clientRequirementSlice.actions;
export const ClientRequirementReducer = clientRequirementSlice.reducer
