import api from "../apiclient";

export const addClientRequirement = async (data) => {

  const res = await api.post(`api/v2/clientRequirement/add`, data);
  return res?.data;
};

//get client by id api

// api/v2/clientRequirement/search/648fffb21886c2711bbb07c2

export const GetClient = async (id) => {
  const res = await api.get(`api/v2/clientRequirement/search/${id}`);
  return res?.data;

};


export const editClientRequirement = async (data) => {
  const res = await api.post("api/v2/clientRequirement/edit", data);
  return res;
};

export const deleteClientRequirement = async (id) => {
  return await api.delete(`api/v2/clientRequirement/delete/${id}` );
};