import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { toast } from "react-toastify";

import { passwordChange } from "../Redux/Slices/Teams/TeamSlice";

import { useDispatch } from "react-redux";

import VisibilityIcon from "@mui/icons-material/Visibility";

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useNavigate } from "react-router-dom";

function PasswordChangeModal({ open, onClose }) {
  const [currentPassword, setCurrentPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isPasswordValid = (password) => {
    // Password should contain at least one lowercase letter,
    // one uppercase letter, one digit, and be at least 6 characters long
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(password);
  };

  const handleSubmit = async () => {
    if (currentPassword === "") {
      toast("Please enter current password");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast("New password and confirm password should match", {
        autoClose: 2000,
      });
      return;
    }

    if (!isPasswordValid(newPassword)) {
      toast(
        "New password should contain at least one lowercase, one uppercase letter, one digit, and be at least 6 characters long",
        {
          autoClose: 3000,
        }
      );
      return;
    }

    const data = {
      currentPassword,

      newPassword,

      confirmPassword,
    };

    dispatch(passwordChange(data))
      .unwrap()

      .then((response) => {
        toast("Password updated successfully");
        localStorage.removeItem("accessToken");
        onClose();
        setTimeout(() => {
          navigate("/login");
        }, 3000);

        setCurrentPassword("");

        setNewPassword("");

        setConfirmPassword("");
      })

      .catch((error) => {
        toast(error?.response?.data?.message);
      });
  };

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "currentPassword":
        setShowCurrentPassword(!showCurrentPassword);

        break;

      case "newPassword":
        setShowNewPassword(!showNewPassword);

        break;

      case "confirmPassword":
        setShowConfirmPassword(!showConfirmPassword);

        break;

      default:
        break;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Change Password</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Current Password"
          type={showCurrentPassword ? "text" : "password"}
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility("currentPassword")}
                  edge="end"
                >
                  {showCurrentPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          margin="dense"
          label="New Password"
          fullWidth
          type={showNewPassword ? "text" : "password"}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility("newPassword")}
                  edge="end"
                >
                  {showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          margin="dense"
          label="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                  edge="end"
                >
                  {showConfirmPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions
        sx={{
          padding: "0 1.5rem 1rem",
        }}
      >
        <Button
          onClick={() => {
            onClose();
            
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");

            setShowConfirmPassword(false);
            setShowNewPassword(false);
            setShowCurrentPassword(false);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          size="large"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordChangeModal;
