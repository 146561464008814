import * as React from "react";
import Button from "@mui/material/Button";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form } from "formik";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { removeDeveloper } from "../Redux/Slices/Project/ProjectsSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { getDeveloper } from "../Redux/Slices/Project/ProjectsSlice";

const OffboardingDeveloperModal = ({
  open,
  onClose,
  onBoardingDate,
  currentDeveloper,
  id,
  fetchAgain,
}) => {
  const dispatch = useDispatch();

  const shouldDisableDate = (date) => {
    const disableBeforeDate = new Date(onBoardingDate);
    disableBeforeDate.setDate(disableBeforeDate.getDate() - 1);
    return date < disableBeforeDate;
  };

  const boardingTypes = ["Offboarded", "OffReplaced", "OffObservation"];

  const formik = useFormik({
    initialValues: {
      remark: "",
      offBoardingDate: null,
      projectId: "",
      userId: "",
      boardingType: "",
    },
    validationSchema: Yup.object({
      remark: Yup.string().required("Required"),
      offBoardingDate: Yup.date().required("Required"),
      boardingType: Yup.string().required("Required"), // Add validation for boardingType
    }),
    onSubmit: async (values) => {
      try {
        values.userId = currentDeveloper;
        values.projectId = id;
        const isValidDate = dayjs(values.offBoardingDate).isValid();
        if (isValidDate) {
          if (
            values.boardingType === "OffObservation" &&
            dayjs(values.offBoardingDate).isAfter(dayjs(), "day")
          ) {
            toast("OffObservation cannot be scheduled in the future");
            onClose();
          } else {
            const formattedDate = dayjs(values.offBoardingDate).format(
              "YYYY-MM-DD"
            );
            const isoDate = new Date(formattedDate).toISOString();
            values.offBoardingDate = isoDate;
            await dispatch(removeDeveloper(values)).unwrap();
            toast("Developer removed successfully");
            onClose();
            await dispatch(fetchAgain());
            await dispatch(getDeveloper(id)).unwrap();
          }
        } else {
          // console.error("Invalid date:", values.offBoardingDate);
        }
      } catch (error) {
        toast(error?.message);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Offboard</DialogTitle>
        <DialogContent>
          To Offboard this developer, select Offboarding Date.
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "25px",
                    }}
                  >
                    Offboarding Date
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "red",
                      fontSize: "12px",
                      lineHeight: "16px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </Typography>
                </Box>
                <DesktopDatePicker
                  defaultValue={formik.values.offBoardingDate || null}
                  value={formik.values.offBoardingDate || null}
                  onChange={(newValue) => {
                    formik.setFieldValue("offBoardingDate", newValue);
                  }}
                  sx={{ width: "100%" }}
                  shouldDisableDate={shouldDisableDate}
                  error={
                    formik.touched.offBoardingDate &&
                    Boolean(formik.errors.offBoardingDate)
                  }
                  helperText={
                    formik.touched.offBoardingDate &&
                    formik.errors.offBoardingDate
                  }
                  slotProps={{
                    textField: {
                      readOnly: true,
                    },
                  }}
                />
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "25px",
                    }}
                  >
                    Offboarding Type
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "red",
                      fontSize: "12px",
                      lineHeight: "16px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </Typography>
                </Box>
                <TextField
                  mb={2}
                  select
                  sx={{ width: "100%" }}
                  required
                  name="boardingType"
                  onChange={formik.handleChange}
                  value={formik.values.boardingType}
                  error={
                    formik.touched.boardingType &&
                    Boolean(formik.errors.boardingType)
                  }
                  helperText={
                    formik.touched.boardingType && formik.errors.boardingType
                  }
                >
                  {boardingTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontFamily: "Lato",
                      fontWeight: "500",
                      fontSize: "15px",
                      lineHeight: "25px",
                    }}
                  >
                    Remark
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "red",
                      fontSize: "12px",
                      lineHeight: "16px",
                      marginLeft: "2px",
                    }}
                  >
                    *
                  </Typography>
                </Box>
                <TextField
                  mb={2}
                  sx={{ width: "100%" }}
                  name="remark"
                  required
                  onChange={formik.handleChange}
                  value={formik.values.remark}
                  error={formik.touched.remark && Boolean(formik.errors.remark)}
                  helperText={formik.touched.remark && formik.errors.remark}
                />
              </Box>
            </DialogContent>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={formik.handleSubmit}>Offboard</Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default OffboardingDeveloperModal;
