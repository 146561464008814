import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { getTechStacks, getPoc } from "../../../Redux/Slices/Lists/ListsSlice";
import { editClientRequirement } from "../../../apis/ClientApi";

import {
  getClientRequirements,
  getSalesPoc,
} from "../../../Redux/Slices/ClientRequirement/ClientRequirementSlice";
import { toast } from "react-toastify";
import moment from "moment";
const EditClient = ({ open, onClose, user }) => {
  const [editDataa, seteditDataa] = useState(user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: editDataa?.id ?? "",
      clientName: editDataa?.clientName ?? "",
      status: editDataa?.status ? editDataa?.status : "",
      techStack: editDataa?.techStack_id[0] ? editDataa?.techStack_id[0] : {},
      count: editDataa?.count ? editDataa?.count : "",
      seniorityLevel: editDataa?.seniorityLevel
        ? editDataa?.seniorityLevel
        : "",
      remarks: editDataa?.remarks ? editDataa?.remarks : "",
      POC: editDataa?.poc_obj ? editDataa?.poc_obj : {},
      salesPoc: editDataa?.salesPoc_obj ? editDataa?.salesPoc_obj : {},
      deadline: editDataa?.deadline
        ? moment(editDataa?.deadline).subtract(1, "d")
        : "",
      requirementOpenDate: editDataa?.requirementOpenDate
        ? moment(editDataa?.requirementOpenDate).subtract(1, "d")
        : "",
      daysLeft: editDataa?.daysLeft ? editDataa?.daysleft : "",
      hireDate: editDataa?.hireDate ? editDataa?.hireDate : "",
      hiringDuration: editDataa?.hiringDuration
        ? editDataa?.hiringDuration
        : "",
    },
    // validationSchema: CreateClientSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const useStyles = makeStyles({
    container: {
      gap: "10px",
    },
    inputItem: {
      width: "100%",
    },
    paper: { minWidth: "500px" },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (formik.values.deadline) {
      const deadline = moment(formik.values.deadline);
      const currentDate = moment();
      const daysLeft = deadline.diff(currentDate, "days");
      formik.setFieldValue("daysLeft", daysLeft);
    }
  }, [formik.values.deadline]);

  const handleSubmit = (values) => {
    const techStackIds = values?.techStack?._id;
    const pocIds = values?.POC?._id;
    const salesPoc = values?.salesPoc?._id;

    let obj = {
      _id: values?._id,
      clientName: values?.clientName,
      status: values?.status,
      startDate: values?.startDate,
      techStack: techStackIds,
      count: values?.count,
      seniorityLevel: values?.seniorityLevel,
      remarks: values?.remarks,
      POC: pocIds,
      salesPoc: salesPoc,
      deadline: moment(values?.deadline).add(1, "d"),
      requirementOpenDate: moment(values?.requirementOpenDate).add(1, "d"),
      daysLeft: values?.daysLeft,
      hireDate: values?.hireDate,
      hiringDuration: values?.hiringDuration,
    };
    // create editt

    editClientRequirement(obj)
      .then((res) => {
        toast(res?.data?.resp?.message);
        setTimeout(() => {
          formik.handleReset();
          onClose();
          dispatch(getClientRequirements());
        });
      })
      .catch((error) => toast(error?.response?.data?.message));
  };

  useEffect(() => {
    dispatch(getTechStacks());
    dispatch(getPoc());
    dispatch(getSalesPoc());
  }, []);

  const techStack = useSelector((store) => store?.lists?.techStacks);
  const poc = useSelector((store) => store?.lists?.poc);

  const { salesPoc } = useSelector((store) => store?.clientRequirement);
  const status = ["OPEN", "HOT", "CLOSE", "COLD", "LOST"];
  const seniorityLevel = ["Intern", "Junior", "Mid Level", "Senior"];

  const my = (value) => {
    formik.setFieldValue("POC", value);
  };
  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Dialog
            className={classes.paper}
            maxWidth={"md"}
            open={open}
            Close={onClose}
            sx={{
              "& .MuiDialogActions-root": {
                padding: "0px !important",
              },
            }}
          >
            <DialogTitle>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Edit Client Requirement
                <Button
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                  }}
                  onClick={onClose}
                >
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                </Button>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                sx={{
                  "&  .MuiAutocomplete-popper": {
                    paddingBottom: "1rem",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "#D32F2F",
                  },
                }}
                mt={1}
                spacing={2}
              >
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    required
                    value={formik?.values?.clientName}
                    name="clientName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Client Name"
                    helperText={
                      formik.touched.clientName ? formik.errors.clientName : ""
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={status}
                    disablePortal={true}
                    className={classes.inputItem}
                    name="status"
                    getOptionLabel={(option) =>
                      option?.isDeleted ? "" : option
                    }
                    sx={{
                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        visibility: "hidden",
                      },
                    }}
                    value={formik.values.status}
                    onChange={(event, value) =>
                      formik.setFieldValue("status", value)
                    }
                    onBlur={formik.handleBlur}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik.values.status}
                        name="status"
                        label="Status"
                        helperText={
                          formik.touched.status ? formik.errors.status : ""
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={classes.inputItem}
                    freeSolo
                    options={techStack || []}
                    getOptionLabel={(option) => option.name}
                    name="techStack"
                    value={formik?.values?.techStack}
                    onChange={(event, value) =>
                      formik.setFieldValue("techStack", value)
                    }
                    onBlur={formik.handleBlur}
                    filterSelectedOptions
                    size="small"
                    renderInput={(params) => (
                      <>
                        <TextField
                          required
                          {...params}
                          name="techStack"
                          label="Tech Stack"
                          value={formik?.values?.techStack}
                          helperText={
                            formik.touched.techStack
                              ? formik.errors.techStack
                              : ""
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    required
                    value={formik.values.count}
                    name="count"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Count"
                    helperText={formik.touched.count ? formik.errors.count : ""}
                    autocomplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    options={seniorityLevel}
                    disablePortal={true}
                    className={classes.inputItem}
                    name="seniorityLevel"
                    getOptionLabel={(option) =>
                      option?.isDeleted ? "" : option
                    }
                    sx={{
                      "& .MuiButtonBase-root.MuiAutocomplete-clearIndicator": {
                        visibility: "hidden",
                      },
                    }}
                    value={formik.values.seniorityLevel}
                    onChange={(event, value) =>
                      formik.setFieldValue("seniorityLevel", value)
                    }
                    onBlur={formik.handleBlur}
                    size="small"
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        value={formik.values.seniorityLevel}
                        name="seniorityLevel"
                        label="Seniority Level"
                        helperText={
                          formik.touched.seniorityLevel
                            ? formik.errors.seniorityLevel
                            : ""
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    // required
                    value={formik.values.remarks}
                    name="remarks"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Remarks"
                    helperText={
                      formik.touched.remarks ? formik.errors.remarks : ""
                    }
                    autocomplete="off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={classes.inputItem}
                    options={poc}
                    name="POC"
                    disablePortal={true}
                    getOptionLabel={(option) =>
                      `${option.fullName} (${option.role})`
                    }
                    getOptionSelected={(option, value) =>
                      option._id === value?._id
                    }
                    filterSelectedOptions
                    onChange={(event, value) => my(value)}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.POC}
                    size="small"
                    renderInput={(params) => (
                      <>
                        <TextField
                          required
                          {...params}
                          name="POC"
                          label="POC"
                          value={formik?.values?.POC}
                          helperText={
                            formik.touched.POC ? formik.errors.POC : ""
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    className={classes.inputItem}
                    options={salesPoc || []}
                    name="salesPOC"
                    disablePortal={true}
                    getOptionLabel={(option) =>
                      `${option.fullName} (${option.role})`
                    }
                    getOptionSelected={(option, value) =>
                      option._id === value?._id
                    }
                    filterSelectedOptions
                    onChange={(event, value) => {
                      formik.setFieldValue("salesPoc", value);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik?.values?.salesPoc}
                    size="small"
                    renderInput={(params) => (
                      <>
                        <TextField
                          required
                          {...params}
                          name="salesPoc"
                          label="SALES POC"
                          value={formik?.values?.salesPoc}
                          helperText={
                            formik.touched?.salesPoc
                              ? formik.errors?.salesPoc
                              : ""
                          }
                        />
                      </>
                    )}
                  />
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid item xs={6}>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      inputFormat="MM/dd/yyyy"
                      slotProps={{ textField: { size: "small" } }}
                      label="Requirement Open Date"
                      value={
                        formik.values.requirementOpenDate &&
                        new Date(
                          moment(formik?.values?.requirementOpenDate).format(
                            "MM/DD/YYYY"
                          )
                        )
                      }
                      onChange={(newValue) => {
                        formik.setFieldValue("requirementOpenDate", newValue);
                      }}
                      invalidDateMessage="Computer says no"
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                          onBlur={(e) => {
                            formik.setFieldTouched("startDate", true);
                          }}
                          helperText={
                            formik.touched.startDate
                              ? formik.errors.startDate
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>

                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      inputFormat="MM/dd/yyyy"
                      slotProps={{ textField: { size: "small" } }}
                      label="Deadline"
                      value={
                        formik.values.deadline &&
                        new Date(
                          moment(formik?.values?.deadline).format("MM/DD/YYYY")
                        )
                      }
                      onChange={(newValue) => {
                        formik.setFieldValue("deadline", newValue);
                      }}
                      minDate={
                        formik.values.requirementOpenDate
                          ? new Date(formik.values.requirementOpenDate)
                          : null
                      }
                      invalidDateMessage="Computer says no"
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                          onBlur={(e) => {
                            formik.setFieldTouched("startDate", true);
                          }}
                          helperText={
                            formik.touched.startDate
                              ? formik.errors.startDate
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.daysLeft}
                    name="daysLeft"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Days Left"
                    helperText={
                      formik.touched.daysLeft ? formik.errors.daysLeft : ""
                    }
                    autocomplete="off"
                    disabled
                  />
                </Grid>

                {/* <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.hireDate}
                    name="hireDate"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Hire Date"
                    helperText={
                      formik.touched.hireDate ? formik.errors.hireDate : ""
                    }
                    autocomplete="off"
                    // required
                  />
                </Grid> */}
                {/* neww hire date */}
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      inputFormat="MM/dd/yyyy"
                      slotProps={{ textField: { size: "small" } }}
                      label="Hire Date"
                      value={
                        formik.values.hireDate &&
                        new Date(
                          moment(formik.values.hireDate).format("MM/DD/YYYY")
                        )
                      }
                      onChange={(newValue) => {
                        formik.setFieldValue("hireDate", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                          onBlur={(e) => {
                            formik.setFieldTouched("hireDate", true);
                          }}
                          helperText={
                            formik.touched.hireDate
                              ? formik.errors.hireDate
                              : ""
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* -------------- */}
                {/* <Grid item xs={6}>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      sx={{ width: '100%' }}
      inputFormat="MM/dd/yyyy"  // Use 'MM/dd/yyyy' here to match the format
      slotProps={{ textField: { size: 'small' } }}
      label="Hire Date"
      value={
        formik.values.hireDate
          ? new Date(formik.values.hireDate) // No need to format the date
          : null
      }
      onChange={(newValue) => {
        formik.setFieldValue('hireDate', newValue);
      }}
      renderInput={(params) => (
        <TextField
          size="small"
          required
          fullWidth
          {...params}
          onBlur={(e) => {
            formik.setFieldTouched('hireDate', true);
          }}
          helperText={
            formik.touched.hireDate ? formik.errors.hireDate : ''
          }
        />
      )}
    />
  </LocalizationProvider>
</Grid> */}

                <Grid item xs={6}>
                  <TextField
                    size="small"
                    className={classes.inputItem}
                    value={formik.values.hiringDuration}
                    name="hiringDuration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label="Hiring Duration"
                    helperText={
                      formik.touched.hiringDuration
                        ? formik.errors.hiringDuration
                        : ""
                    }
                    autocomplete="off"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box sx={{ margin: "8px 20px" }}>
                <Button
                  onClick={formik.handleSubmit}
                  sx={{ color: "white" }}
                  variant="contained"
                >
                  Save Client requirement
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </>
  );
};

export default EditClient;
