import { Box, Button, Stack, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../../src/apiclient";
import { DataGrid } from "@mui/x-data-grid";
import DeveloperReports from "./DeveloperReports";
import IcChart from "./IcChart";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LoadingSpinner from "../../../utils/LoadingSpinner";

const Reports = () => {
  const [showSchedule, setShowSchedule] = useState(true);
  const [showByDeveloper, setShowByDeveloper] = useState(false);
  const [showIcCount, setShowIcCount] = useState(false);
  const [scheduledByData, setScheduledByData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (newValue) => {
    setFromDate(newValue);
  };

  const handleToDateChange = (newValue) => {
    setToDate(newValue);
  };
  useEffect(() => {
    const getReportsScheduledby = async () => {
      const res = await api.get(
        `api/v2/ic/scheduleByData?startDate=${fromDate}&endDate=${toDate}`
      );
      setScheduledByData(res?.data?.data);
      setIsLoading(false);
      return res?.data;
    };
    getReportsScheduledby();
  }, [fromDate, toDate]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      width: 150,
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Accepted",
      headerName: "Accepted",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Rejected",
      headerName: "Rejected",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Rescheduled",
      headerName: "Rescheduled",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Cancelled",
      headerName: "Cancelled",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "Pending",
      headerName: "Pending",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "grandCount",
      headerName: "Grand Count",
      headerAlign: "center",
      width: 160,
      align: "center",
      renderCell: (param) => (
        <Tooltip title={param.value} placement="right" arrow>
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              cursor: "context-menu",
            }}
          >
            {param.value}
          </span>
        </Tooltip>
      ),
    },
  ];

  const rows = scheduledByData?.map((user) => {
    function getPendingCount(data) {
      let pendingCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Pending") {
          pendingCount += data[i].count;
        }
      }
      return pendingCount;
    }
    function getAcceptedCount(data) {
      let acceptedCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Accepted") {
          acceptedCount += data[i].count;
        }
      }
      return acceptedCount;
    }
    function getRejectedCount(data) {
      let rejectedCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Rejected") {
          rejectedCount += data[i].count;
        }
      }
      return rejectedCount;
    }
    function getRescheduledCount(data) {
      let rescheduledCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Rescheduled") {
          rescheduledCount += data[i].count;
        }
      }
      return rescheduledCount;
    }
    function getCancelledCount(data) {
      let canceledCount = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].feedback === "Cancelled") {
          canceledCount += data[i].count;
        }
      }
      return canceledCount;
    }
    return {
      // name : user,
      id: user?._id,
      name: user?.fullName,
      grandCount: user?.grandCount,
      Pending: getPendingCount(user?.feedbackCounts),
      Accepted: getAcceptedCount(user?.feedbackCounts),
      Rejected: getRejectedCount(user?.feedbackCounts),
      Rescheduled: getRescheduledCount(user?.feedbackCounts),
      Cancelled: getCancelledCount(user?.feedbackCounts),
    };
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,

          m: 1,
          bgcolor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Stack spacing={4} direction="row">
          <Button
            sx={{
              bgcolor: showSchedule ? "black" : "lightgray",
            }}
            variant="contained"
            onClick={() => {
              setShowSchedule(true);
              setShowByDeveloper(false);
              setShowIcCount(false);
            }}
          >
            Scheduled By
          </Button>
          <Button
            sx={{
              bgcolor: showByDeveloper ? "black" : "lightgray",
            }}
            variant="contained"
            onClick={() => {
              setShowSchedule(false);
              setShowByDeveloper(true);
              setShowIcCount(false);
            }}
          >
            Developer
          </Button>
          <Button
            sx={{
              bgcolor: showIcCount ? "black" : "lightgray",
            }}
            variant="contained"
            onClick={() => {
              setShowSchedule(false);
              setShowByDeveloper(false);
              setShowIcCount(true);
            }}
          >
            Ic Count
          </Button>
        </Stack>
      </Box>

      {showSchedule && (
        <>
          <div style={{ height: "70vh", width: "100%" }}>
            <div
              style={{
                width: "100%",
                height: "4.5rem",
                display: "flex",
                gap: "15px",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  width: "9.5rem",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    sx={{ width: "100%" }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                      },
                    }}
                    inputFormat="MM-DD-YYYY"
                    label="From"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(params) => (
                      <>
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                        />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div
                style={{
                  width: "9.5rem",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    sx={{ width: "100%" }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                      },
                    }}
                    inputFormat="MM-DD-YYYY"
                    label="TO"
                    value={toDate}
                    onChange={handleToDateChange}
                    minDate={fromDate}
                    // disabled={true}
                    disabled={!fromDate}
                    renderInput={(params) => (
                      <>
                        <TextField
                          size="small"
                          required
                          fullWidth
                          {...params}
                        />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </div>
            </div>

            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <DataGrid
                rows={rows}
                rowHeight={40}
                columns={columns}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                // hideFooterPagination
                disableColumnMenu
                disableColumnFilter
                sx={{
                  backgroundColor: "white",
                  "& .MuiDataGrid-columnHeaders": {
                    // fontSize: "1rem",
                    background: "black",
                    color: "white",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    color: "white",
                  },
                  "&    .MuiDataGrid-menuIconButton": {
                    color: "white",
                  },
                  "&    .MuiDataGrid-filterIcon": {
                    color: "white",
                  },
                }}
                componentsProps={{
                  loadingOverlay: {
                    style: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  },
                  noRowsOverlay: {
                    style: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  },
                }}
              />
            )}
          </div>
        </>
      )}
      {showByDeveloper && (
        <>
          <DeveloperReports />
        </>
      )}
      {showIcCount && (
        <>
          <IcChart />
        </>
      )}
    </>
  );
};

export default Reports;
