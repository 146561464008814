import { Button, Modal, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFutureOnboardingsLogs,
  offboardFutureOnboardedDeveloper,
} from "../../../Redux/Slices/Project/ProjectsSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const FutureOnboardingsModal = ({ open, onClose, id }) => {
  const dispatch = useDispatch();
  const { futureOnboardingsLogs, isLoading1 } = useSelector(
    (store) => store.project
  );

  useEffect(() => {
    dispatch(getFutureOnboardingsLogs({ projectId: id }));
  }, []);

  const offboardFutureOnboardedDeveloperHandler = (scheduleId) => {
    dispatch(offboardFutureOnboardedDeveloper({ scheduleId: scheduleId })).then(
      () => {
        toast("Future Onboardings deleted succesfully");
        dispatch(getFutureOnboardingsLogs({ projectId: id }));
      }
    );
  };

  const rows =
    futureOnboardingsLogs?.length > 0 &&
    futureOnboardingsLogs?.map((futureOnboardingsLog) => {
      return {
        engagementType: futureOnboardingsLog.engagementType,
        name: futureOnboardingsLog.name,
        boardingType: futureOnboardingsLog.boardingType,
        onBoardingDate: futureOnboardingsLog.onBoardingDate,
        OffBoardAction: futureOnboardingsLog._id,
      };
    });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 50,
      align: "center",
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
      fixed: "left",
    },
    {
      dataIndex: "OffBoardAction",
      title: "Action",
      width: 50,
      align: "center",
      render: (text, record) => (
        <div>
          <Button
            onClick={() => {
              offboardFutureOnboardedDeveloperHandler(text);
            }}
            style={{
              backgroundColor: " #80808066",
              padding: "2px 3px",
              fontSize: "13px",
              minWidth: "55px",
            }}
          >
            Cancel
          </Button>
        </div>
      ),
    },
    {
      title: "Onboarding Date",
      dataIndex: "onBoardingDate",
      width: 50,

      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{dayjs(text).format("DD-MMM-YYYY")}</span>
        </Tooltip>
      ),
    },
    {
      title: "Boarding Type",
      dataIndex: "boardingType",
      width: 50,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Engagement Type",
      dataIndex: "engagementType",
      width: 50,
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={false}
      centered
      destroyOnClose
      width={700}
      title="Future Onboarding"
    >
      <Table
        dataSource={rows}
        columns={columns}
        rowKey="key"
        scroll={{ y: 400 }}
        pagination={false}
        loading={isLoading1}
      />
    </Modal>
  );
};

export default FutureOnboardingsModal;
