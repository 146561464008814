import React from "react";
import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import "../../../Assets/CSS/Groups/groups.css";

const ActionDialog = ({
  editModalHandler,
  open,
  onClose,
  addDeveloper,
  handleDelete,
  handleProjectStatus,
}) => {
  return (
    <>
      <Modal
        open={open}
        onCancel={onClose}
        footer={null}
        centered
        destroyOnClose
        width={300}
      >
        <div className="flex flex-col text-start justify-center gap-2">
          <Button
            className="action-button "
            onClick={() => {
              editModalHandler();
              onClose();
            }}
          >
            <EditOutlined />
            Edit
          </Button>
          <Button
            className="action-button "
            onClick={() => {
              addDeveloper();
              onClose();
            }}
          >
            <UsergroupAddOutlined />
            Add
          </Button>

          <Button
            className="action-button "
            onClick={() => {
              handleDelete();
              onClose();
            }}
          >
            <DeleteOutlined />
            Delete
          </Button>
          <Button
            className="action-button "
            onClick={() => {
              handleProjectStatus();
              onClose();
            }}
          >
            <BarChartOutlined />
            Update Project Status
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ActionDialog;
