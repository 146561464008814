import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../apiclient";

const initialState = {
  isLoading: false,
  error: "",
  pieDeveloperData: [],
  barData: [],
  pieProjectData: [],
  pmWiseProjectsData: [],
  delayedProjectData: [],
  citiesList: [],
  barChartData: [],


};

export const getDeveloperPieChart = createAsyncThunk(
  "dashboard/getDeveloperPieChart",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(
        `api/v2/dashboard/get-developer-pie-chart?category=${data}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCitiesName = createAsyncThunk(
  "dashboard/getCitiesName",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(`api/v2/cities/getCities`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDeveloperBarChart = createAsyncThunk(
  "dashboard/getDeveloperBarChart",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(
        `api/v2/dashboard/get-developer-bar-chart?category=${data}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProjectPieChart = createAsyncThunk(
  "dashboard/getProjectPieChart",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(
        `api/v2/dashboard/get-project-pie-chart?category=${data}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getProjectPmChart = createAsyncThunk(
  "dashboard/getProjectPmChart",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(`api/v2/dashboard/get-project-pm-chart`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDelayedProjectData = createAsyncThunk(
  "dashboard/getDelayedProjectData",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(
        `api/v2/dashboard/get-project-Delay-Data?category=${data}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//new barchart 
export const getbarChartData = createAsyncThunk(
  "dashboard/getbarChartData",
  async(url,thunkAPI)=>{
    try{
      let response = await api.get(
        `api/v2/dashboard/${url}`
      );
      return response.data;
    }
    catch(error){
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
)





const DashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getDeveloperPieChart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pieDeveloperData = action.payload.data;
      state.error = "";
    });
    builder.addCase(getDeveloperPieChart.pending, (state, action) => {
      state.isLoading = true;
      state.pieDeveloperData = [];
      state.error = "";
    });
    builder.addCase(getDeveloperPieChart.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action?.payload?.message;
    });
    builder.addCase(getProjectPieChart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pieProjectData = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getProjectPieChart.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getProjectPieChart.rejected, (state, action) => {
      state.isLoading = false;
      state.pieProjectData = [];
      state.error = action?.payload?.message;
    });
    builder.addCase(getCitiesName.fulfilled, (state, action) => {
      state.isLoading = false;
      state.citiesList = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getCitiesName.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getCitiesName.rejected, (state, action) => {
      state.isLoading = false;
      state.citiesList = [];
      state.error = action?.payload?.message;
    });
    builder.addCase(getDeveloperBarChart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.barData = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getDeveloperBarChart.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getDeveloperBarChart.rejected, (state, action) => {
      state.isLoading = false;
      state.barData = [];
      state.error = action?.payload?.message;
    });
    builder.addCase(getProjectPmChart.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pmWiseProjectsData = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getProjectPmChart.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getProjectPmChart.rejected, (state, action) => {
      state.isLoading = false;
      state.pmWiseProjectsData = [];
      state.error = action?.payload?.message;
    });
    builder.addCase(getDelayedProjectData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.delayedProjectData = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(getDelayedProjectData.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getDelayedProjectData.rejected, (state, action) => {
      state.isLoading = false;
      state.delayedProjectData = [];
      state.error = action?.payload?.message;
    });
    //new bar chart
    builder.addCase(getbarChartData.fulfilled, (state, action) => {            
      state.isLoading = false;
      state.barChartData= action?.payload?.data
      state.error = "";
    });
    builder.addCase(getbarChartData.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    });
    builder.addCase(getbarChartData.rejected, (state, action) => {
      state.isLoading = false;
      state.barChartData = [];
      state.error = action?.payload?.message;
    });
  
  },

  
});


export default DashboardSlice.reducer;
