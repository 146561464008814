import { Button, DatePicker, Input, Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectStatus,
  postProjectStatus,
} from "../../../Redux/Slices/Groups/GroupsSlice";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const ProjectStatus = () => {
  const { state } = useLocation();
  const groupId = state.groupId;
  const dispatch = useDispatch();
  const { getProjectStatusData, statusLoading } = useSelector(
    (store) => store.groups
  );
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [projectStatus, setProjectStatus] = useState([]);

  useEffect(() => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    dispatch(getProjectStatus({ formattedDate, groupId }));
  }, [selectedDate]);

  useEffect(() => {
    if (
      Array.isArray(getProjectStatusData.projectStatus) &&
      getProjectStatusData?.projectStatus.length > 0
    ) {
      setProjectStatus(
        getProjectStatusData?.projectStatus.map((getProjectStatus) => {
          return {
            ...getProjectStatus?.currentWeekprojectStatus,
            projectId: getProjectStatus?._id,
          };
        })
      );
    } else {
      setProjectStatus([]);
    }
  }, [getProjectStatusData.projectStatus]);

  const handleStatusChange = (value, projectId) => {
    const projectIndex = projectStatus.findIndex(
      (project) => project.projectId === projectId
    );

    if (projectIndex === -1) {
      projectStatus.push({
        projectId: projectId,
        status: value,
      });
    } else {
      projectStatus[projectIndex] = {
        ...projectStatus[projectIndex],
        status: value,
      };
    }
  };

  const handleRemarkChange = (value, projectId) => {
    if (value.trim() !== "") {
      const projectIndex = projectStatus.findIndex(
        (project) => project.projectId === projectId
      );

      if (projectIndex === -1) {
        projectStatus.push({ projectId: projectId, remark: value });
      } else {
        projectStatus[projectIndex] = {
          ...projectStatus[projectIndex],
          remark: value,
        };
      }
    }
  };

  const handleSubmit = async () => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    // Check if any project is missing the "status" key
    const hasMissingStatus = projectStatus.some(
      (project) =>
        !project.hasOwnProperty("status") && project.hasOwnProperty("remark")
    );

    if (hasMissingStatus) {
      toast.error("Please select status for all projects");
      return; // Stop further execution
    }
    dispatch(
      postProjectStatus({ date: formattedDate, projectStatus: projectStatus })
    ).then((response) => {
      toast(response.payload.message);
    });
  };

  const disabledFutureDate = (current) => {
    const today = new Date();
    return current && current > today;
  };

  const handleDateChange = (value) => {
    setSelectedDate(value.format());
  };

  const rows =
    getProjectStatusData?.projectStatus?.length > 0 &&
    getProjectStatusData?.projectStatus?.map((project) => {
      return {
        projectName: project.projectName,
        pm: project.pm,
        typeOfProject: project.typeOfProject,
        status: project?.currentWeekprojectStatus
          ? project?.currentWeekprojectStatus?.status
          : "Not Filled",
        remark: project?.currentWeekprojectStatus
          ? project?.currentWeekprojectStatus?.remark
          : "",
        lastRemark: project?.lastWeekProjectStatus?.remark
          ? project?.lastWeekProjectStatus?.remark
          : "Not Filled",
        lastStatus: project?.lastWeekProjectStatus?.status
          ? project?.lastWeekProjectStatus?.status
          : "Not Filled",
        id: project._id,
      };
    });

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      width: 150,
      fixed: "left",
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Type",
      dataIndex: "typeOfProject",
      width: 200,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Project Manager",
      dataIndex: "pm",
      width: 200,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 180,
      render: (text, record) => (
        <Select
          style={{ width: 180 }}
          onChange={(value) => handleStatusChange(value, record.id)}
          defaultValue={text}
        >
          <Select.Option
            value="All Good"
            style={{
              backgroundColor: "#11734B",
              marginBottom: "2px",
              color: "white",
            }}
          >
            All Good
          </Select.Option>
          <Select.Option
            value="Need Improvement"
            style={{
              backgroundColor: "#DEF779",
              marginBottom: "2px",
              color: "black",
            }}
          >
            Need Improvement
          </Select.Option>
          <Select.Option
            value="Critical"
            style={{
              backgroundColor: "#B10202",
              marginBottom: "2px",
              color: "white",
            }}
          >
            Critical
          </Select.Option>
        </Select>
      ),
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      width: 200,
      render: (text, record) => (
        <Input.TextArea
          defaultValue={text}
          placeholder={text === "" ? "Not Filled yet" : text}
          autoSize={{ minRows: 2, maxRows: 5 }}
          onChange={(e) => {
            const value = e.target.value;
            handleRemarkChange(value, record.id);
          }}
        />
      ),
    },
    {
      title: "Last Week Status",
      dataIndex: "lastStatus",
      width: 180,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Last Week Remark",
      dataIndex: "lastRemark",
      width: 180,
      render: (text) => (
        <Tooltip title={text} placement="right" arrow>
          <span>{text}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <div
      style={{
        height: "85vh",
        backgroundColor: "white",
        padding: "10px 32px 24px 32px",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "2rem",
          marginBottom: "2rem",
        }}
      >
        <h1
          style={{
            font: "Montserrat",
            fontSize: "1.5rem",
            fontWeight: "500",
          }}
        >
          Update Project Status
        </h1>
        <DatePicker
          onChange={handleDateChange}
          disabledDate={disabledFutureDate}
          picker="week"
          defaultValue={dayjs(
            dayjs(selectedDate).format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          )}
        />
      </div>
      <div className="w-[75vw] h-[80%] overflow-y-auto">
        <Table
          dataSource={rows}
          columns={columns}
          rowKey="key"
          pagination={false}
          scroll={"false"}
          loading={statusLoading}
        />
      </div>
      <div className="flex justify-end mr-1">
        <Button
          onClick={handleSubmit}
          className="bg-blue-500 text-white hover:bg-blue-800 hover:!text-white mt-5 "
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ProjectStatus;
