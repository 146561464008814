import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../apiclient";

const initialState = {
  isLoading: false,
  error: "",
  getFilledDates: [],
};

export const getTimesheetFilledDates = createAsyncThunk(
  "timesheet/getTimesheetFilledDates",
  async (data, thunkAPI) => {
    try {
      let response = await api.get(
        `api/v2/timesheet/getTimesheetFilledDates?userId=${data.userId}&startDate=${data.startDate}&endDate=${data.endDate}&projectId=${data.projectId}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const TimesheetSlice = createSlice({
  name: "timesheet",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getTimesheetFilledDates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.getFilledDates = action.payload.data;
      state.error = "";
    });
    builder.addCase(getTimesheetFilledDates.pending, (state, action) => {
      state.isLoading = true;
      state.getFilledDates = [];
      state.error = "";
    });
    builder.addCase(getTimesheetFilledDates.rejected, (state, action) => {
      state.isLoading = false;
      state.getFilledDates = [];
    });
  },
});

export default TimesheetSlice.reducer;
