import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../../apiclient";

const initialState = {
  isLoading: false,
  msg: "",
  code: "",
  status: "",
  userData: [],
  keyword: "",
};

export const reg_dev_vld = createAsyncThunk(
  "registerDeveloper/reg_dev_vld",
  async (data, { rejectWithValue }) => {
    try {
      let res = await api.post("/api/v2/users/add-user", data);
      toast("Developer Added Successfully");
      return res.data;
    } catch (error) {
      if (error.response.status === 409 || error.response.status === 400) {
        toast.error(error.response.data.message);

        return rejectWithValue(error.response.data);
      }
      return error.response;
    }
  }
);

export const AddIcUser = createAsyncThunk(
  "registerDeveloper/AddIcUser",
  async (data, { rejectWithValue }) => {
    try {
      let res = await api.post("/api/v2/users/add-ICuser", data);
      toast("Developer Added Successfully");
      return res.data;
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(error.response.data.message);

        return rejectWithValue(error.response.data);
      }
      return error.response;
    }
  }
);
export const get_user = createAsyncThunk(
  "registerDeveloper/get_user",
  async (data) => {
    const user_url = "/api/v2/users/getDetails?userId=" + data;
    try {
      let res = await api.get(user_url);
      return res.data;
    } catch (error) {
      return error.response;
    }
  }
);

export const update_user = createAsyncThunk(
  "registerDeveloper/update_user",
  async (data, { rejectWithValue }) => {
    const update_url = "/api/v2/users/edit-user";
    const requiredData = data;
    const payload = { ...requiredData.data, id: requiredData.id };
    try {
      let res = await api.put(update_url, payload);
      toast("Developer Updated Successfully");
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const RegDevSlice = createSlice({
  name: "registerDeveloper",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "";
    },
    addEditData: (state, action) => {
      state.editData = action.payload.thisRow;
      state.id = action.payload.id;
    },
    emptyUserData: (state) => {
      state.userData = {};
    },
  },
  extraReducers: {
    [reg_dev_vld.pending]: (state) => {
      state.isLoading = true;
    },
    [reg_dev_vld.fulfilled]: (state, action) => {
      state.msg = action.payload.msg;
      state.isLoading = false;
      state.code = action.payload.code;
      state.status = action.payload.status;
    },
    [reg_dev_vld.rejected]: (state, action) => {
      state.isLoading = false;
      state.msg = action.payload.message;
      state.status = action.payload.status;
    },
    [AddIcUser.pending]: (state) => {
      state.isLoading = true;
    },
    [AddIcUser.fulfilled]: (state, action) => {
      state.msg = action.payload.msg;
      state.isLoading = false;
      state.code = action.payload.code;
      state.status = action.payload.status;
    },
    [AddIcUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.msg = action.payload.message;
      state.status = action.payload.status;
    },
    [get_user.pending]: (state) => {
      state.isLoading = true;
      state.userData = {};
    },
    [get_user.fulfilled]: (state, action) => {
      state.msg = action.payload.msg;
      state.isLoading = false;

      state.userData = action.payload.data;
      state.code = action.payload.code;
    },
    [get_user.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [update_user.pending]: (state) => {
      state.isLoading = true;
    },
    [update_user.fulfilled]: (state, action) => {
      state.msg = action.payload.msg;
      state.isLoading = false;
      state.code = action.payload.code;
      state.status = action.payload.status;
    },
    [update_user.rejected]: (state, action) => {
      state.isLoading = false;
      state.keyword = action.payload.keyValue;
      state.msg = action.payload.msg;
      state.status = action.payload.status;
    },
  },
});

export const { resetStatus, addEditData, emptyUserData } = RegDevSlice.actions;

export default RegDevSlice.reducer;
