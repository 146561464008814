import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Modal } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import {
  getTechStacks,
} from "../../../Redux/Slices/Lists/ListsSlice";
import { useDispatch } from "react-redux";
import { getAllIC } from "../../../apis/ICApi";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../Assets/CSS/IC/IC.css";

function filterUniqueData(data, key) {
  const seen = new Set();
  return data.filter((item) => {
    if (!seen.has(item[key])) {
      seen.add(item[key]);
      return true;
    }
    return false;
  });
}

const IcFilter = ({
  setDeveloperData,
  filterData,
  setFilterData,
  setpayloadSelected,
  clientName,
  setClientName,
}) => {
  const dispatch = useDispatch();

  const [originalData, setOriginalData] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [filterValues, setFilterValues] = useState({
    selectedDeveloperName: [],
    selectedTechStack: [],
    selectedClientName: [],
    selectedScheduledBy: [],
    selectedDeveloperType: [],
    selectedFeedBack: [],
    Searchclientname: "",
  });

  useEffect(() => {
    dispatch(getTechStacks());
  }, []);

  const { handleSubmit, reset } = useForm({
    defaultValues: {
      searchValue: "",
      selectedDeveloperName: [],
      selectedTechStack: [],
      selectedClientName: [],
      selectedScheduledBy: [],
      selectedDeveloperType: [],
      selectedFeedBack: [],
      selectedname: "",
      Searchclientname: "",
    },
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    // Map selected values to their corresponding IDs
    const selectedDeveloperIds = filterValues.selectedDeveloperName.map(
      (developerName) => {
        const matchingDeveloper = uniqueDeveloperData.find(
          (data) => data?.developer === developerName
        );
        return matchingDeveloper?.developer_id;
      }
    );

    const selectedTechStackIds = filterValues.selectedTechStack.map(
      (techStackName) => {
        const matchingTechStack = uniqueTechStackData.find(
          (ts) => ts.techStack === techStackName
        );
        return matchingTechStack?.techStackId;
      }
    );

    const selectedClientIds = filterValues.selectedClientName.map(
      (clientName) => {
        const matchingClient = uniqueClientData.find(
          (data) => data?.clientName === clientName
        );
        return matchingClient?.clientId;
      }
    );

    const selectedScheduledByIds = filterValues.selectedScheduledBy.map(
      (scheduledByName) => {
        const matchingScheduledBy = uniqueScheduledByData.find(
          (data) => data?.scheduledBy === scheduledByName
        );
        return matchingScheduledBy?.scheduledById;
      }
    );

    const payload = {
      developer: selectedDeveloperIds,
      techStack: selectedTechStackIds,
      clientName: selectedClientIds,
      scheduledBy: selectedScheduledByIds,
      developerType: filterValues.selectedDeveloperType,
      feedBack: filterValues.selectedFeedBack,
      Searchclientname: filterValues.Searchclientname,
    };

    getAllIC(payload)
      .then((response) => {
        setDeveloperData(response?.data);
        setpayloadSelected(payload);
      })
      .catch((error) => {
        // console.error("API Error:", error);
        // Handle API error here
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    handleReset();
  };

  const handleSearch = () => {
    // Implement the updated filtering logic here
    const selectedDeveloperIds = filterValues.selectedDeveloperName.map(
      (developerName) => {
        const matchingDeveloper = uniqueDeveloperData.find(
          (data) => data?.developer === developerName
        );
        return matchingDeveloper?.developer_id;
      }
    );

    const selectedTechStackIds = filterValues.selectedTechStack.map(
      (techStackName) => {
        const matchingTechStack = uniqueTechStackData.find(
          (ts) => ts.techStack === techStackName
        );
        return matchingTechStack?.techStackId;
      }
    );

    const selectedClientIds = filterValues.selectedClientName.map(
      (clientName) => {
        const matchingClient = uniqueClientData.find(
          (data) => data?.clientName === clientName
        );
        return matchingClient?.clientId;
      }
    );

    const selectedScheduledByIds = filterValues.selectedScheduledBy.map(
      (scheduledByName) => {
        const matchingScheduledBy = uniqueScheduledByData.find(
          (data) => data?.scheduledBy === scheduledByName
        );
        return matchingScheduledBy?.scheduledById;
      }
    );

    const filteredICData = originalData.filter((data) => {
      if (
        (selectedDeveloperIds.length === 0 ||
          selectedDeveloperIds.includes(data.developer_id)) &&
        (selectedTechStackIds.length === 0 ||
          selectedTechStackIds.includes(data.techStackId)) &&
        (selectedClientIds.length === 0 ||
          selectedClientIds.includes(data.clientId)) &&
        (selectedScheduledByIds.length === 0 ||
          selectedScheduledByIds.includes(data.scheduledById)) &&
        (filterValues.selectedDeveloperType.length === 0 ||
          filterValues.selectedDeveloperType.includes(data.developerType)) &&
        (filterValues.selectedFeedBack.length === 0 ||
          filterValues.selectedFeedBack.includes(data.feedBack)) &&
        (filterValues.Searchclientname === "" ||
          data.clientName
            .toLowerCase()
            .includes(filterValues.Searchclientname.toLowerCase()))
      ) {
        return true;
      }
      return false;
    });

    setDeveloperData(filteredICData);
  };

  const handleReset = () => {
    reset();
    setFilterValues({
      selectedDeveloperName: [],
      selectedTechStack: [],
      selectedClientName: [],
      selectedScheduledBy: [],
      selectedDeveloperType: [],
      selectedFeedBack: [],
      Searchclientname: "",
    });

    getAllIC()
      .then((response) => {
        setDeveloperData(response?.data);
        setFilterData(response?.data);
        setpayloadSelected({});
      })
      .catch((error) => {
        // console.error("API Error:", error);
      });
  };

  const handleChangeTechStack = (event, newValue) => {
    setFilterValues({ ...filterValues, selectedTechStack: newValue });
  };

  const handleChangeDeveloperName = (event, newValue) => {
    setFilterValues({ ...filterValues, selectedDeveloperName: newValue });
  };

  const handleChangeClientName = (event, newValue) => {
    setFilterValues({ ...filterValues, selectedClientName: newValue });
  };

  const handleChangeScheduledBy = (event, newValue) => {
    setFilterValues({ ...filterValues, selectedScheduledBy: newValue });
  };

  const uniqueDeveloperData = filterUniqueData(filterData, "developer_id");
  const uniqueTechStackData = filterUniqueData(filterData, "techStackId");
  const uniqueClientData = filterUniqueData(filterData, "clientId");
  const uniqueScheduledByData = filterUniqueData(filterData, "scheduledById");
  const uniqueFeedBackData = filterUniqueData(filterData, "feedBack");

  return (
    <div>
      <div class="flex items-center gap-4 my-2.5">
        <TextField
          size="small"
          placeholder="Search Client Name"
          value={clientName}
          autoComplete={"off"}
          sx={{
            width: "25rem",
            borderRadius: "8px",
          }}
          onChange={(e) => setClientName(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="outlined"
          color="primary"
          endIcon={<FilterOutlined />}
          onClick={showModal}
          className="filter-filter-button"
        >
          Filter
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={handleReset}
          className="filter-reset-button"
        >
          Reset
        </Button>
      </div>

      <form onSubmit={handleSubmit(handleSearch)}>
        <Modal
          title="IC Filter"
          open={isModalVisible}
          onOk={handleSubmit(handleOk)}
          onCancel={handleCancel}
          footer={[
            <div className="flex gap-4 mt-8 justify-around">
              <Button
                key="cancel"
                variant="contained"
                onClick={handleCancel}
                className="cancel-button-style"
              >
                Cancel
              </Button>
              <Button
                key="filter"
                type="primary"
                variant="contained"
                onClick={handleSubmit(handleOk)}
                className="apply-button-style"
              >
                Apply Filter
              </Button>
            </div>,
          ]}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel style={{ color: "Black" }}></InputLabel>
              <Autocomplete
                multiple
                id="developer-name"
                options={uniqueDeveloperData.map((data) => data.developer)}
                value={filterValues.selectedDeveloperName}
                onChange={handleChangeDeveloperName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Developer Name"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel style={{ color: "Black" }}></InputLabel>
              <Autocomplete
                multiple
                id="tech-stack"
                options={uniqueTechStackData.map((ts) => ts.techStack)}
                value={filterValues.selectedTechStack}
                onChange={handleChangeTechStack}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tech Stack"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel style={{ color: "Black" }}></InputLabel>
              <Autocomplete
                multiple
                id="client-name"
                options={uniqueClientData.map((data) => data.clientName)}
                value={filterValues.selectedClientName}
                onChange={handleChangeClientName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Client Name"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel style={{ color: "Black" }}></InputLabel>
              <Autocomplete
                multiple
                id="scheduled-by"
                options={uniqueScheduledByData.map((data) => data.scheduledBy)}
                value={filterValues.selectedScheduledBy}
                onChange={handleChangeScheduledBy}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Scheduled By"
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel style={{ color: "Black" }}></InputLabel>
              <Autocomplete
                multiple
                id="developer-type-autocomplete"
                options={["YetToHire", "InHouse"]}
                value={filterValues.selectedDeveloperType}
                onChange={(_, newValue) => {
                  setFilterValues({
                    ...filterValues,
                    selectedDeveloperType: newValue,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Developer Type"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputLabel style={{ color: "Black" }}></InputLabel>
              <Autocomplete
                multiple
                id="feedback"
                options={uniqueFeedBackData.map((data) => data.feedBack)}
                value={filterValues.selectedFeedBack}
                onChange={(event, newValue) =>
                  setFilterValues({
                    ...filterValues,
                    selectedFeedBack: newValue,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="FeedBack"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Modal>
      </form>
    </div>
  );
};

export default IcFilter;
