import React from "react";
import { Modal, Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";

const ActionDialog = ({
  open,
  onClose,
  deleteHandler,
  editHandler,
  handleUnassignGroup,
  handleAssignGroup,
}) => {
  const styleButtons = {
    color: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    border: "none",
    fontWeight: "500",
    fontSize: "1rem",
    boxShadow: "none",
  };
  return (
    <Modal
      open={open}
      onCancel={onClose}
      footer={null}
      centered
      destroyOnClose
      width={300}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "center",
          gap: "0.5rem",
        }}
      >
        <Button
          style={styleButtons}
          onClick={() => {
            editHandler();
            onClose();
          }}
        >
          <EditOutlined />
          Edit
        </Button>
        <Button
          style={styleButtons}
          onClick={() => {
            handleAssignGroup();
            onClose();
          }}
        >
          <UsergroupAddOutlined />
          Assign Group
        </Button>
        <Button
          style={styleButtons}
          onClick={() => {
            handleUnassignGroup();
            onClose();
          }}
        >
          <UsergroupDeleteOutlined />
          Unassign Group
        </Button>
        <Button
          style={styleButtons}
          onClick={() => {
            deleteHandler();
            onClose();
          }}
        >
          <DeleteOutlined />
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default ActionDialog;
