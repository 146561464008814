import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import LoadingSpinner from "../../../utils/LoadingSpinner";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getTechStacks } from "../../../Redux/Slices/Lists/ListsSlice";
import { toast } from "react-toastify";

import {
  getDeveloperNames,
  getAllProjectManagers,
  getAllIC,
  getClientname,
} from "../../../Redux/Slices/IC/ICSlice";

import { GetIC } from "../../../apis/ICApi";
import { UpdateIC } from "../../../apis/ICApi";
const EditIC = ({ open, onClose, setIsSuccess, id, isSuccess }) => {
  const [IC, setIC] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    GetIC(id)
      .then((res) => {
        setIC(res.data);
        setLoading(false); // Hide the loader when data is fetched
      })
      .catch((error) => {
        setLoading(false); // Hide the loader on error
      });
  }, [id]);

  const useStyles = makeStyles({
    container: {
      gap: "10px",
    },
    containerItem: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "12px",
    },
    inputItem: {
      width: "100%",
    },
    paper: { minWidth: "500px" },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const { developerNames, ics } = useSelector((store) => store.ICS);

  const { clientList } = useSelector((store) => store.ICS);

  const Feedback = [
    "Accepted",
    "Rejected",
    "Cancelled",
    "Rescheduled",
    "Pending",
  ];
  const developerType = ["YetToHired", "InHouse"];
  const ICfeedbackForm = ["Yes", "No"];

  const { projectManagers } = useSelector((store) => store.ICS);

  const techStacks = useSelector((store) => store?.lists?.techStacks);

  useEffect(() => {
    dispatch(getAllIC());
  }, [isSuccess]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getDeveloperNames());
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getClientname());
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchPm = async () => {
      dispatch(getAllProjectManagers());
    };

    fetchPm();
  }, [dispatch]);

  const handleSubmit = (values) => {
    let obj = {
      developer: values?.developer?._id,
      clientName: values?.clientId || "",

      iCDate: values?.iCDate,
      iCSlot: values?.iCSlot,
      developerType: values?.developerType,
      scheduledBy: values?.scheduledBy?._id,
      techStack: values?.techStack?._id,
      feedBack: values?.feedBack,
      ICfeedbackForm: values?.ICfeedbackForm,
      remarks: values?.remarks,
    };

    UpdateIC(id, obj)
      .then((res) => {
        toast(res?.message);
        setTimeout(() => {
          formik.handleReset();
          onClose();
          dispatch(getAllIC());
        });

        setIsSuccess(!isSuccess);
      })
      .catch((error) => toast(error?.response?.data?.message));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientName: IC?.clientName?.clientName || "", // Initialize with the client name
      clientId: IC?.clientName?._id || "",
      developerType: IC?.developerType ? IC?.developerType : "",
      iCDate: IC?.iCDate ? IC?.iCDate : "",
      feedBack: IC?.feedBack ? IC?.feedBack : "",
      iCSlot: IC?.iCSlot ? IC?.iCSlot : "",
      developer: IC?.developer ? IC?.developer : "",
      techStack: IC?.techStack ? IC?.techStack : "",
      scheduledBy: IC?.scheduledBy ? IC?.scheduledBy : "",
      ICfeedbackForm: IC?.ICfeedbackForm ? IC?.ICfeedbackForm : "",
      remarks: IC?.remarks ? IC?.remarks : "",
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    dispatch(getTechStacks());
  }, []);

  return (
    <>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Dialog
            className={classes.paper}
            maxWidth={"md"}
            open={open}
            close={onClose}
            sx={{
              "& .MuiDialogActions-root": {
                padding: "0px !important",
              },
            }}
          >
            <DialogTitle>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Edit IC
                <Button
                  sx={{
                    color: "black",
                    fontSize: "1rem",
                  }}
                  onClick={onClose}
                >
                  <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
                </Button>
              </Grid>
            </DialogTitle>

            <DialogContent>
              {loading ? ( // Render the loader if loading is true
                <Box sx={{ width: "700px" }}>
                  <LoadingSpinner />
                </Box>
              ) : (
                <Grid
                  container
                  sx={{
                    "& 	.MuiAutocomplete-popper": {
                      paddingBottom: "1rem",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#D32F2F",
                    },
                  }}
                  mt={1}
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={developerNames || []}
                      getOptionLabel={(option) => option?.fullName}
                      name="developer"
                      // disablePortal={true}
                      filterSelectedOptions
                      onChange={(event, value) =>
                        formik.setFieldValue("developer", value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik?.values?.developer}
                      size="small"
                      renderInput={(params) => (
                        <>
                          <TextField
                            required
                            {...params}
                            name="developer"
                            label="Developer"
                            // error={formik.errors.poc}
                            helperText={
                              formik.touched.developer
                                ? formik.errors.developer
                                : ""
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>

                  {/* ----------------------client----------------- */}
                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={clientList || []} // Set your list of clients here
                      getOptionLabel={(option) => option?.clientName} // Display client names
                      name="clientName"
                      filterSelectedOptions
                      onChange={(event, value) => {
                        formik.setFieldValue(
                          "clientName",
                          value?.clientName || ""
                        );
                        formik.setFieldValue("clientId", value?._id || "");
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        clientList.find(
                          (client) =>
                            client.clientName === formik.values.clientName
                        ) || null
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          name="clientName"
                          label="Client Name"
                          helperText={
                            formik.touched.clientName
                              ? formik.errors.clientName
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* ------------------------------------- */}

                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={projectManagers || []}
                      getOptionLabel={(option) => option?.fullName}
                      name="scheduledBy"
                      filterSelectedOptions
                      onChange={(event, value) =>
                        formik.setFieldValue("scheduledBy", value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik?.values?.scheduledBy}
                      size="small"
                      renderInput={(params) => (
                        <>
                          <TextField
                            required
                            {...params}
                            name="scheduledBy"
                            label="Scheduled By"
                            helperText={
                              formik.touched.scheduledBy
                                ? formik.errors.scheduledBy
                                : ""
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={developerType?.map((option) => option)}
                      name="developerType"
                      filterSelectedOptions
                      onChange={(event, value) =>
                        formik.setFieldValue("developerType", value)
                      }
                      disabled="true"
                      onBlur={formik.handleBlur}
                      value={formik?.values?.developerType}
                      size="small"
                      renderInput={(params) => (
                        <>
                          <TextField
                            required
                            {...params}
                            name="developerType"
                            label="Developer Type"
                            // error={formik.errors.poc}
                            helperText={
                              formik.touched.developerType
                                ? formik.errors.developerType
                                : ""
                            }
                          />
                        </>
                      )}
                      getOptionDisabled={(option) => option === "YetToHired"}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={techStacks || []}
                      getOptionLabel={(option) => option?.name}
                      name="techStack"
                      filterSelectedOptions
                      onChange={(event, value) =>
                        formik.setFieldValue("techStack", value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik?.values?.techStack}
                      size="small"
                      renderInput={(params) => (
                        <>
                          <TextField
                            required
                            {...params}
                            name="techStack"
                            label="Tech Stack"
                            helperText={
                              formik.touched.techStack
                                ? formik.errors.techStack
                                : ""
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={6}>
                      <DesktopDatePicker
                        sx={{ width: "100%" }}
                        slotProps={{ textField: { size: "small" } }}
                        inputFormat="MM-DD-YYYY"
                        label="IC Date"
                        value={
                          formik?.values?.iCDate &&
                          new Date(
                            moment(formik?.values?.iCDate).format("MM-DD-YYYY")
                          )
                        }
                        onChange={(newValue) => {
                          formik.setFieldValue(
                            "iCDate",
                            moment(newValue).format("MM-DD-YYYY")
                          );
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              size="small"
                              required
                              fullWidth
                              {...params}
                              onBlur={(e) => {
                                formik.setFieldTouched("iCDate", true);
                              }}
                              helperText={
                                formik.touched.iCDate
                                  ? formik.errors.iCDate
                                  : ""
                              }
                            />
                          </>
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>

                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        label="IC Slot"
                        sx={{ width: "100%" }}
                        slotProps={{ textField: { size: "small" } }}
                        value={
                          formik?.values?.iCSlot
                            ? new Date(formik.values.iCSlot)
                            : null
                        }
                        onChange={(newValue) => {
                          formik.setFieldValue(
                            "iCSlot",
                            format(newValue, "HH:mm:ss")
                          );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={Feedback?.map((option) => option)}
                      name="feedBack"
                      filterSelectedOptions
                      onChange={(event, value) =>
                        formik.setFieldValue("feedBack", value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik?.values?.feedBack}
                      size="small"
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            name="feedBack"
                            label="Feedback"
                            // error={formik.errors.poc}
                            helperText={
                              formik.touched.feedBack
                                ? formik.errors.feedBack
                                : ""
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      className={classes.inputItem}
                      options={ICfeedbackForm?.map((option) => option)}
                      name="ICfeedbackForm"
                      // disablePortal={true}
                      filterSelectedOptions
                      onChange={(event, value) =>
                        formik.setFieldValue("ICfeedbackForm", value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik?.values?.ICfeedbackForm}
                      size="small"
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            name="ICfeedbackForm"
                            label="IC Feedback Form"
                            // error={formik.errors.poc}
                            helperText={
                              formik.touched.ICfeedbackForm
                                ? formik.errors.ICfeedbackForm
                                : ""
                            }
                          />
                        </>
                      )}
                    />
                  </Grid>

                  {/* remark */}
                  <Grid item xs={6}>
                    <TextField
                      sx={{
                        width: "100%",
                      }}
                      id="outlined-required"
                      label="Remark"
                      name="remarks" // Unique name for this field
                      type={"text"}
                      size="small"
                      value={formik.values.remarks}
                      onChange={(event, value) =>
                        formik.setFieldValue("remarks", event.target.value)
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.remarks && Boolean(formik.errors.remarks)
                      }
                      helperText={
                        formik.touched.remarks && formik.errors.remarks
                      }
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Box sx={{ margin: "8px 20px" }}>
                <Button
                  onClick={formik.handleSubmit}
                  sx={{ color: "white" }}
                  variant="contained"
                >
                  Save Project
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </form>
      </Box>
    </>
  );
};

export default EditIC;
