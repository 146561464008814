import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../apiclient";

const initialState = {
  isLoading: false,
  ics: [],
  projectManagers: [],
  developerNames: [],
  clientList: [],
  error: "",
  dataPerPage: 1,
  totalCount: 0,
  currentPage: 1,
};

export const getAllIC = createAsyncThunk(
  "ICSlice/getAllIC",
  async (thunkAPI) => {
    try {
      let res = await api.get(`/api/v2/ic/getAllIc`);
      return res?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAllProjectManagers = createAsyncThunk(
  "ICSlice/projectManagers",
  async (projectManagerId) => {
    const projectManager_url = `api/v2/users/search?role=PM`;
    try {
      const res = await api.get(projectManager_url, {
        designation: projectManagerId,
      });
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      // return error?.response
    }
  }
);

export const getAllManagers = createAsyncThunk(
  "ICSlice/managers",
  async (projectManagerId) => {
    const projectManager_url = `api/v2/users/access-management-data`;
    try {
      const res = await api.get(projectManager_url, {
        designation: projectManagerId,
      });
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      // return error?.response
    }
  }
);

export const getDeveloperNames = createAsyncThunk(
  "ICSlice/getDeveloperNames",
  async () => {
    try {
      const res = await api.get("api/v2/users/listIcUsers");
      return res?.data?.data;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  }
);

export const getClientname = createAsyncThunk(
  "ICSlice/getClientNames",
  async (thunkAPI) => {
    try {
      const res = await api.get("api/v2/ic/doGetClientName");
      return res?.data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const ICSlice = createSlice({
  name: "ICSlice",
  initialState,
  reducers: {
    setFilterParameters: (state, action) => {
      state.filterParameters = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add your async action cases here...
    builder.addCase(getAllIC.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getAllIC.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ics = action.payload.data;
    });
    builder.addCase(getAllIC.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllProjectManagers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectManagers = action?.payload?.data;
      state.projectManagers = action.payload;
    });
    builder.addCase(getAllProjectManagers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllProjectManagers.rejected, (state, action) => {
      state.isLoading = false;
      state.projectManagers = [];
      state.error = action.error.message;
    });
    builder.addCase(getAllManagers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.projectManagers = action?.payload?.data;
      state.projectManagers = action.payload;
    });
    builder.addCase(getAllManagers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllManagers.rejected, (state, action) => {
      state.isLoading = false;
      state.projectManagers = [];
      state.error = action.error.message;
    });
    builder.addCase(getDeveloperNames.fulfilled, (state, action) => {
      state.isLoading = false;
      state.developerNames = action?.payload;
    });
    builder.addCase(getDeveloperNames.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getDeveloperNames.rejected, (state, action) => {
      state.isLoading = false;
      state.developerNames = [];
      state.error = action.error.message;
    });
    builder.addCase(getClientname.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clientList = action?.payload;
    });
    builder.addCase(getClientname.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientname.rejected, (state, action) => {
      state.isLoading = false;
      state.clientList = [];
      state.error = action.error.message;
    });
  },
});

export const { setFilterParameters, setCurrentPage } = ICSlice.actions;
export default ICSlice.reducer;
